export const adminLteConf = {
  skin: 'black',
  // isSidebarLeftCollapsed: false,
  // isSidebarLeftExpandOnOver: false,
  // isSidebarLeftMouseOver: false,
  // isSidebarLeftMini: true,
  // sidebarRightSkin: 'dark',
  // isSidebarRightCollapsed: true,
  // isSidebarRightOverContent: true,
  // layout: 'normal',
  sidebarLeftMenu: [
    //{label: 'MAIN NAVIGATION', separator: true},
    { label: 'Dashboard', route: '/', iconClasses: 'fa fal fa-star' },
    { label: 'New Order', route: '/order/new', iconClasses: 'fa fal fa-plus' },
    { label: 'Delivery Dashboard', route: '/delivery', iconClasses: 'fa fal fa-truck' },
    { label: 'Order', route: '/order/', iconClasses: 'fa fal fa-file-alt' },
    { label: 'POS Order', route: '/pos', iconClasses: 'fa fal fa-file-alt' },
    { label: 'News / Promotions ', route: '/news', iconClasses: 'fa fal fa-newspaper' },
    { label: 'Product set promotions', route: '/product-promotion', iconClasses: 'fa fal fa-border-all' },
    { label: 'Product list ', route: '/product', iconClasses: 'fa fal fa-ice-cream' },
    { label: 'Customers', route: '/customer', iconClasses: 'fa fal fa-user' },
    { label: 'Branches', route: '/branches', iconClasses: 'fa fal fa-ice-cream' },
    {
      label: 'Setting',
      iconClasses: 'fa fal fa-cog',
      children: [
        { label: 'Category', route: 'setting/category' },
        { label: 'Allergic', route: 'setting/allergic' },
        { label: 'Nutrition', route: 'setting/nutrition' },
        { label: 'General', route: 'setting/general' },
      ],
    },
  ],
};
