import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestCalculateOrderModel } from 'src/app/models/calculate.model';
import { OrderDetailModel } from 'src/app/models/order-detail.model';
import { ApiService } from './base/api.service';
@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private apiService: ApiService) {}

  getTodayOrderTotal(): Observable<any> {
    return this.apiService.get(`orders/today/total`);
  }

  getOrderTotalRange(from: string, to: string): Observable<any> {
    return this.apiService.get(`orders/total?fromdate=${from}&todate=${to}`);
  }

  getPartnerList(from: string, to: string, isDeliveryDate: boolean): Observable<any> {
    return this.apiService.get(`orders/partners?fromdate=${from}&todate=${to}&deliveryDate=${isDeliveryDate}`);
  }

  getPosPartnerList(from: string, to: string, isDeliveryDate: boolean): Observable<any> {
    return this.apiService.get(`orders/partners-pos?fromdate=${from}&todate=${to}&deliveryDate=${isDeliveryDate}`);
  }

  getTodayOrders(): Observable<any> {
    return this.apiService.get(`orders/today`);
  }

  getOrderById(id: string): Observable<any> {
    return this.apiService.get(`orders/${id}`);
  }

  orderReCheck(id: string, isChecked: any): Observable<any> {
    const body: any = {
      isCheck: isChecked,
    };
    return this.apiService.patch(`orders/${id}/check`, body);
  }

  getOrdersDateRange(
    from: string,
    to: string,
    paging: string = '',
    filter: string = '',
    sortBy: string = ''
  ): Observable<any> {
    const params = `?fromdate=${from}&todate=${to}&paging=${paging}&filter=${filter}&sortby=${sortBy}`;
    return this.apiService.get(`orders/${params}`);
  }

  getPosOrdersDateRange(
    from: string,
    to: string,
    paging: string = '',
    filter: string = '',
    sortBy: string = ''
  ): Observable<any> {
    const params = `?fromdate=${from}&todate=${to}&paging=${paging}&filter=${filter}&sortby=${sortBy}`;
    return this.apiService.get(`orders/filter-pos/${params}`);
  }

  getCalculatePrice(quantity: number, isUpcountry: boolean): Observable<any> {
    const body: any = {
      calculateOrderItems: [
        {
          quantity: quantity,
          addressId: 1,
          upcountry: isUpcountry,
        },
      ],
    };

    return this.apiService.post(`/api/orders/calprice`, body);
  }

  calculatePrice(model: RequestCalculateOrderModel): Observable<any> {
    return this.apiService.post(`orders/CalPrice`, model);
  }

  createOrder(model: object) {
    return this.apiService.post(`orders`, model);
  }

  updateOrder(id: number, model: object) {
    return this.apiService.put(`orders/${id}`, model);
  }

  checkStatus(orderNo: any) {
    return this.apiService.get(`/ksher/webhook/success?mch_order_no=${orderNo}`);
  }

  cancelOrder(orderNo: any) {
    return this.apiService.get(`/ksher​/webhook​/cancel?mch_order_no=${orderNo}`);
  }

  getFullAddressDetail(model: OrderDetailModel, provinceList: any[]) {
    if (model.provinceId > -1) {
      const province = provinceList.find((x) => x.provinceID == model.provinceId);
      return (
        model.address + ' ' + model.subDistrict + ' ' + model.district + ' ' + province?.name + ' ' + model.zipCode
      );
    }
    return '';
  }

  getOrderCustomerHistory(phone: string): Observable<any> {
    return this.apiService.get(`orders/customerhistory/${phone}`);
  }

  getOrderHistory(id: string): Observable<any> {
    return this.apiService.get(`orders/orderhistory/${id}`);
  }

  sendNotificationLineBot(orderId: string): Observable<any> {
    return this.apiService.get(`LineBot/SendNoti?orderId=${orderId}`);
  }

  getBookGrabOrder(orderId: string): Observable<any> {
    return this.apiService.get(`orders/bookgraborder?orderId=${orderId}`);
  }

  returnOrderRequest(orderId: string): Observable<any> {
    return this.apiService.post(`orders/CreateReturnOrderRequest?orderId=${orderId}`);
  }
}
