<button class="recheck-toggle" (click)="toggleRecheck()">Recheck</button>
<div class="recheck-wrapper">
  <div [ngClass]="{ 'bill-left': hasImage() }">
    <div *ngFor="let reference of orderRecheck.referenceInformation || []">
      <img src="{{ reference.filePath }}" alt="" />
    </div>
  </div>
  <div [ngClass]="{ 'bill-right': hasImage() }">
    <div class="bill-header">
      <div class="d-flex invoice-no">
        <div class="field-name">ORDER NO. :</div>
        <div class="field-content">
          {{ orderRecheck?.invoiceNumber }}
        </div>
      </div>
      <div class="d-flex invoice-no">
        <div class="field-name">REF NO. :</div>
        <div class="field-content">
          {{ orderRecheck?.referenceNumber }}
        </div>
      </div>
      <ul class="item-list primary-info">
        <li>
          <div class="field-name">จำนวน</div>
          <div class="field-content"><span></span>{{ orderRecheck?.place?.setName }}</div>
        </li>
        <li>
          <div class="field-name">ค่าไอศครีม</div>
          <div class="field-content"><span>฿</span>{{ orderRecheck?.orderPrice }}</div>
        </li>
        <li>
          <div class="field-name">ค่าจัดส่ง</div>
          <div class="field-content"><span>฿</span>{{ orderRecheck?.deliveryPrice }}</div>
        </li>
        <li>
          <div class="field-name">ส่วนลด</div>
          <div class="field-content"><span>฿</span>{{ orderRecheck?.discountPrice }}</div>
        </li>
        <li>
          <div class="field-name">ราคาสุทธิ</div>
          <div class="field-content"><span>฿</span>{{ orderRecheck?.totalPrice }}</div>
        </li>
      </ul>
    </div>
    <mk-accordion-group class="accordion-group bg-transparent">
      <mk-accordion header="">
        <mk-accordion-header> </mk-accordion-header>
        <div class="label-note">
          <h3>{{ orderRecheck?.place?.setName }} | {{ orderRecheck?.place?.boxNumber }} Box</h3>
        </div>
        <div class="d-flex bill-content" *ngFor="let prod of orderRecheck?.place?.products">
          <div class="field-name">
            {{ prod.name }}
          </div>
          <div class="field-content"><span>X</span> {{ prod.quantity }}</div>
        </div>
        <div class="d-flex bill-footer">
          <div class="field-name">Total</div>
          <div class="field-content">
            {{ orderRecheck?.quantity }}
          </div>
        </div>
        <ng-container *ngIf="orderRecheck?.place?.gifts?.length > 0">
          <hr />
          <div class="d-flex bill-content" *ngFor="let prod of orderRecheck?.place?.gifts">
            <div class="field-name">
              {{ getGiftName(prod.id) }}
            </div>
            <div class="field-content"><span>X</span> {{ prod.amount }}</div>
          </div>
          <div class="d-flex bill-footer">
            <div class="field-name">Total</div>
            <div class="field-content">
              {{ getTotalGift() }}
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="orderRecheck?.boxQuantity?.length > 0">
          <hr />
        </ng-container>
        <div class="d-flex bill-content" *ngFor="let box of orderRecheck?.boxQuantity">
          <i class="fal fa-box" style="font-size: 11px"> {{ box.key }} = {{ box.value }}&nbsp;</i>
        </div>
        <div class="note-content" *ngIf="orderRecheck?.place?.remark">
          <div class="note-label">Remark!!!</div>
          <div class="field-content">
            <div class="special-remark">*{{ orderRecheck?.place?.remark }}</div>
          </div>
        </div>
        <hr />
        <div class="shipping-info-wrapper">
          <div class="shipping-info">
            <div class="field-content delivery">
              {{ orderRecheck?.referenceText }}
            </div>
            <div class="field-content delivery">วันที่จัดส่ง {{ orderRecheck?.place?.deliveryDate }}</div>
            <div class="field-content customer">K.{{ orderRecheck?.place?.receiverName }}</div>
            <div class="field-content phone">เบอร์ผู้รับ: {{ orderRecheck?.place?.tel }}</div>
            <div class="field-content phone">เบอร์สำรอง: {{ orderRecheck?.place?.secondPhone }}</div>
            <div class="field-content">
              {{ orderRecheck?.place?.address }}
            </div>
          </div>
          <div class="shipping-info">
            <div class="field-content">
              <div class="status-remark">
                {{ orderRecheck?.place?.status }}
              </div>
            </div>
            <div class="field-content">
              <div class="status-remark">
                {{ orderRecheck?.place?.transport }}
              </div>
            </div>
            <div class="field-content" *ngIf="orderRecheck?.place?.deliveryRemark">
              <div class="note-label">หมายเหตุการจัดส่ง</div>
              <div class="special-remark">*{{ orderRecheck?.place?.deliveryRemark }}</div>
            </div>
            <div class="field-content" *ngIf="orderRecheck?.smsRemark">
              <div class="note-label">SMS</div>
              <div class="special-remark">*{{ orderRecheck?.smsRemark }}</div>
            </div>
            <div class="field-content" *ngIf="orderRecheck?.factoryRemark">
              <div class="note-label">หมายเหตุถึงคนรถ ฉีกลาเบล</div>
              <div class="special-remark">*{{ orderRecheck?.factoryRemark }}</div>
            </div>
          </div>
        </div>
      </mk-accordion>
    </mk-accordion-group>
    <div class="form-group switch-form">
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          class="custom-control-input"
          id="customSwitch1"
          (change)="onChange($event)"
          [checked]="orderRecheck.isCheck"
          [disabled]="orderRecheck.isWaitingData"
        />
        <label class="custom-control-label" for="customSwitch1">ตรวจทาน</label>
      </div>
      <label>{{ orderRecheck?.waitingData }}</label>
    </div>
    <div class="form-group">
      <div class="btn-group d-flex" role="group">
        <button
          type="button"
          class="btn btn-default w-100"
          [disabled]="orderRecheck.previousOrderId === null"
          (click)="callPrevious()"
        >
          <i class="fal fa-chevron-circle-left"></i> Previous
        </button>
        <button
          type="button"
          class="btn btn-default w-100"
          [disabled]="orderRecheck.nextOrderId === null"
          (click)="callNext()"
        >
          Next <i class="fal fa-chevron-circle-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>
