<mk-accordion-group class="accordion-group bg-transparent" [isMultiple]="false" (collapseDone)="collapseDone($event)">
  <mk-accordion header="รายละเอียดที่อยู่">
    <div class="label-note"><strong>Delivery number.</strong>{{ place.deliveryNumber }}</div>
    <div class="d-flex form-row">
      <div class="form-group">
        <label for="InvoiceNumber">วันที่จัดส่งสินค้า</label>
        <input
          type="text"
          placeholder="Date"
          class="form-control date-input"
          [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
          [bsValue]="place!.deliveryDate"
          bsDatepicker
        />
      </div>
      <div class="form-group ml-1">
        <label for="refInvoice">Set</label>
        <ng-select
          [items]="setList"
          bindLabel="name"
          bindValue="id"
          (change)="onSetChange($event)"
          placeholder="Select set"
          [(ngModel)]="selectedSetId"
        >
        </ng-select>
      </div>
      <div class="form-group ml-1">
        <label for="refInvoice">จำนวนกล่อง</label>

        <ng-select
          [items]="numberOfBoxList"
          bindLabel="name"
          bindValue="id"
          placeholder="จำนวนกล่อง"
          (change)="onBoxChange($event)"
          [(ngModel)]="numberOfBox"
        >
        </ng-select>
      </div>
    </div>
    <div class="d-flex form-row">
      <div class="form-group">
        <label for="InvoiceNumber">หมายเหตุ</label>
        <input type="text" class="form-control" id="InvoiceNumber" placeholder="หมายเหตุ" [(ngModel)]="place.remark" />
      </div>
    </div>
    <div class="d-flex form-row">
      <div class="form-group">
        <label for="InvoiceNumber">ชื่อผู้รับ</label>
        <input
          type="text"
          class="form-control"
          id="reciver_name"
          placeholder="ชื่อผู้รับ"
          [(ngModel)]="place!.receiverName"
        />
      </div>
    </div>
    <div class="d-flex form-row">
      <div class="form-group">
        <label for="InvoiceNumber">ที่อยู่การจัดส่ง</label>
        <!-- <input type="text" class="form-control" id="InvoiceNumber" placeholder="วันที่จัดส่งสินค้า"> -->
        <textarea
          class="form-control"
          name="address"
          id="address"
          cols="30"
          rows="3"
          placeholder="ที่อยู่จัดส่ง"
          [(ngModel)]="place.address"
          style="min-height: 180px"
          readonly
        ></textarea>
      </div>
      <div class="form-group ml-1">
        <button type="submit" class="btn btn-primary btn-change-address">เปลี่ยนที่อยู่การจัดส่ง</button>
        <input type="text" class="form-control" id="tel" placeholder="Tel" [(ngModel)]="place.tel" readonly />
      </div>
    </div>
    <div class="d-flex form-row">
      <div class="form-group">
        <label for="InvoiceNumber">Province</label>
        <ng-select
          [items]="proviceList"
          class="disabled"
          bindLabel="name"
          bindValue="provinceID"
          placeholder="Province"
          (change)="onProvinceChange($event)"
          [ngModel]="provinceId"
          [readonly]="true"
          [disabled]="true"
        >
        </ng-select>
      </div>
      <div class="form-group ml-1">
        <label for="refInvoice">District</label>
        <input
          type="text"
          class="form-control"
          id="district"
          name="district"
          placeholder="District"
          [(ngModel)]="place.district"
          readonly
        />
      </div>
      <div class="form-group ml-1">
        <label for="refInvoice">Zipcode</label>
        <input
          type="text"
          class="form-control"
          id="zipcode"
          name="zipcode"
          placeholder="Zipcode"
          [(ngModel)]="place.zipcode"
          readonly
        />
      </div>
      <div class="form-group ml-1">
        <label for="refInvoice">Transport</label>
        <ng-select
          [items]="orderCommon.transportationTypes"
          bindLabel="name"
          bindValue="id"
          placeholder="Transport"
          (change)="onTransportChange($event)"
          [(ngModel)]="trasportId"
        >
        </ng-select>
      </div>
    </div>
    <div class="d-flex form-row">
      <div class="form-group">
        <label for="InvoiceNumber">ราคา(สุทธิ)</label>
        <input
          type="text"
          class="form-control"
          id="totalPrice"
          placeholder="ราคา(สุทธิ)"
          [ngModel]="place.totalPrice"
          readonly
        />
      </div>
      <div class="form-group ml-1">
        <label for="refInvoice">ค่าไอศครีม</label>
        <input
          type="text"
          class="form-control"
          id="orderPrice"
          placeholder="ค่าไอศครีม"
          [ngModel]="place.orderPrice"
          readonly
        />
      </div>
      <div class="form-group ml-1">
        <label for="refInvoice">ค่าจัดส่ง</label>
        <input
          type="text"
          class="form-control"
          id="deliveryPrice"
          placeholder="ค่าจัดส่ง"
          [ngModel]="place.deliveryPrice"
          readonly
        />
      </div>
      <div class="form-group ml-1">
        <button type="button" class="btn btn-primary btn-change-address" (click)="clickedCalculate()">คำนวน</button>
      </div>
    </div>
    <div class="d-flex form-row">
      <div class="form-group">
        <label for="InvoiceNumber">Location link</label>
        <input
          type="text"
          class="form-control"
          id="InvoiceNumber"
          placeholder="Location link"
          [(ngModel)]="place.locationLink"
        />
      </div>
    </div>
    <div class="d-flex form-row">
      <div class="form-group">
        <label for="InvoiceNumber">หมายเหตุการจัดส่ง</label>
        <input
          type="text"
          class="form-control"
          id="InvoiceNumber"
          placeholder="หมายเหตุการจัดส่ง"
          [(ngModel)]="place.deliveryRemark"
        />
      </div>
    </div>
    <div class="d-flex form-row">
      <div class="form-group">
        <label for="orderSTatus">สถานะสินค้า</label>
        <ng-select
          [items]="orderCommon.orderStatus"
          bindLabel="name"
          bindValue="id"
          placeholder="สถานะสินค้า"
          (change)="onStatusChange($event)"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="place.status"
        >
        </ng-select>
      </div>
      <div class="form-group ml-1 text-right">
        <label for="InvoiceNumber">Time remark</label>
        <div class="time-remark-wrapper">
          <button
            class="time-remark-btn"
            [ngClass]="{
              active: isTimeRemark1Active
            }"
            (click)="toggleTimeRemark1Active($event)"
          >
            <i class="fas fa-cloud-sun"></i>
          </button>
          <button
            class="time-remark-btn"
            [ngClass]="{
              active: isTimeRemark2Active
            }"
            (click)="toggleTimeRemark2Active($event)"
          >
            <i class="fas fa-sun"></i>
          </button>
        </div>
      </div>
    </div>
  </mk-accordion>
</mk-accordion-group>
