import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { LayoutStore } from 'angular-admin-lte';
import { isEmpty } from 'lodash';
import { isNil } from 'lodash/fp';
import { Subscription } from 'rxjs';
import { OrderRecheckModel } from 'src/app/models/order-recheck.model';
import { OrderCommonModel } from 'src/app/models/order.model';
import { CommonService } from '../../services/common.service';
import { InformationMessageService } from '../../services/information-message.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-sidebar-right-inner',
  templateUrl: './sidebar-right-inner.component.html',
  styleUrls: ['./sidebar-right-inner.component.scss'],
})
export class SidebarRightInnerComponent implements OnInit, OnDestroy {
  @Input() public orderRecheck?: OrderRecheckModel | any = [];
  @Input() public isShowRecheck: boolean;
  @Output() onPreviousPage: EventEmitter<any> = new EventEmitter();
  @Output() onNextPage: EventEmitter<any> = new EventEmitter();
  public layout!: string;
  public isSidebarLeftCollapsed!: boolean;
  public isSidebarLeftExpandOnOver!: boolean;
  public isSidebarLeftMini!: boolean;

  private subscriptions: Subscription[] = [];
  orderCommon: OrderCommonModel = new OrderCommonModel();
  giftList: any[] = [];
  constructor(
    public layoutStore: LayoutStore,
    private changeDetectorRef: ChangeDetectorRef,
    private orderService: OrderService,
    private commonService: CommonService,
    private messageService: InformationMessageService
  ) {}

  @HostBinding('class.open-recheck')
  get t() {
    return this.isShowRecheck;
  }

  /**
   * [ngOnInit description]
   * @method ngOnInit
   */
  async ngOnInit() {
    this.orderCommon = await this.commonService.getOrderCommon();
    this.loadGiftItem();
    this.subscriptions.push(
      this.layoutStore.isSidebarLeftCollapsed.subscribe((value: boolean) => {
        this.isSidebarLeftCollapsed = value;
        this.changeDetectorRef.detectChanges();
      })
    );
    this.subscriptions.push(
      this.layoutStore.isSidebarLeftExpandOnOver.subscribe((value: boolean) => {
        this.isSidebarLeftExpandOnOver = value;
        this.changeDetectorRef.detectChanges();
      })
    );
    this.subscriptions.push(
      this.layoutStore.isSidebarLeftMini.subscribe((value: boolean) => {
        this.isSidebarLeftMini = value;
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  private loadGiftItem() {
    this.commonService.filterGiftItem().subscribe((x) => {
      this.giftList = x;
    });
  }

  /**
   * @method ngOnDestroy
   */
  ngOnDestroy() {
    this.removeSubscriptions();
  }

  /**
   * [removeListeners description]
   * @method removeListeners
   */
  private removeSubscriptions() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
      });
    }
    this.subscriptions = [];
  }

  /**
   * [onLayoutChange description]
   * @method onLayoutChange
   */
  public onLayoutChange(event: any): void {
    this.layout = event.target.checked ? event.target.getAttribute('value') : '';
    this.layoutStore.setLayout(this.layout);
  }

  /**
   * [changeSkin description]
   * @method changeSkin
   */
  public changeSkin(event: Event, color: string): void {
    event.preventDefault();
    this.layoutStore.setSkin(color);
  }

  /**
   * [changeSidebarRightSkin description]
   * @method changeSidebarRightSkin
   */
  public changeSidebarRightSkin(value: boolean): void {
    if (value) {
      this.layoutStore.setSidebarRightSkin('light');
    } else {
      this.layoutStore.setSidebarRightSkin('dark');
    }
  }

  getGiftName(id: number) {
    return this.giftList?.find((x) => x.id === id)?.name;
  }

  getTotalGift() {
    return this.orderRecheck.place?.gifts?.reduce((total, item) => {
      return total + item.amount;
    }, 0);
  }

  toggleRecheck() {
    this.isShowRecheck = !this.isShowRecheck;
  }

  onChange($event: any) {
    const checkValue = $event.target.checked;
    this.orderService.orderReCheck(this.orderRecheck.orderId, checkValue).subscribe(
      () => {
        this.messageService.showSuccess('Update successful');
      },
      (err) => {
        this.messageService.showError('Update failed');
      }
    );
  }

  hasImage() {
    return !isNil(this.orderRecheck.referenceInformation) && !isEmpty(this.orderRecheck.referenceInformation);
  }

  callPrevious() {
    this.onPreviousPage.emit();
  }
  callNext() {
    this.onNextPage.emit();
  }
}
