import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { DragUploadComponent } from './drag-upload/drag-upload.component';
import { NavigationComponent } from './navigation/navigation.component';
import { PhoneMaskDirective } from './phone-format/phone-format.directive';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FileUploadModule],
  declarations: [DragUploadComponent, NavigationComponent, PhoneMaskDirective],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    DragUploadComponent,
    PhoneMaskDirective,
    NavigationComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
