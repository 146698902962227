import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { OrdersComponent } from './components/order/orders.component';
import { OrdersListComponent } from './orders-list/orders-list.component';
import { OrdersNewComponent } from './orders-new/orders-new.component';
import { OrdersPosComponent } from './orders-pos/orders-pos.component';

const routes: Routes = [
  {
    path: '',
    component: OrdersListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'order',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: OrdersListComponent,
        data: {
          title: 'Orders list',
        },
      },
      {
        path: 'old',
        component: OrdersComponent,
        data: {
          title: 'View Order',
        },
      },
      {
        path: 'new',
        component: OrdersNewComponent,
        data: {
          title: 'New Order',
        },
      },
      {
        path: 'pos',
        component: OrdersPosComponent,
        data: {
          title: 'POS Order',
        },
      },
      {
        path: 'edit/:id',
        component: OrdersNewComponent,
        data: {
          title: 'Edit Order',
        },
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrderRoutingModule {}
