<div class="row">
  <div class="col-lg-6">
    <app-total-orders></app-total-orders>
  </div>
  <div class="col-lg-6" style="margin-bottom: 20px">
    <app-payment-delivery></app-payment-delivery>
  </div>
</div>
<div class="row">
  <section class="col-12 connectedSortable">
    <app-invoice-list></app-invoice-list>
  </section>
</div>
