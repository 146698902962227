<div class="recheck-sidebar" *ngIf="orderId && orderRecheck">
  <app-sidebar-right-inner
    [(orderRecheck)]="orderRecheck"
    [(isShowRecheck)]="isOrderRecheck"
    [ngClass]="{ 'recheck-image': orderRecheck.referenceInformation?.length !== 0 }"
    (onPreviousPage)="previousPage()"
    (onNextPage)="nextPage()"
  >
  </app-sidebar-right-inner>
</div>
<form [formGroup]="orderForm" *ngIf="orderForm" (ngSubmit)="onSubmit()">
  <div id="order-form" class="container-fluid">
    <div class="col-3 pr-0 pl-0">
      <div class="invoice-info">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="ddlBranch">สาขา</label>
              <div for="ddlBranch" class="form-group mb-0">
                <ng-select
                  id="ddlBranch"
                  [items]="branchList"
                  [loading]="isBranchListLoading"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="สาขา"
                  [(ngModel)]="branchId"
                  [ngModelOptions]="{ standalone: true }"
                  formControlName="branchId"
                  [ngClass]="{
                    'is-invalid': f.branchId.touched && (f.branchId.errors || f.branchId.errors?.required)
                  }"
                >
                </ng-select>
                <div *ngIf="f.branchId.touched" class="invalid-feedback">กรุณาเลือกฟิลด์นี้</div>
              </div>
            </div>
          </div>
          <div class="col-6 pl-10 pr-10">
            <div class="form-group">
              <label for="input1">Invoice number</label>
              <input class="form-control" readonly id="input1" formControlName="invoiceNumber" />
            </div>
            <div class="form-group date-picker">
              <label>วันที่รับออเดอร์</label>
              <input
                type="text"
                placeholder="Start date"
                class="form-control"
                bsDatepicker
                formControlName="orderDate"
                [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY', customTodayClass: 'today' }"
                [(ngModel)]="orderDate"
                [ngClass]="{
                  'is-invalid': f.orderDate.touched && (f.orderDate.errors || f.orderDate.errors?.required)
                }"
              />
              <div *ngIf="f.orderDate.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
            </div>
          </div>
          <div class="col-6 pr-10 pl-10">
            <div class="form-group-btn">
              <label for="input2">Ref</label>
              <div class="form-group">
                <input class="form-control" id="input2" formControlName="referenceNumber" />
                <button type="button" (click)="openModal(referenceModal)" class="btn btn-square">T</button>
              </div>
            </div>
            <div class="form-group date-picker">
              <label>วันที่จัดส่งสินค้า</label>
              <input
                type="text"
                placeholder="Delivery date"
                class="form-control"
                formControlName="deliveryDate"
                bsDatepicker
                [(ngModel)]="deliveryDate"
                [bsConfig]="{
                  adaptivePosition: true,
                  isAnimated: true,
                  dateInputFormat: 'DD/MM/YYYY',
                  customTodayClass: 'today'
                }"
                [ngClass]="{
                  'is-invalid': f.deliveryDate.touched && (f.deliveryDate.errors || f.deliveryDate.errors?.required)
                }"
              />
              <div *ngIf="f.deliveryDate.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
            </div>
          </div>
        </div>
      </div>
      <div class="user-info">
        <h4>ข้อมูลสมาชิก</h4>
        <div class="row">
          <div class="col-12">
            <div class="form-group-btn">
              <div class="form-group">
                <ng-select
                  class="ddl-customer"
                  placeholder="Molto customer"
                  [items]="customerList$ | async"
                  name="customerId"
                  bindLabel="name"
                  formControlName="customerName"
                  [multiple]="false"
                  [hideSelected]="true"
                  [trackByFn]="trackByFn"
                  [minTermLength]="2"
                  [(ngModel)]="selectedCustomerName"
                  [loading]="isCustomerLoading"
                  typeToSearchText="Please enter 2 or more characters"
                  bindValue="name"
                  [typeahead]="searchText$"
                  [ngModelOptions]="{ standalone: true }"
                  [ngClass]="{
                    'is-invalid': f.customerName.touched && (f.customerName.errors || f.customerName.errors?.required)
                  }"
                  (change)="onSelectCustomer($event)"
                >
                  <ng-template ng-option-tmp let-item=" item" let-search="searchTerm">
                    <div>
                      <span>{{ item.name }} {{ item.ref }}</span>
                    </div>
                    <div>
                      <small><b>โทร:</b> {{ item.phone }}</small>
                    </div>
                    <small><b>ที่อยู่:</b> {{ item.deliveryAddress }}</small>
                  </ng-template>
                </ng-select>
                <ng-container *ngIf="selectedCustomer">
                  <button type="button" (click)="openCustomerHistoryModal(customerHistoryModal)" class="btn btn-square">
                    <i class="fas fa-history"></i>
                  </button>
                </ng-container>
                <button type="button" (click)="openCustomerModal(customerModal)" class="btn btn-square">
                  <i class="fas fa-plus"></i>
                </button>
              </div>
              <div
                style="margin-top: -10px"
                [ngClass]="{
                  'is-invalid': f.customerName.touched && (f.customerName.errors || f.customerName.errors?.required)
                }"
              ></div>
              <div *ngIf="f.customerName.touched" class="invalid-feedback" style="margin-bottom: 15px">
                กรุณาเลือกฟิลด์นี้
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="ddlReference">อ้างอิง</label>
              <div for="ddlReference" class="form-group mb-0">
                <ng-select
                  id="ddlReference"
                  [items]="partners"
                  [loading]="isPartnerLoading"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="อ้างอิง"
                  [(ngModel)]="customerRefId"
                  [ngModelOptions]="{ standalone: true }"
                  formControlName="referenceId"
                  [ngClass]="{
                    'is-invalid': f.referenceId.touched && (f.referenceId.errors || f.referenceId.errors?.required)
                  }"
                >
                </ng-select>
                <div *ngIf="f.referenceId.touched" class="invalid-feedback">กรุณาเลือกฟิลด์นี้</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="cusTel">เบอร์โทรศัพท์</label>
              <input
                phoneMask
                type="tel"
                class="form-control"
                id="cusTel"
                readonly
                formControlName="customerPhoneNumber"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label>เบอร์โทรศัพท์สำรอง</label>
              <input
                phoneMask
                type="tel"
                class="form-control"
                name="secondPhone"
                placeholder="เบอร์โทรศัพท์สำรอง"
                formControlName="secondPhone"
              />
            </div>
          </div>
        </div>
        <div class="address-info">
          <h4>รายละเอียดที่อยู่จัดส่ง</h4>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="receiverName">ชื่อผู้รับ</label>
                <input class="form-control" id="receiverName" formControlName="receiverName" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="deliveryPhoneNumber">เบอร์โทรศัพท์ผู้รับ</label>
                <input
                  class="form-control"
                  id="deliveryPhoneNumber"
                  formControlName="deliveryPhoneNumber"
                  phoneMask
                  type="tel"
                />
              </div>
            </div>
          </div>
          <div class="form-group-btn">
            <label for="locationLink">Location link</label>
            <div class="form-group">
              <input class="form-control" id="locationLink" readonly formControlName="locationLink" />
              <button type="button" (click)="openLocationModal(locationModal)" class="btn btn-square">
                <i class="fal fa-map-marker-alt"></i>
              </button>
            </div>
          </div>
          <div class="form-group text-area">
            <label>ที่อยู่</label>
            <button
              type="button"
              (click)="openAddressesModal(addressModal)"
              [disabled]="!selectedCustomer || !isLockAddress"
              class="btn btn-badge"
            >
              เปลี่ยนที่อยู่
            </button>
            <textarea
              class="form-control"
              rows="3"
              formControlName="address"
              placeholder="Enter ..."
              spellcheck="false"
              readonly
            ></textarea>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="subDistrict">แขวง / ตำบล</label>
                <input class="form-control" id="subDistrict" readonly formControlName="subDistrict" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="district">เขต / อำเภอ</label>
                <input class="form-control" id="district" readonly formControlName="district" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="input8">จังหวัด</label>
                <div class="select-form form-group mb-0">
                  <div for="ddlProvince" class="form-group mb-0">
                    <ng-select
                      id="ddlProvince"
                      [items]="provinces"
                      [loading]="isProvinceLoading"
                      bindLabel="name"
                      bindValue="provinceID"
                      placeholder="จังหวัด"
                      [readonly]="true"
                      formControlName="provinceId"
                      [clearable]="false"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="zipcode">รหัสไปรษณีย์</label>
                <input class="form-control" id="zipcode" readonly formControlName="zipCode" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">ช่วงเวลา</div>
          </div>
          <div class="form-group">
            <div class="btn-group d-flex" role="group">
              <button
                type="button"
                class="btn btn-default w-100"
                (click)="setTimeRemark1()"
                [ngClass]="{ active: isTimeRemark1 }"
              >
                ก่อนเที่ยง
              </button>
              <button
                type="button"
                class="btn btn-default w-100"
                (click)="setTimeRemark2()"
                [ngClass]="{ active: isTimeRemark2 }"
              >
                หลังเที่ยง
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="input">หมายเหตุลาเบล</label>
                <textarea
                  class="form-control"
                  rows="3"
                  spellcheck="false"
                  formControlName="deliveryRemark"
                  placeholder="เช่น ส่งช่วง 8:00-12:00"
                ></textarea>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>SMS</label>
                <textarea
                  class="form-control"
                  rows="3"
                  spellcheck="false"
                  formControlName="smsRemark"
                  placeholder="ระบบอัตโนมัติ [09:00-12:00]"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>หมายเหตุถึงคนรถ ฉีกลาเบล</label>
                <textarea
                  class="form-control"
                  rows="3"
                  spellcheck="false"
                  formControlName="factoryRemark"
                  placeholder="เช่น เขียนการ์ดว่า Happy Birthday"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 pr-0 pl-0">
      <div class="order-info">
        <!-- ข้อมูลการสั่งซื้อ -->
        <div class="order-info-add">
          <div class="header">
            <button
              type="button"
              class="btn btn-link"
              (click)="isCollapsedOrderDetail = !isCollapsedOrderDetail"
              [attr.aria-expanded]="!isCollapsedOrderDetail"
              aria-controls="collapseDelivery"
            >
              <i class="fas fa-chevron-right"></i>
              <h4>ข้อมูลการสั่งซื้อ</h4>
            </button>
          </div>
          <div id="collapseDelivery" [collapse]="isCollapsedOrderDetail" [isAnimated]="true">
            <div class="row-1">
              <div class="col-4 pl-0">
                <div class="form-group">
                  <label for="input12">โปรโมชัน</label>
                  <ng-select
                    [items]="promotions"
                    name="promotionId"
                    [loading]="isPromotionLoading"
                    bindValue="id"
                    bindLabel="name"
                    placeholder="โปรโมชั่น"
                    required
                    (change)="onPromotionChange($event)"
                    formControlName="promotionId"
                    [ngClass]="{
                      'is-invalid': f.promotionId?.touched && (f.promotionId.errors || f.promotionId.errors?.required)
                    }"
                  >
                  </ng-select>
                  <div *ngIf="f.promotionId?.touched" class="invalid-feedback">กรุณาเลือกฟิลด์นี้</div>
                </div>
              </div>
              <div class="col-2 pl-0">
                <div class="form-group mb-0">
                  <label for="txtQuantity">จำนวนสินค้า</label>
                  <input
                    id="txtQuantity"
                    type="number"
                    min="{{ minQtyInput$.value }}"
                    max="{{ maxQtyInput$.value }}"
                    class="form-control"
                    formControlName="quantity"
                    (change)="onQuantityChange($event)"
                  />
                </div>
              </div>
              <div class="col-3 pl-0">
                <div class="form-group mb-0">
                  <label for="select5">จำนวน/ขนาดกล่อง</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="boxCode"
                    (input)="boxCodeValidator($event)"
                    (blur)="boxCount($event.target.value)"
                    [ngClass]="{ 'is-invalid': f.boxCode?.touched && (f.boxCode.errors || f.boxCode.errors?.required) }"
                  />
                  <div *ngIf="f.boxCode?.touched" class="invalid-feedback">กรุณากรอกจำนวนให้ถูกต้อง</div>
                  <ng-container *ngFor="let box of boxCountArr">
                    <i class="fal fa-box" style="font-size: 11px"> {{ box.key }} = {{ box.value }}&nbsp;</i>
                  </ng-container>
                </div>
              </div>
              <div class="col-3 pl-0 pr-0">
                <div class="form-group mb-0">
                  <label for="select5">เขตจัดส่ง</label>
                  <input type="text" class="form-control" readonly [value]="setDeliveryZone(f.isUpcountry.value)" />
                </div>
              </div>
            </div>
            <div class="row-2">
              <div class="col-4 pl-0">
                <div class="form-group">
                  <label for="input13">ค่าไอศกรีม</label>
                  <input
                    class="form-control"
                    id="orderPrice"
                    type="number"
                    [readonly]="!isManualPrice"
                    formControlName="orderPrice"
                    (change)="updatePrice()"
                  />
                </div>
              </div>
              <div class="col-2 pl-0">
                <div class="form-group">
                  <label for="input14">ค่าจัดส่ง</label>
                  <input
                    class="form-control"
                    id="deliveryPrice"
                    type="number"
                    [readonly]="!isManualPrice"
                    formControlName="deliveryPrice"
                    (change)="updatePrice()"
                  />
                </div>
              </div>
              <div class="col-3 pl-0">
                <div class="form-group">
                  <label for="input15">ส่วนลด</label>
                  <input
                    type="number"
                    class="form-control"
                    id="discountPrice"
                    min="0"
                    formControlName="discountPrice"
                    (change)="updatePrice()"
                  />
                </div>
              </div>
              <div class="col-3 pl-0 pr-0">
                <div class="form-group">
                  <label for="input15">ราคาสุทธิ</label>
                  <input
                    class="form-control"
                    id="input15"
                    type="number"
                    [readonly]="!isSkipCalPrice"
                    (change)="updateDiscountPrice()"
                    formControlName="totalPrice"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- รายการสั่งซื้อสินค้า -->
        <div class="order-info-add">
          <div class="header">
            <button
              type="button"
              class="btn btn-link"
              (click)="isCollapsed = !isCollapsed"
              [attr.aria-expanded]="!isCollapsed"
              aria-controls="collapseBasic"
            >
              <i class="fas fa-chevron-right"></i>
              <h4>รายการสั่งซื้อสินค้า</h4>
            </button>
            <div class="order-add">{{ getSelectedProductQuantity() }}/{{ maxQuantity$.value }}</div>
          </div>
          <div id="collapseBasic" [collapse]="isCollapsed" [isAnimated]="true">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">รหัสสินค้า</th>
                    <th scope="col">สินค้า (TH)</th>
                    <th scope="col">สินค้า (EN)</th>
                    <th class="text-center" scope="col">จำนวน</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of selectedProducts">
                    <td scope="row">{{ item.code }}</td>
                    <td>{{ item.nameTH }}</td>
                    <td>{{ item.nameEN }}</td>
                    <td class="add-order-col">
                      <div class="add-order">
                        <button type="button" class="btn btn-cal" (click)="onDecreaseProduct(item)">
                          <i class="fal fa-minus"></i>
                        </button>
                        <div class="number-display">
                          <input
                            type="number"
                            style="width: 3em"
                            [value]="item.quantity"
                            (change)="onProductItemValueChange(item, $event)"
                          />
                        </div>
                        <button type="button" class="btn btn-cal" (click)="onIncrementProduct(item)">
                          <i class="fal fa-plus"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="4">
                      <div
                        [ngClass]="{
                          'is-invalid': f.products.touched && (f.products.errors || f.products.errors?.required)
                        }"
                      ></div>
                      <div *ngIf="f.products.touched" class="invalid-feedback">กรุณาเลือกไอศกรีมให้ครบตามจำนวน</div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <!-- รายการของแถม -->
        <div class="order-info-add">
          <div class="header">
            <button
              type="button"
              class="btn btn-link"
              (click)="isCollapsedGift = !isCollapsedGift"
              [attr.aria-expanded]="!isCollapsedGift"
              aria-controls="collapseGift"
            >
              <i class="fas fa-chevron-right"></i>
              <h4>รายการของแถม</h4>
            </button>

            <div class="col-4 pl-0">
              <div class="form-group">
                <ng-select
                  [items]="giftDropdown"
                  [loading]="isGiftItemLoading"
                  bindValue="id"
                  bindLabel="name"
                  placeholder="ของแถม"
                  (open)="onGiftOpen()"
                  (change)="onGiftChange($event)"
                  [searchable]="false"
                  [clearable]="false"
                  [(ngModel)]="selectedGiftValue"
                  [ngModelOptions]="{ standalone: true }"
                >
                </ng-select>
              </div>
            </div>
          </div>
          <div id="collapseGift" [collapse]="isCollapsedGift" [isAnimated]="true">
            <div class="row-2">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">ของแถม</th>
                      <th class="text-center" scope="col">จำนวน</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of selectedGifts">
                      <td scope="row">{{ getGiftName(item.giftItemId) }}</td>
                      <td class="add-order-col">
                        <div class="add-order">
                          <button type="button" class="btn btn-cal" (click)="onDecreaseGift(item)">
                            <i class="fal fa-minus"></i>
                          </button>
                          <div class="number-display">
                            <input type="number" style="width: 3em" [value]="item.amount" [min]="1" />
                          </div>
                          <button type="button" class="btn btn-cal" (click)="onIncrementGift(item)">
                            <i class="fal fa-plus"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <h4>เลือกไอศกรีม</h4>
          </div>
          <div class="col-6 filter-component">
            <div class="search-component">
              <div class="input-group">
                <input
                  type="text"
                  name="q"
                  class="form-control"
                  (focus)="$event.target.value = ''; onFilterProductChange('')"
                  (keyup)="onFilterProductChange($event.target.value)"
                  placeholder="ค้นหาตามชื่อไอศกรีม..."
                />
                <span class="input-group-btn">
                  <button type="button" name="search" id="search-btn" class="btn btn-flat">
                    <i class="fa fa-search"></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="order-filter-add">
          <div class="form-group">
            <div
              class="btn btn-filter filter-item"
              *ngFor="let category of categoryList"
              [class.active]="filterProductId == category.id"
              (click)="onFilterProduct(category)"
            >
              {{ category.name }}
            </div>
          </div>
        </div>
        <div class="col-3 pl-0"></div>
        <div class="row order-image-row">
          <div class="col-3 pr-0 pl-0" *ngFor="let product of productList; let i = index">
            <fieldset
              class="order-image-item"
              (click)="addProduct(product)"
              [disabled]="!product.isActive || product.stockNumber === 0"
            >
              <img [src]="getProductImage(product)" alt="" />
              <span class="product-name">{{ product.nameEN }}</span>
              <section *ngIf="!product.isActive || product.stockNumber === 0">
                <span class="out-of-stock">Out of Stock</span>
              </section>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3 pr-0 pl-0">
      <div class="paid-info">
        <h4>ข้อมูลการจัดส่ง</h4>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>ประเภทขนส่ง</label>
              <ng-select
                name="transportationTypes"
                [items]="orderCommon.transportationTypes"
                [loading]="isPromotionLoading"
                bindValue="id"
                bindLabel="name"
                formControlName="transportationType"
                placeholder="ประเภทขนส่ง"
                [searchable]="false"
                [clearable]="false"
                [ngModel]="setTransportationType(f.isUpcountry.value)"
                [ngModelOptions]="{ standalone: true }"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label>สถานะสินค้า</label>
              <ng-select
                id="ddlOrderStatus"
                [items]="orderCommon.orderStatus"
                bindLabel="name"
                bindValue="id"
                placeholder="Order Status"
                [clearable]="false"
                [ngModelOptions]="{ standalone: true }"
                formControlName="orderStatus"
              >
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>คันรถ</label>
              <input type="text" class="form-control" formControlName="routeName" />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label>คิวขนส่ง</label>
              <input
                type="number"
                (input)="routeQueueValidator($event)"
                class="form-control"
                formControlName="routeQueue"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="paid-info">
        <h4>ข้อมูลการชำระเงิน</h4>
        <div class="row">
          <div class="col-6">
            <label>การชำระเงิน</label>
            <ng-select
              class="ddlPaymentStatus"
              id="ddlPaymentStatus"
              [items]="orderCommon.paymentStatuses"
              bindLabel="name"
              bindValue="id"
              placeholder="การชำระเงิน"
              [clearable]="false"
              formControlName="paymentStatus"
            >
            </ng-select>
          </div>
        </div>
      </div>
      <div class="ref-info">
        <h4>ข้อมูลการอ้างอิง</h4>
        <app-drag-upload
          (getBaseText)="saveReferenceImage($event)"
          (onClear)="clearReferenceImage()"
          [imageUrl]="referenceImage$ | async"
        >
        </app-drag-upload>
        <hr />
        <div formArrayName="referenceInformation">
          <div *ngFor="let reference of getReferences(orderForm); let i = index" [formGroupName]="i">
            <div class="row">
              <div class="col-12">
                <div class="form-group-switch">
                  <label *ngIf="reference.value.fileReferenceTypeId == 2" class="label-text"
                    >รูปหลักฐานการชำระเงิน
                  </label>

                  <label *ngIf="reference.value.fileReferenceTypeId == 3" class="label-text"
                    >รูปหลักฐานการจัดส่ง
                  </label>
                  <button
                    type="button"
                    class="btn btn-delete pull-right"
                    (click)="openConfirmDeleteModal(confirmDeleteModal, i)"
                  >
                    <i class="fal fa-trash"></i>
                  </button>
                  <div class="short">
                    <app-drag-upload
                      (getBaseText)="saveReferenceImageList($event, i)"
                      (onClear)="clearReferenceImageList(i)"
                      [imageUrl]="referenceImageList$.value[i]"
                    >
                    </app-drag-upload>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="reference.value.fileReferenceTypeId == 2">
              <div class="col-6">
                <div class="form-group">
                  <label>ช่องทางการชำระ</label>
                  <ng-select
                    class="ddlPaymentStatus"
                    id="ddlPaymentStatus"
                    [items]="paymentGateway"
                    [loading]="isPaymentGatewayLoading"
                    [(ngModel)]="reference.value.paymentGatewayId"
                    bindLabel="name"
                    bindValue="id"
                    placeholder="ประเภทของรูป"
                    [clearable]="false"
                    appendTo="body"
                    formControlName="paymentGatewayId"
                    [ngModelOptions]="{ standalone: true }"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>อ้างอิงการชำระ</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="อ้างอิงการชำระ"
                    formControlName="referenceText"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>เก็บจำนวนเงิน</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="จำนวนเงิน"
                    [(ngModel)]="reference.value.billValue"
                    formControlName="billValue"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group date-picker">
                  <label>วัน/เวลาของหลักฐานอ้างอิง</label>
                  <input
                    type="text"
                    placeholder="Start date"
                    class="form-control"
                    bsDatepicker
                    [(ngModel)]="reference.value.paymentDate"
                    formControlName="paymentDate"
                    [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY', customTodayClass: 'today' }"
                  />
                </div>
              </div>
              <div class="col-12">
                <timepicker
                  [(ngModel)]="reference.value.paymentDate"
                  formControlName="paymentDate"
                  [showMeridian]="false"
                ></timepicker>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="add-ref">
                <a (click)="addReference()" class="add-ref-button">+ เพิ่มหลักฐานการอ้างอิง</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- ReferenceModal -->
  <ng-template #referenceModal>
    <div class="modal-stock">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Reference Text</h4>
        <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true"><i class="fal fa-times"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <textarea class="form-control" id="referenceText" formControlName="referenceText" readonly> </textarea>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- Confirm Delete Modal-->
  <ng-template #confirmDeleteModal>
    <div class="modal-stock">
      <div class="modal-header">
        <h4 class="modal-title pull-left">ยืนยันที่จะลบ?</h4>
        <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true"><i class="fal fa-times"></i></span>
        </button>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="modalRef.hide(); onDeleteReference(modalRef.id)" class="btn btn-danger">
          ยืนยัน
        </button>
        <button class="btn btn-primary-outline" (click)="modalRef.hide()">ยกเลิก</button>
      </div>
    </div>
  </ng-template>
  <footer class="fixed-footer">
    <div class="flex-left">
      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          class="custom-control-input"
          (change)="onCheckDataChange($event)"
          id="checkData"
          formControlName="isWaitingData"
        />
        <label class="custom-control-label" for="checkData">รอข้อมูล</label>
      </div>
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          formControlName="waitingData"
          [hidden]="!isWaitingForData"
          placeholder="กรุณากรอกเหตุผล"
          [ngClass]="{
            'is-invalid': f.waitingData.touched && (f.waitingData.errors || f.waitingData.errors?.required)
          }"
        />
      </div>
    </div>
    <div class="button-row-right">
      <div *ngIf="this.orderId">
        <button
          class="btn btn-primary"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="fas fa-ellipsis-v"></i>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="cursor: pointer">
          <span class="dropdown-item" (click)="openOrderHistoryModal(orderHistoryModal)">
            <i class="fas fa-history"></i> ดูประวัติการแก้ไข
          </span>
          <div class="dropdown-divider"></div>
          <span class="dropdown-item" (click)="onAction('line')">
            <i class="fas fa-paper-plane"></i> ส่งไลน์หาลูกค้า</span
          >
          <div class="dropdown-divider"></div>
          <span class="dropdown-item" (click)="onAction('card')"> <i class="fas fa-address-card"></i> ส่งการ์ด</span>
          <div class="dropdown-divider"></div>
          <span class="dropdown-item" (click)="onAction('call')"> <i class="fas fa-car"></i> เรียกรถ</span>
          <div class="dropdown-divider"></div>
          <span class="dropdown-item" (click)="openReturnOrderDialog(returnOrderModal)"
            ><i class="fas fa-backward"></i> Return order</span
          >
        </div>
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="isSubmitOrder">Save</button>
      <button class="btn btn-primary-outline" [routerLink]="['/order']">Cancel</button>
    </div>
  </footer>
</form>

<!-- CustomerModal -->
<ng-template #customerModal>
  <form (ngSubmit)="onCustomerSubmit()" [formGroup]="customerForm">
    <div class="modal-stock">
      <div class="modal-header">
        <h4 class="modal-title pull-left">ข้อมูลลูกค้า</h4>
        <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true"><i class="fal fa-times"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label for="name">ชื่อลูกค้า</label>
              <input
                class="form-control"
                id="name"
                formControlName="name"
                [ngClass]="{
                  'is-invalid': f_customer.name.touched && (f_customer.name.errors || f_customer.name.errors?.required)
                }"
              />
              <div *ngIf="f_customer.name.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="phone">เบอร์โทรศัพท์</label>
              <input
                class="form-control"
                id="customerPhoneNumber"
                formControlName="customerPhoneNumber"
                phoneMask
                type="tel"
                [ngClass]="{
                  'is-invalid':
                    f_customer.customerPhoneNumber.touched &&
                    (f_customer.customerPhoneNumber.errors || f_customer.customerPhoneNumber.errors?.required)
                }"
              />
              <div *ngIf="f_customer.customerPhoneNumber.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="phone">เบอร์โทรศัพท์สำรอง</label>
              <input class="form-control" formControlName="secondPhone" phoneMask type="tel" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="ddlReference">อ้างอิง</label>
              <div for="ddlReference" class="form-group mb-0">
                <ng-select
                  id="ddlReference"
                  [items]="partners"
                  [loading]="isPartnerLoading"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="อ้างอิง"
                  [ngModelOptions]="{ standalone: true }"
                  formControlName="customerReferId"
                >
                </ng-select>
                <div *ngIf="f_customer.customerReferId.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <input id="pac-input" class="form-control" type="text" placeholder="Search Box" />
        </div>
        <div class="form-group">
          <div #locationMap id="locationMap" style="width: 100%; height: 340px"></div>
        </div>
        <div class="form-group">
          <div class="custom-control custom-checkbox" style="padding-left: 2.5rem">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customCheck1"
              (change)="makeReceiverAsCustomer($event, f_customer)"
            />
            <label class="custom-control-label" for="customCheck1">ลูกค้าเป็นผู้รับสินค้าเอง</label>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="deliveryName">ชื่อผู้รับ</label>
              <input
                class="form-control"
                id="deliveryName"
                formControlName="deliveryName"
                [ngClass]="{
                  'is-invalid':
                    f_customer.deliveryName.touched &&
                    (f_customer.deliveryName.errors || f_customer.deliveryName.errors?.required)
                }"
              />
              <div *ngIf="f_customer.deliveryName.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="deliveryPhoneNumber">เบอร์ผู้รับ</label>
              <input
                class="form-control"
                id="deliveryPhoneNumber"
                formControlName="deliveryPhoneNumber"
                phoneMask
                type="tel"
                [ngClass]="{
                  'is-invalid':
                    f_customer.deliveryPhoneNumber.touched &&
                    (f_customer.deliveryPhoneNumber.errors || f_customer.deliveryPhoneNumber.errors?.required)
                }"
              />
              <div *ngIf="f_customer.deliveryPhoneNumber.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="locationLink">Location Link</label>
          <input
            class="form-control"
            id="locationLink"
            formControlName="locationLink"
            [ngClass]="{
              'is-invalid':
                f_customer.locationLink.touched &&
                (f_customer.locationLink.errors || f_customer.locationLink.errors?.required)
            }"
          />
          <div *ngIf="f_customer.locationLink.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
        </div>
        <div class="form-group">
          <label for="input">ที่อยู่</label>
          <textarea
            class="form-control"
            rows="3"
            spellcheck="false"
            formControlName="address"
            [ngClass]="{
              'is-invalid':
                f_customer.address.touched && (f_customer.address.errors || f_customer.address.errors?.required)
            }"
          ></textarea>
          <div *ngIf="f_customer.address.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="subDistrict">แขวง / ตำบล</label>
              <input
                class="form-control"
                id="subDistrict"
                formControlName="subDistrict"
                [ngClass]="{
                  'is-invalid':
                    f_customer.subDistrict.touched &&
                    (f_customer.subDistrict.errors || f_customer.subDistrict.errors?.required)
                }"
              />
              <div *ngIf="f_customer.subDistrict.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="district">เขต / อำเภอ</label>
              <input
                class="form-control"
                id="district"
                formControlName="district"
                [ngClass]="{
                  'is-invalid':
                    f_customer.district.touched && (f_customer.district.errors || f_customer.district.errors?.required)
                }"
              />
              <div *ngIf="f_customer.district.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="input">จังหวัด</label>
              <div class="select-form form-group mb-0">
                <ng-select
                  id="ddlProvince"
                  [items]="provinces"
                  [loading]="isProvinceLoading"
                  bindLabel="name"
                  bindValue="provinceID"
                  placeholder="จังหวัด"
                  formControlName="provinceId"
                  [ngClass]="{
                    'is-invalid':
                      f_customer.provinceId.touched &&
                      (f_customer.provinceId.errors || f_customer.provinceId.errors?.required)
                  }"
                >
                </ng-select>
                <div *ngIf="f_customer.provinceId.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="postcode">รหัสไปรษณีย์</label>
              <input
                class="form-control"
                id="postcode"
                formControlName="postcode"
                [ngClass]="{
                  'is-invalid':
                    f_customer.postcode.touched && (f_customer.postcode.errors || f_customer.postcode.errors?.required)
                }"
              />
              <div *ngIf="f_customer.postcode.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="input">หมายเหตุลาเบล</label>
          <textarea
            class="form-control"
            rows="3"
            placeholder="Enter ..."
            spellcheck="false"
            formControlName="description"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary">Save</button>
      <button type="button" class="btn btn-primary-outline" (click)="modalRef.hide()">Cancel</button>
    </div>
  </form>
</ng-template>

<!-- LocationModal-->
<ng-template #locationModal>
  <div class="modal-stock">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Maps</h4>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true"><i class="fal fa-times"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <input id="pac-input" class="form-control" type="text" placeholder="Search Box" />
      </div>
      <div class="form-group">
        <div #locationMap id="locationMap" style="width: 100%; height: 340px"></div>
      </div>
      <div class="button-row button-row-right">
        <button type="button" class="btn btn-primary" (click)="setLocationFromMap()">OK</button>
        <button type="button" class="btn btn-primary-outline" (click)="modalRef.hide()">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>

<!-- AddressModal -->
<ng-template #addressModal>
  <div class="modal-stock">
    <div class="modal-header">
      <h4 class="modal-title pull-left">เปลี่ยนที่อยู่</h4>
      <button type="button" class="close" aria-label="Close" (click)="onAddressModalClose()">
        <span aria-hidden="true"><i class="fal fa-times"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <table class="table table-hover">
            <tbody [hidden]="addressCreateMode">
              <tr
                *ngFor="let address of customerAddresses; let i = index"
                class="customer-address"
                [ngStyle]="{ 'background-color': i === selectedIndexAddress ? '#ababab' : '' }"
              >
                <td>
                  <button
                    type="button"
                    class="btn btn-square-choose"
                    (click)="onChangeAddress(address); modalRef.hide()"
                    [hidden]="addressEditMode || addressCreateMode"
                  >
                    เลือกที่อยู่
                  </button>
                </td>
                <td style="width: 80%">
                  <span class="title">{{ address.addressName }}</span>
                  <p>
                    {{ displayAddressInfo(address) }}
                  </p>
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-square-edit"
                    (click)="onEditOrCreateAddress(true, address, i)"
                    [hidden]="addressEditMode || addressCreateMode"
                  >
                    <i class="fal fa-edit"></i>
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <button
                    type="button"
                    class="btn btn-primary"
                    [hidden]="addressEditMode || addressCreateMode"
                    (click)="onEditOrCreateAddress(false)"
                  >
                    <i class="fal fa-plus"></i> เพิ่มที่อยู่ใหม่
                  </button>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div class="row" [hidden]="!selectedAddressInModal">
        <div class="col-12">
          <form [formGroup]="addressForm" (ngSubmit)="onAddressSubmit()">
            <div class="row">
              <div class="col-4">
                <div class="form-group">
                  <label for="name">ชื่อลูกค้า</label>
                  <input class="form-control" formControlName="customerName" />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label for="phone">เบอร์โทรศัพท์</label>
                  <input class="form-control" phoneMask type="tel" formControlName="phoneNumber" />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label for="phone">เบอร์โทรศัพท์สำรอง</label>
                  <input class="form-control" formControlName="secondPhone" phoneMask type="tel" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="ddlReference">อ้างอิง</label>
                  <div for="ddlReference" class="form-group mb-0">
                    <ng-select
                      id="ddlReference"
                      [items]="partners"
                      [loading]="isPartnerLoading"
                      bindLabel="name"
                      bindValue="id"
                      placeholder="อ้างอิง"
                      [(ngModel)]="customerRefId"
                      [ngModelOptions]="{ standalone: true }"
                      [readonly]="true"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <input id="pac-input" class="form-control" type="text" placeholder="Search Box" />
            </div>
            <div class="form-group">
              <div #locationMap id="locationMap" style="width: 100%; height: 340px"></div>
            </div>
            <div class="form-group">
              <div class="custom-control custom-checkbox" style="padding-left: 2.5rem">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customCheck1"
                  [(ngModel)]="isCheckSameName"
                  (change)="makeReceiverAsCustomer($event, f_address)"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="custom-control-label" for="customCheck1">ลูกค้าเป็นผู้รับสินค้าเอง</label>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="deliveryName">ชื่อผู้รับ</label>
                  <input
                    class="form-control"
                    id="deliveryName"
                    formControlName="deliveryName"
                    [ngClass]="{
                      'is-invalid':
                        f_address.deliveryName.touched &&
                        (f_address.deliveryName.errors || f_address.deliveryName.errors?.required)
                    }"
                  />
                  <div *ngIf="f_address.deliveryName.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="deliveryPhoneNumber">เบอร์โทรศัพท์ผู้รับ</label>
                  <input
                    class="form-control"
                    id="deliveryPhoneNumber"
                    phoneMask
                    type="tel"
                    formControlName="deliveryPhoneNumber"
                    [ngClass]="{
                      'is-invalid':
                        f_address.deliveryPhoneNumber.touched &&
                        (f_address.deliveryPhoneNumber.errors || f_address.deliveryPhoneNumber.errors?.required)
                    }"
                  />
                  <div *ngIf="f_address.deliveryPhoneNumber.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="locationLink">Location Link</label>
              <input
                class="form-control"
                id="locationLink"
                formControlName="locationLink"
                [ngClass]="{
                  'is-invalid':
                    f_address.locationLink.touched &&
                    (f_address.locationLink.errors || f_address.locationLink.errors?.required)
                }"
              />
              <div *ngIf="f_address.locationLink.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
            </div>
            <div class="form-group">
              <label for="input">ที่อยู่</label>
              <textarea
                class="form-control"
                rows="3"
                spellcheck="false"
                formControlName="address"
                [ngClass]="{
                  'is-invalid':
                    f_address.address.touched && (f_address.address.errors || f_address.address.errors?.required)
                }"
              ></textarea>
              <div *ngIf="f_address.address.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="subDistrict">แขวง / ตำบล</label>
                  <input
                    class="form-control"
                    id="subDistrict"
                    formControlName="subDistrict"
                    [ngClass]="{
                      'is-invalid':
                        f_address.subDistrict.touched &&
                        (f_address.subDistrict.errors || f_address.subDistrict.errors?.required)
                    }"
                  />
                  <div *ngIf="f_address.subDistrict.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="district">เขต / อำเภอ</label>
                  <input
                    class="form-control"
                    id="district"
                    formControlName="district"
                    [ngClass]="{
                      'is-invalid':
                        f_address.district.touched && (f_address.district.errors || f_address.district.errors?.required)
                    }"
                  />
                  <div *ngIf="f_address.district.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="input">จังหวัด</label>
                  <div class="select-form form-group mb-0">
                    <ng-select
                      id="ddlProvince"
                      [items]="provinces"
                      [loading]="isProvinceLoading"
                      bindLabel="name"
                      bindValue="provinceID"
                      placeholder="จังหวัด"
                      formControlName="provinceId"
                      [ngClass]="{
                        'is-invalid':
                          f_address.provinceId.touched &&
                          (f_address.provinceId.errors || f_address.provinceId.errors?.required)
                      }"
                    >
                    </ng-select>
                    <div *ngIf="f_address.provinceId.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="postcode">รหัสไปรษณีย์</label>
                  <input
                    class="form-control"
                    id="postcode"
                    formControlName="postcode"
                    [ngClass]="{
                      'is-invalid':
                        f_address.postcode.touched && (f_address.postcode.errors || f_address.postcode.errors?.required)
                    }"
                  />
                  <div *ngIf="f_address.postcode.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="input">หมายเหตุลาเบล</label>
              <textarea
                class="form-control"
                rows="3"
                placeholder="Enter ..."
                spellcheck="false"
                formControlName="description"
              ></textarea>
            </div>
            <div class="flex-right">
              <button type=" submit" class="btn btn-primary">Save</button>
              <button type="button" class="btn btn-primary-outline" (click)="cancelAddressModal()">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Customer History Modal-->
<ng-template #customerHistoryModal>
  <div class="modal-stock">
    <div class="modal-header">
      <h4 class="modal-title pull-left">ข้อมูลประวัติการสั่งซื้อจากลูกค้า</h4>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true"><i class="fal fa-times"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table id="table-customer-history" class="table">
              <thead>
                <tr>
                  <th>OrderDate</th>
                  <th>Invoice Number</th>
                  <th>CustomerName</th>
                  <th>Address</th>
                  <th>ReferenceNo</th>
                  <th>ReferenceName</th>
                  <th>Amount</th>
                  <th>Payment Method</th>
                  <th>Status</th>
                  <th>File Ref.</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let history of customerHistory">
                  <td>{{ convertDateToString(history.orderDate) }}</td>
                  <td>
                    <a [routerLink]="['/order/edit', history.orderId]" target="_blank" class="btn btn-sm"
                      ><i class="fal fa-eye"></i>{{ history.invoiceNumber }}</a
                    >
                  </td>
                  <td>{{ history.customerName }}</td>
                  <td>{{ history.address }}</td>
                  <td>{{ history.referenceNo }}</td>
                  <td>{{ history.referenceName }}</td>
                  <td>{{ history.amount }}</td>
                  <td>{{ history.paymentMethod }}</td>
                  <td>{{ history.orderStatus }}</td>
                  <td><a href="{{ mapImageUrl(history.fileRefUrl) }}" target="_blank">View File</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Order History Modal-->
<ng-template #orderHistoryModal>
  <div class="modal-stock">
    <div class="modal-header">
      <h4 class="modal-title pull-left">ข้อมูลประวัติการแก้ไขออเดอร์</h4>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true"><i class="fal fa-times"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <table id="table-order-history" class="table">
            <thead>
              <tr>
                <th style="width: 200px">Username</th>
                <th>Change Log</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let history of orderHistory">
                <td>{{ convertDateToString(history.activityDate, true) }}<br />{{ history.userName }}</td>
                <td>
                  <ul *ngFor="let log of history.changeLog" style="list-style-type: none">
                    <li style="white-space: pre">{{ replaceText(log, '_', ' ') }}</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Confirm return order Modal-->
<ng-template #returnOrderModal>
  <div class="modal-stock">
    <div class="modal-header">
      <h4 class="modal-title pull-left">ยืนยันที่จะ Return order?</h4>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true"><i class="fal fa-times"></i></span>
      </button>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="onReturnOrder()" class="btn btn-success">ยืนยัน</button>
      <button class="btn btn-primary-outline" (click)="modalRef.hide()">ยกเลิก</button>
    </div>
  </div>
</ng-template>
