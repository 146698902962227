import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const DATE_FORMAT = 'dd/MM/yyyy';
const MONTH_YEAR_FORMAT = 'MMMM/YYYY';
const DATE_PICKER_FORMAT = DATE_FORMAT.toUpperCase();
const DATE_TIME_FORMAT = DATE_FORMAT + ', HH:mm';
const FULL_DATE_FORMAT = 'EE ' + DATE_FORMAT;
const FULL_DATE_TIME_FORMAT = 'EE ' + DATE_TIME_FORMAT;
const DEFAULT_PER_PAGE = 500;

const IMAGE_URL = environment.IMAGE_URL;
const HTML_OPTION = {
  headers: new HttpHeaders()
    .set('Content-Type', 'application/x-www-form-urlencoded')
    //.set('Access-Control-Allow-Origin','*')
    //.set('Access-Control-Allow-Credentials','true')
    .set('Accept', 'application/json'),
};

const MODAL_CONFIG = {
  keyboard: false,
  backdrop: true,
  ignoreBackdropClick: true,
};

const MODAL_CONFIG_LG = {
  keyboard: false,
  backdrop: true,
  ignoreBackdropClick: true,
  class: 'modal-800',
};
// 5 minute = 300000
export {
  DATE_FORMAT,
  FULL_DATE_FORMAT,
  DATE_TIME_FORMAT,
  FULL_DATE_TIME_FORMAT,
  DATE_PICKER_FORMAT,
  MONTH_YEAR_FORMAT,
  HTML_OPTION,
  IMAGE_URL,
  MODAL_CONFIG,
  MODAL_CONFIG_LG,
  DEFAULT_PER_PAGE,
};
