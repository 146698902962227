import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { CoreModule } from 'src/app/core/core.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DeliveryDashboardComponent } from './delivery-dashboard/delivery-dashboard.component';
import { DeliveryRoutingModule } from './delivery-routing.module';

@NgModule({
  declarations: [DeliveryDashboardComponent],
  imports: [
    NgSelectModule,
    DeliveryRoutingModule,
    BsDatepickerModule.forRoot(),
    NgxPaginationModule,
    SharedModule,
    CoreModule,
    CommonModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DeliveryModule {}
