import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/common.service';
import { OrderService } from 'src/app/core/services/order.service';
import { ProductsService } from 'src/app/core/services/products.service';
import { SaveOrderService } from 'src/app/core/services/save-order.service';
import { OrderCalculatePrice } from 'src/app/models/api-order.model';
import { DropdownItemModel } from 'src/app/models/base/util.model';
import { OrderCommonModel, OrderDetail, OrderPlaceModel } from 'src/app/models/order.model';
import { ProductViewModel, selectedOrderProduct } from 'src/app/models/product.model';

@Component({
  selector: 'app-order-place',
  templateUrl: './order-place.component.html',
  styleUrls: ['./order-place.component.scss'],
})
export class OrderPlaceComponent implements OnInit, OnDestroy {
  @Input() index: Number;
  @Output() valueChange = new EventEmitter<OrderPlaceModel>();

  proviceList: any[] = [];
  setList: any[] = [];
  productList: ProductViewModel[] = [];
  allProductList: ProductViewModel[] = [];
  selectProducts: selectedOrderProduct[] = [];
  numberOfBoxList: DropdownItemModel[] = [];

  numberOfItem: number | string = 0;
  selectedSetId: any;
  numberOfBox = '1';
  filterProductId = 0;
  header = '';
  placeOrderStatus: any[] = [];

  numberOfSet: number = 6;
  orderSubscrip: Subscription;
  orderDetail: OrderDetail = new OrderDetail();
  place: OrderPlaceModel = new OrderPlaceModel();

  isTimeRemark1Active: boolean = false;
  isTimeRemark2Active: boolean = false;

  orderCommon: OrderCommonModel = new OrderCommonModel();

  provinceId: number;
  trasportId: number;
  orderStatusId: number = 1;
  selectedProvince: any;

  constructor(
    private saveOrderService: SaveOrderService,
    private productService: ProductsService,
    private commonService: CommonService,
    private orderService: OrderService
  ) {}

  async ngOnInit(): Promise<void> {
    this.header = 'Place';
    this.mockNumberOfBox();
    this.orderCommon = await this.commonService.getOrderCommon();
    this.getAllSets();
    this.proviceList = this.orderCommon.provinces;

    this.placeOrderStatus = this.orderCommon.paymentStatuses;
    this.orderSubscrip = this.saveOrderService.orderDetail.subscribe((x) => {
      this.orderDetail = x;

      this.place = this.orderDetail.places != null ? this.orderDetail.places : new OrderPlaceModel();
      this.selectedSetId = this.place.setId;
      this.provinceId = +this.place.province;
      this.trasportId = +this.place.transport;
      this.selectedProvince = this.proviceList.find((x) => x.provinceID == this.provinceId);

      if (this.place.timeRemark == 1) {
        this.isTimeRemark1Active = true;
        this.isTimeRemark2Active = false;
      } else if (this.place.timeRemark == 2) {
        this.isTimeRemark1Active = false;
        this.isTimeRemark2Active = true;
      } else {
        this.isTimeRemark1Active = false;
        this.isTimeRemark2Active = false;
      }
    });
  }

  private mockNumberOfBox() {
    for (let index = 1; index <= 10; index++) {
      const item = new DropdownItemModel();
      item.id = index.toString();
      item.name = index.toString();

      this.numberOfBoxList.push(item);
    }
  }

  clickedCalculate() {
    if (this.selectedProvince != null) {
      const setQuantity = this.orderDetail.numberOfSet;
      const isUpcountry = this.selectedProvince?.upcountry;

      this.orderService
        .getCalculatePrice(setQuantity, isUpcountry)
        .pipe(take(1))
        .subscribe((res) => {
          const cal = Object.assign(new OrderCalculatePrice(), res);

          this.place.deliveryPrice = cal.deliveryPrice;
          this.place.orderPrice = cal.orderPrice;
          this.place.totalPrice = cal.summaryPrice;
        });
    }
  }

  toggleTimeRemark1Active(event: any) {
    this.isTimeRemark1Active = !this.isTimeRemark1Active;
    if (event) {
      this.orderDetail.places.timeRemark = 1;
      this.saveOrderService.updateOrderDetail(this.orderDetail);
      this.isTimeRemark2Active = false;
    } else {
      this.orderDetail.places.timeRemark = 0;
      this.saveOrderService.updateOrderDetail(this.orderDetail);
    }
  }

  toggleTimeRemark2Active(event: any) {
    this.isTimeRemark2Active = !this.isTimeRemark2Active;
    if (event) {
      this.orderDetail.places.timeRemark = 2;
      this.saveOrderService.updateOrderDetail(this.orderDetail);
      this.isTimeRemark1Active = false;
    } else {
      this.orderDetail.places.timeRemark = 0;
      this.saveOrderService.updateOrderDetail(this.orderDetail);
    }
  }

  ngOnDestroy() {
    this.orderSubscrip.unsubscribe();
  }

  collapseDone($event: any) {
    this.orderDetail.selectedPlaceIndex = $event.index;
    this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  onSetChange($event: any): void {
    this.orderDetail.places.setId = $event.id;
    this.orderDetail.numberOfSet = +$event.quantity;
    this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  onStatusChange($event: any): void {
    //this.orderStatusId = $event.id;
    this.orderDetail.places.status = $event.id;
    this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  onBoxChange($event: any): void {
    this.orderDetail.places.boxNumber = $event.id;
    this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  getAllSets(): void {
    this.setList = this.orderCommon.promotion.sort((a, b) =>
      a.priority == null ? 1 : a.priority > b.priority ? 1 : -1
    );
  }

  onProvinceChange(value: any) {
    this.place.province = value.id;
    this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  onTransportChange(value: any) {
    this.place.transport = value.id;
    this.saveOrderService.updateOrderDetail(this.orderDetail);
  }
}
