import { Injectable } from '@angular/core';
import { JwtService } from './jwt.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HTML_OPTION } from 'src/app/configs/global';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import { ApiService } from './api.service';
import jwt_decode from 'jwt-decode';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    public router: Router,
    public jwtService: JwtService,
    private userService: UserService,
    private apiService: ApiService
  ) {}

  isAuthenticated() {
    if (this.jwtService.getToken() != null && this.jwtService.getToken() !== '') {
      //   const userAuth = this.authService.getCurrentUser();

      // Test expiration in 5 mins after get JWT
      // Remove 5 mins to full expires after test => jsonJWT.exp * 1000
      const userStr = localStorage.getItem('currentUser');
      if (userStr == '' || userStr == null) return false;

      const user = JSON.parse(userStr);
      const expInFiveMins = moment(user.exp);
      const current = moment();

      if (current >= expInFiveMins) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }

  login(username: string, password: string) {
    let body = new URLSearchParams();
    body.set('username', username);
    body.set('password', password);
    body.set('grant_type', 'password');
    return this.apiService.postLogin(`token`, body).pipe(
      map((user) => {
        console.log(JSON.stringify(user));
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        user.exp = moment().add(user.expires_in, 'seconds');
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.jwtService.saveToken(user.access_token);
        return user;
      })
    );

    // return this.http.post<any>(`${BASE_URL}/token`, body.toString(), HTML_OPTION)
    //   .pipe(map(user => {
    //     console.log(JSON.stringify(user))
    //     // store user details and jwt token in local storage to keep user logged in between page refreshes
    //     localStorage.setItem('currentUser', JSON.stringify(user));

    //     return user;
    //   }));
  }

  async SignOut() {
    localStorage.removeItem('user');
    localStorage.removeItem('user-credential');
    sessionStorage.removeItem('user');
    this.jwtService.destroyToken();
    console.log('logout');
    this.router.navigate(['/', 'login']).then(
      (nav) => {
        console.log(nav); // true if navigation is successful
      },
      (err) => {
        console.log(err); // when there's an error
      }
    );
  }

  async SetUserData(user: any) {
    let userDetail;
    const data = await this.userService.getUserDetail(user.uid).toPromise();
    userDetail = data.data;

    const userData: any = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      name: userDetail.name,
      isAdmin: userDetail.isAdmin,
    };

    this.jwtService.saveToken(await user.getIdToken(true));
    localStorage.setItem('user', JSON.stringify(userData));
  }
}
