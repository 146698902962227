<mk-box>
  <div class="card-header">
    <div class="card-tools">
      <div class="left-column">
        <button type="button" class="btn btn-primary float-right" [routerLink]="'orders'">
          <i class="fas fa-plus"></i> New invoice
        </button>
        <!-- <app-datepicker></app-datepicker> -->
        <input
          type="text"
          placeholder="Date range picker"
          class="form-control"
          (bsValueChange)="dateRangeChange($event)"
          [(ngModel)]="dateRange"
          bsDaterangepicker
        />
      </div>
      <div class="right-column">
        <input
          type="text"
          class="form-control"
          placeholder="Filter"
          aria-label="Recipient's username"
          aria-describedby="button-addon2"
          [(ngModel)]="searchText"
          (keyup.enter)="orderFilter($event)"
        />
        <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="clearFilter()">
          Clear
        </button>
      </div>
    </div>
  </div>
  <div class="table-responsive">
    <table id="example2" class="table table-striped">
      <thead>
        <tr>
          <th>Monitor</th>
          <th>Invoice No.</th>
          <th>Ref</th>
          <th>วันที่รับออเดอร์</th>
          <th>วันที่ส่ง</th>
          <th>Data</th>
          <th>Name</th>
          <th>Set</th>
          <th>Price</th>
          <th>ค่าส่ง</th>
          <th>Total</th>
          <th>Payment</th>
          <th>(Slip)</th>
          <th>Status</th>
          <th>อ้างอิง</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of orderList" style="cursor: pointer">
          <td class="text-center" (click)="rowClick(order)">
            <app-switch-toggle [isCheck]="order.isCheck"></app-switch-toggle>
          </td>
          <td (click)="rowClick(order)">{{ order.invoiceNumber }}</td>
          <td (click)="rowClick(order)">{{ order.ref_id }}</td>
          <td (click)="rowClick(order)">{{ convertDateToString(order.orderDate) }}</td>
          <td (click)="rowClick(order)">{{ convertDateToString(order.deliveryDate) }}</td>
          <td class="text-center">
            <app-switch-toggle [isCheck]="order.isdatacomplete"></app-switch-toggle>
          </td>
          <td (click)="rowClick(order)">{{ order.customerName }}</td>
          <td (click)="rowClick(order)">{{ order.promotionName }}</td>
          <td (click)="rowClick(order)">{{ order.orderPrice! | number : '1.0' : 'en-US' }}</td>
          <td (click)="rowClick(order)">{{ order.deliveryPrice! | number : '1.0' : 'en-US' }}</td>
          <td (click)="rowClick(order)">{{ order.totalPrice! | number : '1.0' : 'en-US' }}</td>
          <td class="text-center">
            <app-switch-toggle [isCheck]="order.isPaid"></app-switch-toggle>
          </td>
          <td class="text-center" (click)="showImageModel(image, order.invUrl)">
            <a *ngIf="order.invUrl"><i class="fas fa-file-alt"></i></a>
          </td>
          <td (click)="rowClick(order)">
            <app-status-badge [statusTypeText]="order.orderStatus"></app-status-badge>
          </td>
          <td (click)="rowClick(order)">{{ order.ref_id_name }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</mk-box>
<ng-template #image let-modal>
  <div class="modal-body">
    <img src="{{ modalImageUrl }}" width="100%" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modalRef.hide()">Close</button>
  </div>
</ng-template>
