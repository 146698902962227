import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './base/api.service';

@Injectable({
  providedIn: 'root',
})
export class PromotionSetService {
  constructor(private apiService: ApiService) {}

  getPromotionList(): Observable<any> {
    return this.apiService.get(`promotions`);
  }

  getPromotionById(promotionId: number): Observable<any> {
    return this.apiService.get(`promotions/${promotionId}`);
  }

  createPromotion(promotionDetail: object): Observable<any> {
    return this.apiService.post(`promotions`, promotionDetail);
  }

  updatePromotionById(promotionId: number, promotionDetail: object): Observable<any> {
    return this.apiService.patch(`promotions/${promotionId}`, promotionDetail);
  }

  deletePromotionById(promotionId: number): Observable<any> {
    return this.apiService.delete(`promotions/${promotionId}`);
  }
}
