export class DeliveryFilterModel {
  start: string | '';
  stop: string | '';
  searchText: string | '';
  routeName: string;
}

export class DeliveryItemsModel {
  index: number;
  routeName: string;
  parcelStatus: ParcelStatus[];
  remaining: RemainingModel;
  remarkCount: number;
}

export class RemainingModel {
  current: number;
  total: number;
}

export class ParcelStatus {
  index: number;
  deliveryStatus: number;
}
