<div class="header-title project-list-title">
  <a (click)="openModal(submitModal)" class="btn btn-primary">Add Set promotions</a>
</div>

<div class="table table-custom product-set-promotion">
  <table
    id="example2"
    class="table table-borderless table-hover dataTable table-responsive"
    role="grid"
    aria-describedby="example2_info"
  >
    <thead>
      <tr role="row">
        <th class="sorting w-20" tabindex="0" aria-controls="example2" rowspan="1" colspan="1" aria-sort="ascending">
          ชื่อ Set promotion
        </th>
        <th class="sorting" tabindex="0" aria-controls="example2" rowspan="1" colspan="1">จำนวนไอเทมในเซ็ต</th>
        <th class="sorting" tabindex="0" aria-controls="example2" rowspan="1" colspan="1">ราคา</th>
        <th class="sorting" tabindex="0" aria-controls="example2" rowspan="1" colspan="1">คำนวนราคา</th>
        <th class="sorting" tabindex="0" aria-controls="example2" rowspan="1" colspan="1">ค่าส่ง กทม / ตจว</th>
        <th class="sorting" tabindex="0" aria-controls="example2" rowspan="1" colspan="1">ระยะเวลาโปรโมชั่น</th>
        <th class="sorting" tabindex="0" aria-controls="example2" rowspan="1" colspan="1">สถานะ</th>
        <th class="sorting w-0 padding" tabindex="0" aria-controls="example2" rowspan="1" colspan="1"></th>
        <th class="sorting w-0 padding" tabindex="0" aria-controls="example2" rowspan="1" colspan="1"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let promotion of pageOfItems">
        <td>{{ promotion.name }}</td>
        <td>{{ promotion.quantity }}</td>
        <td>{{ promotion.price }}</td>
        <td>{{ promotion.calculationMethod }}</td>
        <td>{{ promotion.shippingCost }}</td>
        <td>{{ promotion.promotionPeriod }}</td>
        <td>{{ promotion.isAvailable ? 'Active' : 'InActive' }}</td>
        <td class="padding">
          <button type="button" class="btn btn-primary-outline" (click)="openModal(submitModal, promotion.id)">
            <i class="fal fa-edit"></i>Edit
          </button>
        </td>
        <td class="padding">
          <button type="button" class="btn btn-delete" (click)="openDeleteModal(confirmDeleteModal, promotion.id)">
            <i class="fal fa-trash"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <app-navigation [items]="promotionList" (changePage)="onChangePage($event)"></app-navigation>
</div>

<ng-template #submitModal>
  <form *ngIf="promotionForm" (ngSubmit)="onSubmit()" [formGroup]="promotionForm">
    <div class="modal-stock">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Add set promotion</h4>
        <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true"><i class="fal fa-times"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="input">ชื่อ Set promotion</label>
          <input
            class="form-control"
            id="name"
            formControlName="name"
            [ngClass]="{ 'is-invalid': f.name.touched && (f.name.errors || f.name.errors?.required) }"
          />
          <div *ngIf="f.name.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
        </div>
        <div class="form-group">
          <label for="input">จำนวนไอเทมในเซ็ต</label>
          <input
            type="number"
            class="form-control"
            id="quantity"
            formControlName="quantity"
            [ngClass]="{ 'is-invalid': f.quantity.touched && (f.quantity.errors || f.quantity.errors?.required) }"
          />
          <div *ngIf="f.quantity.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
        </div>
        <div class="form-group">
          <label for="input">ราคา</label>
          <input
            type="number"
            class="form-control"
            id="price"
            formControlName="price"
            [ngClass]="{ 'is-invalid': f.price.touched && (f.price.errors || f.price.errors?.required) }"
          />
          <div *ngIf="f.price.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
        </div>

        <div class="form-group">
          <label for="input">pricePerUnit</label>
          <input
            class="form-control"
            id="pricePerUnit"
            formControlName="pricePerUnit"
            [ngClass]="{ 'is-invalid': f.name.touched && (f.pricePerUnit.errors || f.pricePerUnit.errors?.required) }"
          />
          <div *ngIf="f.pricePerUnit.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
        </div>

        <!-- <div class="radio-group">
          <label>คำนวณราคา</label>
          <div class="custom-control custom-radio">
            <input type="radio" id="customRadio1" name="calculationMethod" class="custom-control-input"
              formControlName="calculationMethod" value="อัตโนมัติ">
            <label class="custom-control-label" for="customRadio1">อัตโนมัติ</label>
          </div>
          <div class="custom-control custom-radio">
            <input type="radio" id="customRadio2" name="calculationMethod" class="custom-control-input"
              formControlName="calculationMethod" value="ช่องทางอื่นๆ">
            <label class="custom-control-label" for="customRadio2">ช่องทางอื่นๆ</label>
          </div>
        </div> -->
        <div class="row">
          <!-- <div class="col-6">
            <div class="form-group">
              <label for="input">ค่าส่ง กรุงเทพ (Optional)</label>
              <input type='number' class="form-control" id="shippingCostOfBKK" formControlName="shippingCostOfBKK">
            </div>
          </div> -->
          <div class="col-6">
            <div class="form-group">
              <label for="input">ราคารวมค่าส่ง</label>
              <input
                type="number"
                class="form-control"
                id="shippingCostOfNonBKK"
                formControlName="shippingCostOfNonBKK"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label>ระยะเวลาการแสดงในหน้าแรกของ App (Optional)</label>
            <div class="date-picker-group">
              <div class="form-group date-picker">
                <input
                  type="text"
                  placeholder="Start date"
                  class="form-control"
                  formControlName="promotionPeriodFrom"
                  bsDatepicker
                  [bsConfig]="{
                    adaptivePosition: true,
                    rangeInputFormat: 'YYYY-MM-DD',
                    dateInputFormat: 'YYYY-MM-DD',
                    showWeekNumbers: false
                  }"
                />
              </div>
              <span>to</span>
              <div class="form-group date-picker">
                <input
                  type="text"
                  placeholder="End date"
                  class="form-control"
                  formControlName="promotionPeriodTo"
                  bsDatepicker
                  [bsConfig]="{
                    adaptivePosition: true,
                    rangeInputFormat: 'YYYY-MM-DD',
                    dateInputFormat: 'YYYY-MM-DD',
                    showWeekNumbers: false
                  }"
                />
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="form-group switch-form">
              <div>คำนวนตามเซ็ต</div>
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="calculateItemPrice"
                  formControlName="calculateItemPrice"
                />
                <label class="custom-control-label" for="calculateItemPrice">Active</label>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group switch-form">
              <div>ซื้อรสชาติซ้ำกันได้</div>
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="canBuySameItem"
                  formControlName="canBuySameItem"
                />
                <label class="custom-control-label" for="canBuySameItem">Active</label>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group switch-form">
              <div>เลือกรสชาติได้</div>
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="canSelectProduct"
                  formControlName="canSelectProduct"
                />
                <label class="custom-control-label" for="canSelectProduct">Active</label>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group switch-form">
              <div>คำนวนสินค้าตามจำนวนถ้วย</div>
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isAutoCalculatedNormalOrder"
                  formControlName="isAutoCalculatedNormalOrder"
                />
                <label class="custom-control-label" for="isAutoCalculatedNormalOrder">Active</label>
              </div>
            </div>
          </div>
          <!--<div class="col-4">
            <div class="form-group switch-form">
              <div>skipCalPrice</div>
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="customSwitch1" formControlName="skipCalPrice">
                <label class="custom-control-label" for="customSwitch1">Active</label>
              </div>
            </div>
          </div>-->
          <div class="col-4">
            <div class="form-group switch-form">
              <div>usePricePerUnit</div>
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="usePricePerUnit"
                  formControlName="usePricePerUnit"
                />
                <label class="custom-control-label" for="usePricePerUnit">Active</label>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="form-group switch-form">
              <div>สถานะ</div>
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="customSwitch1" formControlName="isAvailable" />
                <label class="custom-control-label" for="customSwitch1">Active</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary">{{ promotionId ? 'Edit' : 'Add' }}</button>
        <button type="button" class="btn btn-primary-outline" (click)="modalRef.hide()">Cancel</button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #confirmDeleteModal>
  <div class="modal-stock">
    <div class="modal-header">
      <h4 class="modal-title pull-left">ยืนยันที่จะลบ?</h4>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true"><i class="fal fa-times"></i></span>
      </button>
    </div>
    <div class="modal-body">
      {{ modalRef.content }}
    </div>
    <div class="modal-footer">
      <button type="button" (click)="onDeletePromotion()" class="btn btn-danger">ยืนยัน</button>
      <button class="btn btn-primary-outline" (click)="modalRef.hide()">ยกเลิก</button>
    </div>
  </div>
</ng-template>
