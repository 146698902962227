<div class="row">
  <div class="col-lg-4 col-md-12 primary-info-box">
    <!-- small box -->
    <div
      class="small-box special-box"
      [ngClass]="filter.transactionTypeId === 0 ? 'active' : ''"
      (click)="totalClick(0)"
    >
      <div class="inner">
        <div>TOTAL ORDERS</div>
        <h3>{{ orderTotal.total_order }}</h3>
      </div>
    </div>
  </div>
  <!-- ./col -->
  <div class="col-lg-8 col-md-12">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-xs-6">
            <!-- small box -->
            <div class="small-box" [ngClass]="filter.transactionTypeId === 1 ? 'active' : ''" (click)="totalClick(1)">
              <div class="inner">
                <h3>{{ orderTotal.bankok_metro }}</h3>
                <div class="branch-name">Bangkok metro</div>
              </div>
            </div>
          </div>
          <!-- ./col -->
          <div class="col-xs-6">
            <!-- small box -->
            <div class="small-box" [ngClass]="filter.transactionTypeId === 2 ? 'active' : ''" (click)="totalClick(2)">
              <div class="inner">
                <h3>{{ orderTotal.airplan }}</h3>
                <div class="branch-name">Airplane</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-xs-6">
            <!-- small box -->
            <div class="small-box" [ngClass]="filter.transactionTypeId === 3 ? 'active' : ''" (click)="totalClick(3)">
              <div class="inner">
                <h3>{{ orderTotal.upcountry }}</h3>

                <div class="branch-name">UpCountry</div>
              </div>
            </div>
          </div>
          <!-- ./col -->
          <div class="col-xs-6">
            <!-- small box -->
            <div class="small-box" [ngClass]="filter.transactionTypeId === 4 ? 'active' : ''" (click)="totalClick(4)">
              <div class="inner">
                <h3>{{ orderTotal.pickup }}</h3>
                <div class="branch-name">Pick up at factory<br />And online delivery</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./col -->
</div>
