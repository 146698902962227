<app-card class="payment-delivery-wrapper">
  <div
    class="payment-delivery-box"
    *ngFor="let partner of partnerList"
    [ngClass]="filter.partnerId == partner.id ? 'active' : ''"
    (click)="filterClick(partner.id)"
  >
    <div class="type">
      {{ partner.name }}
    </div>
    <div class="amount">
      {{ partner.totalorder }}
    </div>
    <div class="details-wrapper">
      <div class="type-detail">
        <div class="field-label">Payment</div>
        <div class="field-content">{{ partner.payment }}</div>
      </div>
      <div class="type-detail">
        <div class="field-label">Delivery</div>
        <div class="field-content">{{ partner.delivery }}</div>
      </div>
    </div>
  </div>
</app-card>
