import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AccordionModule as MkAccordionModule, BoxModule, LayoutModule } from 'angular-admin-lte';
import { LoadingPageModule, MaterialBarModule } from 'angular-loading-page';
import { NumberPickerModule } from 'ng-number-picker';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';
import { NgxPaginationModule } from 'ngx-pagination';
import { TagifyModule } from 'ngx-tagify';
import { adminLteConf } from './admin-lte.conf';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AuthGuard } from './core/guards/auth.guard';
import { ApiService } from './core/services/base/api.service';
import { JwtService } from './core/services/base/jwt.service';
import { OrderService } from './core/services/order.service';
import { PagesModule } from './pages/page.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthGuard,
    ApiService,
    JwtService,
    OrderService,
  ],
  imports: [
    PagesModule,
    AppRoutingModule,
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule.forRoot(adminLteConf),
    LoadingPageModule,
    MaterialBarModule,
    BsDatepickerModule.forRoot(),
    NumberPickerModule,
    MkAccordionModule,
    BoxModule,
    NgSelectModule,
    NgxFileDragDropModule,
    NgxPaginationModule,
    SharedModule,
    NgSelectModule,
    ModalModule.forRoot(),
    SweetAlert2Module.forRoot(),
    NgxDropzoneModule,
    TagifyModule.forRoot(),
    CollapseModule.forRoot(),
  ],
  declarations: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
