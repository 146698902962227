<div class="recheck-sidebar" *ngIf="orderId">
  <app-sidebar-right-inner [order]="recheckModel"></app-sidebar-right-inner>
</div>
<form>
  <div class="row">
    <div class="col-lg-3">
      <div class="d-flex form-row">
        <div class="form-group">
          <label for="InvoiceNumber">Invoice number.</label>
          <input
            type="text"
            class="form-control"
            name="InvoiceNumber"
            id="InvoiceNumber"
            placeholder="Invoice No."
            [(ngModel)]="orderDetail.invoiceNumber"
            readonly
          />
        </div>
        <div class="form-group ml-1">
          <label for="refInvoice">Ref</label>
          <div class="d-flex">
            <input
              type="text"
              class="form-control"
              id="refInvoice"
              placeholder="referenceText"
              name="reference"
              [(ngModel)]="orderDetail.referenceText"
            />
            <button type="button" class="btn btn-primary ml-1" (click)="testMessage()">T</button>
          </div>
        </div>
      </div>
      <div class="d-flex form-row">
        <div class="form-group">
          <label for="InvoiceNumber">วันที่รับออเดอร์</label>
          <input
            type="text"
            placeholder="Date"
            class="form-control date-input"
            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
            [bsValue]="orderDetail.orderDate!"
            bsDatepicker
          />
        </div>
        <div class="form-group ml-1">
          <label for="InvoiceNumber">อ้างอิง</label>
          <ng-select
            [items]="orderCommon.partners"
            bindLabel="name"
            bindValue="id"
            (change)="onPartnersChange($event)"
            placeholder="อ้างอิง"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="orderDetail.ref_id"
          >
          </ng-select>
        </div>
      </div>
      <div class="d-flex form-row">
        <div class="form-group">
          <label for="InvoiceNumber">Molto customer</label>
          <div class="input-group multiple-icon">
            <ng-select
              placeholder="Molto customer"
              [items]="customerList$ | async"
              name="customerId"
              bindLabel="name"
              [(ngModel)]="customerName"
              [addTag]="true"
              [multiple]="false"
              [hideSelected]="true"
              [trackByFn]="trackByFn"
              [minTermLength]="2"
              [loading]="isCustomerLoading"
              typeToSearchText="Please enter 2 or more characters"
              bindValue="name"
              [typeahead]="searchText$"
              [ngModelOptions]="{ standalone: true }"
              (change)="onSelectCustomer($event)"
            >
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                <div>
                  <span>{{ item.name }}</span>
                </div>
                <div>
                  <small><b>โทร:</b> {{ item.phone }}</small>
                </div>
                <small><b>ที่อยู่:</b> {{ item.deliveryAddress }}</small>
              </ng-template>
            </ng-select>
            <!-- <input type="text" class="form-control" id="refInvoice" placeholder="Molto customer"> -->
            <span class="input-group-addon">
              <i class="fas fa-filter"></i>
            </span>
            <button class="btn btn-primary ml-1" (click)="openCreateModal(template)">
              <i class="fas fa-plus"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="d-flex form-row">
        <div class="form-group">
          <label for="InvoiceNumber">Tel</label>
          <div class="input-group">
            <span class="input-group-addon">
              <i class="fas fa-phone-alt"></i>
            </span>
            <input
              type="text"
              class="form-control"
              id="refInvoice"
              placeholder="Tel"
              name="tel"
              [(ngModel)]="orderDetail.tel"
            />
          </div>
        </div>
      </div>
      <div class="img-preview">
        <div class="img-group">
          <div class="field-label">รูปอ้างอิงข้อมูล</div>
          <app-drag-upload
            (getBaseText)="saveRefImage($event)"
            (onClear)="clearRefImage()"
            [imageUrl]="refImageUrl$ | async"
          ></app-drag-upload>
          <!-- <file-upload formControlName="files" [multiple]="true" [animation]="true"></file-upload> -->
        </div>
        <div class="img-group">
          <div class="field-label">หลักฐานการโอนเงิน</div>
          <app-drag-upload
            (getBaseText)="saveProfImage($event)"
            (onClear)="clearProfImage()"
            [imageUrl]="profImageUrl$ | async"
          >
          </app-drag-upload>
        </div>
      </div>
      <div class="form-row">
        <label for="myfile">More slip:</label>
        <app-drag-upload
          (getBaseText)="saveMoreSlipImage($event)"
          (onClear)="clearMoreSlipImage()"
          [imageUrl]="moreSlipImageUrl$ | async"
        >
        </app-drag-upload>
      </div>
    </div>
    <div class="col-lg-4">
      <!-- <div class="d-flex form-row">
        <div class="form-group">
          <label for="InvoiceNumber">ราคา(สุทธิ)</label>
          <input type="text" class="form-control" id="InvoiceNumber" placeholder="ราคา(สุทธิ)" readonly>
        </div>
        <div class="form-group ml-1">
          <label for="refInvoice">ค่าไอศครีม</label>
          <input type="text" class="form-control" id="refInvoice" placeholder="ค่าไอศครีม" readonly>
        </div>
        <div class="form-group ml-1">
          <label for="refInvoice">ค่าจัดส่ง</label>
          <input type="text" class="form-control" id="refInvoice" placeholder="ค่าจัดส่ง" readonly>
        </div>
      </div> -->
      <div class="d-flex form-row">
        <div class="form-group">
          <label for="paymentType">การชำระเงิน</label>
          <ng-select
            [items]="orderCommon.paymentStatuses"
            bindLabel="name"
            bindValue="id"
            placeholder="การชำระเงิน"
            (change)="setPaymentChange($event)"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="orderDetail.paymentStatus"
          >
          </ng-select>
        </div>
        <div class="form-group ml-1">
          <label for="InvoiceNumber">ตรวจทาน</label>
          <app-switch-toggle [isCheck]="orderDetail.isCheck" (changedValue)="onChecked($event)"> </app-switch-toggle>
        </div>
        <div class="form-group ml-1">
          <label for="saveButton"> </label>
          <button
            type="button"
            id="saveButton"
            class="btn btn-primary saveButton"
            [disabled]="!orderDetail.isSaveable"
            (click)="onSave()"
          >
            Save
          </button>
        </div>
      </div>
      <!-- <hr> -->
      <app-order-place></app-order-place>
      <!-- <hr> -->
      <!-- <div class="add-new-wrapper">
        <button type="button" class="btn btn-primary" (click)="addNewPlace()"><i class="fas fa-plus"></i>&nbsp;New
          Place</button>
      </div> -->
    </div>
    <div class="col-lg-5">
      <app-order-product [numberOfSet]="numberOfSet" (valueChange)="(selectProducts)"></app-order-product>
    </div>
  </div>
</form>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">เปลี่ยนที่อยู่การจัดส่ง</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-no-label">
      <div class="row">
        <div class="col-md-6">
          <div class="form-row">
            <div class="form-group">
              <label for="name" class="sr-only">Name</label>
              <input type="text" class="form-control" id="name" placeholder="Name" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="tel" class="sr-only">Tel.</label>
              <input type="text" class="form-control" id="tel" placeholder="Tel" />
            </div>
          </div>
          <div class="list-shipping-address">
            <div class="shipping-info-wrapper" (click)="toggleSelectAddress1()">
              <div class="default-label">[Default]</div>
              <div class="primary-shipping-info">
                <ng-container *ngIf="isSelectedAddress1">
                  <i class="fa fa-check-square"></i>
                </ng-container>
                <div class="info">
                  <div class="field-content">XXXXXXXX XXXXXXXX</div>
                </div>
              </div>
              <div class="shipping-info">
                <div class="field-content">(081)-9876543</div>
                <div class="field-content">38/89 หมู่ 7 หมู่บ้านพฤกษา 65/2 ต.คลองสอง อ.คลองหลวง จ.ปทุมธานี 12210</div>
              </div>
            </div>
            <div class="shipping-info-wrapper" (click)="toggleSelectAddress2()">
              <div class="primary-shipping-info">
                <ng-container *ngIf="isSelectedAddress2">
                  <i class="fa fa-check-square"></i>
                </ng-container>
                <div class="info">
                  <div class="field-content">XXXXXXXX XXXXXXXX</div>
                </div>
              </div>
              <div class="shipping-info">
                <div class="field-content">(081)-9876543</div>
                <div class="field-content">38/89 หมู่ 7 หมู่บ้านพฤกษา 65/2 ต.คลองสอง อ.คลองหลวง จ.ปทุมธานี 12210</div>
              </div>
            </div>
            <div class="add-new-wrapper text-right">
              <button type="submit" class="btn btn-primary btn-xs"><i class="fas fa-plus"></i>&nbsp;New address</button>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="address-form">
            <div class="form-row">
              <div class="form-group">
                <label for="name" class="sr-only">Name</label>
                <input type="text" class="form-control" id="name" placeholder="Name" />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label for="tel" class="sr-only">Tel.</label>
                <input type="text" class="form-control" id="tel" placeholder="Tel" />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label for="address" class="sr-only">Address</label>
                <textarea class="form-control" name="" id="" cols="30" rows="3" placeholder="Address"></textarea>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label for="province" class="sr-only">Province</label>
                <ng-select
                  [items]="cars"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="Province"
                  [(ngModel)]="selectedCar"
                >
                </ng-select>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label for="zipcode" class="sr-only">Zipcode</label>
                <input type="text" class="form-control" id="tel" placeholder="Zipcode" />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label for="InvoiceNumber" class="sr-only">Location link</label>
                <input type="text" class="form-control" id="InvoiceNumber" placeholder="Location link" />
              </div>
            </div>
            <div class="form-action">
              <div class="default-toggle">
                <app-switch-toggle></app-switch-toggle>
                <div class="field-label">Default</div>
              </div>
              <button type="submit" class="btn btn-primary btn-xs">บันทึก</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modalRef.hide()">ยกเลิก</button>
    <button type="button" class="btn btn-primary">ยืนยัน</button>
  </div>
</ng-template>
