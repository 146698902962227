export class PagingModel {
  currentPage: string | number | 1;
  maxPage: string | number | 0;
  perPage: string | number | 0;
  totalItems: string | number | 0;
}

export class ListPageModel {
  filter: string | '';
  pagination: PagingModel | any | [];
  results: any[] | [];
  sortBy: string;
}

export class OrderListPage extends ListPageModel {
  sumDeliveryPrice: number;
  sumDiscountPrice: number;
  sumOrderPrice: number;
  sumTotalPrice: number;
  sumItem: number;
}

export class SortByModel {
  sortBy: string | '';
  descending: boolean = false;
}
