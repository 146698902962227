export class OrderFilterModel {
  start: string | '';
  stop: string | '';
  deliveryStatusId?: number;
  partnerId?: number;
  paymentStatusId?: number;
  transactionTypeId?: number;
  isWaitingData?: boolean;
  isFilterProductName?: boolean;
  isSearchByDeliveryDate?: boolean;
  paymentGateways?: number[];
  searchText: string | '';
}
