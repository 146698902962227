import { Component, OnInit, TemplateRef } from '@angular/core';
import { isNil } from 'lodash/fp';
import moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DEFAULT_PER_PAGE, MODAL_CONFIG_LG } from 'src/app/configs/global';
import { DateFilterEnum } from 'src/app/core/enum/dateFilter.enum';
import { DeliveryFilterEnum } from 'src/app/core/enum/deliveryFilter.enum';
import { CommonService } from 'src/app/core/services/common.service';
import { DeliveryService } from 'src/app/core/services/delivery.service';
import { InformationMessageService } from 'src/app/core/services/information-message.service';
import { OrderRecheckService } from 'src/app/core/services/order-recheck.service';
import { OrderService } from 'src/app/core/services/order.service';
import { ListPageModel, PagingModel, SortByModel } from 'src/app/models/base/paging.model';
import { ITEM_PER_PAGE } from 'src/app/models/base/util.model';
import { DeliveryFilterModel, DeliveryItemsModel, RemainingModel } from 'src/app/models/delivery.model';
import { OrderRecheckModel } from 'src/app/models/order-recheck.model';
import { OrderCommonModel } from 'src/app/models/order.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-delivery-dashboard',
  templateUrl: './delivery-dashboard.component.html',
  styleUrls: ['./delivery-dashboard.component.scss'],
})
export class DeliveryDashboardComponent implements OnInit {
  listPageModel: ListPageModel;
  pagingModel: PagingModel;
  sorting: SortByModel;
  totalParcel: RemainingModel = new RemainingModel();
  totalParcelTime: RemainingModel = new RemainingModel();
  totalParcelTimeMorning: RemainingModel = new RemainingModel();
  totalParcelTimeAfternoon: RemainingModel = new RemainingModel();
  deliveryItemTop: DeliveryItemsModel[] = [];
  deliveryItemBottom: DeliveryItemsModel[] = [];
  orderCommon: OrderCommonModel = new OrderCommonModel();
  orderRecheck: OrderRecheckModel = new OrderRecheckModel();
  startDate: Date;
  endDate: Date;
  isDateStartDisabled = true;
  isDateEndDisabled = true;
  isDateEndHidden = false;
  isSelectedRouteName = false;
  isAutoRefresh = true;
  selectedDateFilter = DateFilterEnum.Today;
  filterModel: DeliveryFilterModel;
  momentFormat = 'YYYY-MM-DDTHH:mm:ss';
  dateFilterList = Object.values(DateFilterEnum);
  defaultDateFilter = DateFilterEnum.Today;
  deliveryFilterEnum = DeliveryFilterEnum;
  itemPerPage: number = DEFAULT_PER_PAGE;
  routeSection: string;
  routeIndex: number;
  itemPerPageValue = ITEM_PER_PAGE;
  modalRef: BsModalRef;
  counterDateStart = 0;
  counterDateEnd = 0;
  sortingValue;
  tableHeader = [
    {
      key: 'orderId',
      value: 'ออเดอร์',
    },
    {
      key: 'referenceId',
      value: 'Reference',
    },
    {
      key: 'carNumber',
      value: 'Car No.',
    },
    {
      key: 'customer',
      value: 'ลูกค้า',
    },
    {
      key: 'phone',
      value: 'เบอร์โทรศัพท์',
    },
    {
      key: 'address',
      value: 'ที่อยู่',
    },
    {
      key: 'provinceName',
      value: 'จังหวัด',
    },
    {
      key: 'boxCount',
      value: 'จำนวนกล่อง',
    },
    {
      key: 'deliveryStatus',
      value: 'DeliveryStatus',
    },
    {
      key: 'remark',
      value: 'Remark',
    },
    {
      key: 'waittingForDataText',
      value: 'รอข้อมูล',
    },
    {
      key: '',
      value: 'หลักฐานการจัดส่ง',
    },
    {
      key: '',
      value: '',
    },
  ];

  constructor(
    private commonService: CommonService,
    private deliveryService: DeliveryService,
    private modalService: BsModalService,
    private orderService: OrderService,
    private messageService: InformationMessageService,
    private orderRecheckService: OrderRecheckService
  ) {
    const setIntervalConst: ReturnType<typeof setInterval> = setInterval(() => {
      if (!document.hidden && this.isAutoRefresh) {
        this.getDeliveryByDateRange();
      }
    }, 10000);
  }
  private getDeliveryRouteDateRange(from: string, to: string) {
    this.deliveryService.getDeliveryRouteDateRange(from, to).subscribe((res) => {
      let list = res?.carOverview?.map((x: any) => Object.assign(new DeliveryItemsModel(), x));
      const halfList = Math.ceil(list.length / 2);
      if (halfList > 0) {
        this.deliveryItemTop = res.carOverview.slice(0, halfList);
        this.deliveryItemBottom = list.slice(halfList);
      }
      this.totalParcel = res.totalParcel;
      this.totalParcelTime = res.totalParcelTime;
      this.totalParcelTimeMorning = res.totalParcelTimeMorning;
      this.totalParcelTimeAfternoon = res.totalParcelTimeAfternoon;
    });
  }

  private convertPagingModel() {
    let modelStr = `{`;
    modelStr = modelStr + `"TotalItems":"${this.pagingModel.totalItems}"`;
    modelStr = modelStr + `,"PerPage":"${this.itemPerPage}"`;
    modelStr = modelStr + `,"CurrentPage":"${this.pagingModel.currentPage}"`;
    modelStr = modelStr + `,"MaxPage":"${this.pagingModel.maxPage}"`;
    modelStr = modelStr + `}`;
    return modelStr;
  }

  private convertFilterModel() {
    let modelStr = `{`;
    modelStr = modelStr + `"FilterText":"${this.filterModel.searchText}"`;

    // RouteName
    if (!isNil(this.filterModel.routeName)) {
      modelStr = modelStr + `,"routeName":"${this.filterModel.routeName}"`;
    }

    modelStr = modelStr + `}`;
    return modelStr;
  }

  private convertSortModel() {
    return `{"SortBy":"${this.sorting.sortBy}","Descending":${this.sorting.descending}}`;
  }

  getDeliveryByDateRange() {
    this.deliveryService
      .getDeliveryDateRange(
        this.filterModel.start,
        this.filterModel.stop,
        this.convertPagingModel(),
        this.convertFilterModel(),
        this.convertSortModel()
      )
      .subscribe((res) => {
        this.listPageModel = res;
      });
    this.getDeliveryRouteDateRange(this.filterModel.start, this.filterModel.stop);
  }

  initialFilterValue() {
    this.filterModel = new DeliveryFilterModel();
    this.filterModel.searchText = '';
    this.filterModel.start = moment().startOf('day').format(this.momentFormat);
    this.filterModel.stop = moment().endOf('day').format(this.momentFormat);
    this.startDate = moment(this.filterModel.start).toDate();
    this.endDate = moment(this.filterModel.stop).toDate();
  }

  initialPagingValue() {
    this.pagingModel = new PagingModel();
    this.pagingModel.currentPage = 1;
    this.pagingModel.perPage = 10;
    this.pagingModel.maxPage = 1;
    this.pagingModel.totalItems = 10;
  }

  initialSortByValue() {
    this.sorting = new SortByModel();
    this.sorting.sortBy = this.tableHeader[1].key; // Default is referenceId
    this.sortingValue = this.tableHeader[1].value; // Default for dropdown
  }

  async ngOnInit() {
    this.orderCommon = await this.commonService.getOrderCommon();
    this.initialFilterValue();
    this.initialPagingValue();
    this.initialSortByValue();
    this.getDeliveryByDateRange();
  }

  getPaymentStatus(id: number) {
    const paymentStatus = this.orderCommon.paymentStatuses.find((x) => x.id == id);
    if (paymentStatus) {
      return paymentStatus.name;
    }
  }

  getDeliveryStatus(id: number) {
    const deliveryStatus = this.orderCommon.deliveryStatuses.find((x) => x.id == id);
    if (deliveryStatus) {
      return deliveryStatus.name;
    }
  }

  onChange($event: any, typeOfFilter: DeliveryFilterEnum, section?: string, index?: number) {
    this.pagingModel.currentPage = 1;
    switch (typeOfFilter) {
      case DeliveryFilterEnum.RouteName:
        this.routeSection = section;
        this.routeIndex = index;
        this.isSelectedRouteName = true;
        if (isNil($event.routeName)) {
          this.filterModel.routeName = 'none';
          this.getDeliveryByDateRange();
          break;
        } else {
          this.filterModel.routeName = $event.routeName;
          this.getDeliveryByDateRange();
          break;
        }
      case DeliveryFilterEnum.Total:
        this.routeSection = section;
        this.routeIndex = index;
        this.isSelectedRouteName = false;
        this.filterModel.routeName = null;
        this.getDeliveryByDateRange();
        break;
      default:
        return '';
    }
  }

  filterByText($event: any) {
    this.filterModel.searchText = $event.target.value;
    this.getDeliveryByDateRange();
  }

  convertDateToString(date: any) {
    return date == null ? '-' : moment(date).format('DD/MM/YYYY');
  }

  dateStartChange($event: any) {
    this.counterDateStart = this.counterDateStart + 1;
    if (this.counterDateStart > 2 && $event.length !== 0) {
      this.startDate = $event;
      if (moment().diff(moment(this.startDate), 'days') > 30) {
        this.isAutoRefresh = false;
      }
      const endDate = this.selectedDateFilter == DateFilterEnum.SelectDate ? this.startDate : this.endDate;
      this.filterModel.start = moment(this.startDate).startOf('day').format(this.momentFormat);
      this.filterModel.stop = moment(endDate).endOf('day').format(this.momentFormat);
      this.getDeliveryByDateRange();
    }
  }

  dateEndChange($event: any) {
    this.counterDateEnd = this.counterDateEnd + 1;
    if (this.counterDateEnd > 2 && $event.length !== 0) {
      this.endDate = $event;
      this.filterModel.stop = moment(this.endDate).endOf('day').format(this.momentFormat);
      this.getDeliveryByDateRange();
    }
  }

  onDatePresentChanged($event: DateFilterEnum) {
    if ($event == DateFilterEnum.Custom) {
      this.isDateStartDisabled = false;
      this.isDateEndDisabled = false;
    } else {
      this.isDateStartDisabled = true;
      this.isDateEndDisabled = true;
    }

    if ($event == DateFilterEnum.SelectDate) {
      this.isDateEndHidden = true;
      this.isDateStartDisabled = false;
    } else {
      this.isDateEndHidden = false;
    }

    this.selectedDateFilter = $event;
    this.pagingModel.currentPage = 1;
    switch ($event) {
      case DateFilterEnum.Yesterday:
        this.startDate = moment().subtract(1, 'day').startOf('day').toDate();
        this.endDate = moment().subtract(1, 'day').endOf('day').toDate();
        this.filterModel.start = moment(this.startDate).format(this.momentFormat);
        this.filterModel.stop = moment(this.endDate).format(this.momentFormat);
        break;
      case DateFilterEnum.Today:
        this.startDate = moment().startOf('day').toDate();
        this.endDate = moment().endOf('day').toDate();
        this.filterModel.start = moment(this.startDate).format(this.momentFormat);
        this.filterModel.stop = moment(this.endDate).format(this.momentFormat);
        break;
      case DateFilterEnum.Tomorrow:
        this.startDate = moment().startOf('day').add(1, 'day').toDate();
        this.endDate = moment().endOf('day').add(1, 'day').toDate();
        this.filterModel.start = moment(this.startDate).format(this.momentFormat);
        this.filterModel.stop = moment(this.endDate).format(this.momentFormat);
        break;
      case DateFilterEnum.ThisWeek:
        this.startDate = moment().startOf('week').toDate();
        this.endDate = moment().endOf('week').toDate();
        this.filterModel.start = moment(this.startDate).format(this.momentFormat);
        this.filterModel.stop = moment(this.endDate).format(this.momentFormat);
        break;
      case DateFilterEnum.ThisMonth:
        this.startDate = moment().startOf('month').toDate();
        this.endDate = moment().endOf('month').toDate();
        this.filterModel.start = moment(this.startDate).format(this.momentFormat);
        this.filterModel.stop = moment(this.endDate).format(this.momentFormat);
        break;
      case DateFilterEnum.LastMonth:
        this.startDate = moment().subtract(1, 'month').startOf('month').toDate();
        this.endDate = moment().subtract(1, 'month').endOf('month').toDate();
        this.filterModel.start = moment(this.startDate).format(this.momentFormat);
        this.filterModel.stop = moment(this.endDate).format(this.momentFormat);
        break;
      case DateFilterEnum.Custom:
        break;
      case DateFilterEnum.SelectDate:
        this.startDate = moment().startOf('day').toDate();
        this.endDate = moment().endOf('day').toDate();
        this.filterModel.start = moment(this.startDate).format(this.momentFormat);
        this.filterModel.stop = moment(this.endDate).format(this.momentFormat);
        break;
      default:
        return this.initialFilterValue();
    }
  }

  onSelectItemPerPage() {
    this.pagingModel.currentPage = 1;
    this.getDeliveryByDateRange();
  }

  onChangePage(event: number) {
    this.pagingModel.currentPage = event;
    this.getDeliveryByDateRange();
  }

  openOrderRecheckModal(template: TemplateRef<any>, id: string) {
    this.getOrderById(id);
    this.modalRef = this.modalService.show(template, MODAL_CONFIG_LG);
  }

  onChangeIsCheck($event: any, id: string) {
    const checkValue = $event.target.checked;
    this.orderService.orderReCheck(id, checkValue).subscribe(
      () => {
        this.messageService.showSuccess('Update successful');
      },
      (err) => {
        this.messageService.showError('Update failed');
      }
    );
  }

  getTotalGift() {
    // TODO
  }

  getImageUrl(value: string) {
    if (value) {
      return environment.IMAGE_URL + value;
    } else {
      return '';
    }
  }

  zoomImage(url: string) {
    if (url) {
      var modal = document.getElementById('myModal');
      var modalImg = document.getElementById('img01');
      modal.style.display = 'block';
      modalImg.setAttribute('src', url);
    }
  }
  zoomOutImage() {
    var modal = document.getElementById('myModal');
    var modalImg = document.getElementById('img01');
    modalImg.className += ' out';
    setTimeout(function () {
      modal.style.display = 'none';
      modalImg.className = 'imgZoom-modal-content';
    }, 400);
  }

  onSelectSortingField(value: string) {
    const key = this.tableHeader?.find((x) => x.value === value).key;
    this.sorting.sortBy = key;
    this.initialPagingValue();
    this.getDeliveryByDateRange();
  }

  onSelectDescending($event) {
    this.sorting.descending = $event.target.checked;
    this.initialPagingValue();
    this.getDeliveryByDateRange();
  }

  onChangeAutoRefresh($event: any) {
    this.isAutoRefresh = $event.target.checked;
  }

  private getOrderById(id: string) {
    this.orderService.getOrderById(id).subscribe(async (res) => {
      this.orderRecheck = await this.orderRecheckService.bindOrderRecheck(res);
    });
  }
}
