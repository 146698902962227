import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { CommonService } from 'src/app/core/services/common.service';
import { OrderService } from 'src/app/core/services/order.service';
import { OrderDetailModel } from 'src/app/models/order-detail.model';
import { OrderCommonModel } from 'src/app/models/order.model';

@Component({
  selector: 'app-orders-view',
  templateUrl: './orders-view.component.html',
  styleUrls: ['./orders-view.component.scss'],
})
export class OrdersViewComponent implements OnInit {
  orderId: string;
  orderDetail: OrderDetailModel = new OrderDetailModel();
  orderCommon: OrderCommonModel = new OrderCommonModel();
  promotionName: string = '-';

  constructor(
    private orderService: OrderService,
    private commonService: CommonService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    this.orderDetail.products = Array();
    this.orderId = this.route.snapshot.paramMap.get('id');
    this.orderCommon = await this.commonService.getOrderCommon();

    this.orderService.getOrderById(this.orderId).subscribe(async (res) => {
      this.orderDetail = res;
      if (this.orderDetail.promotionId > 0) {
        this.promotionName = this.orderCommon.promotion.find((x) => x.id == this.orderDetail.promotionId)?.name;
      }
    });
  }

  convertDateToString(date: any) {
    return date == null ? '-' : moment(date).format('DD/MM/YYYY');
  }

  getFullAddress() {
    return this.orderService.getFullAddressDetail(this.orderDetail, this.orderCommon.provinces);
  }

  getThaiDateTime(date: any) {
    if (date == undefined) return '';

    return moment(date).lang('th').format('DD MMM YYYY, HH:mm');
  }

  getThaiDate(date: any) {
    if (date == undefined) return '';

    return moment(date).lang('th').format('DD MMM YYYY');
  }
}
