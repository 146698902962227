<div class="container-fluid">
  <div class="col-3 pr-0 pl-0">
    <div class="invoice-info">
      <div class="mobile-active"><i class="fal fa-mobile-android-alt"></i></div>
      <div class="col-6 pl-0 pr-10">
        <div class="form-group">
          <label for="input1">Invoice number</label>
          <input class="form-control" id="input1" value="{{ orderDetail?.invoiceNumber }}" readonly />
        </div>
        <div class="form-group date-picker">
          <label for="datepicker1">วันที่รับออเดอร์</label>
          <input
            type="text"
            placeholder="Start date"
            class="form-control"
            bsDatepicker
            [bsConfig]="{ adaptivePosition: true }"
            id="datepicker1"
            [bsValue]="convertStringToDate(orderDetail?.orderDate)"
            readonly
          />
        </div>
      </div>
      <div class="col-6 pr-0 pl-0">
        <div class="form-group-btn">
          <label for="input2">Order from app</label>
          <div class="form-group switch-form grey">
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitch1" />
              <label class="custom-control-label" for="customSwitch1">แก้ไขออเดอร์</label>
            </div>
          </div>
        </div>
        <div class="form-group mb-0">
          <label for="select1">อ้างอิง</label>
          <div for="ddlReference" class="form-group mb-0" readonly>
            <ng-select
              id="ddlReference"
              [disabled]="true"
              [items]="orderCommon.partners"
              bindLabel="name"
              bindValue="id"
              placeholder="อ้างอิง"
              (change)="onPartnersChange($event)"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="orderDetail.referenceId"
            >
            </ng-select>
          </div>
        </div>
      </div>
    </div>

    <div class="user-info">
      <h4>ข้อมูลสมาชิก</h4>
      <div class="form-group-btn">
        <label for="input3">Molto customer</label>
        <div class="form-group">
          <input class="form-control" id="input3" value="{{ orderDetail?.customerName }}" readonly />
          <button class="btn btn-square filter" disabled><i class="fas fa-filter"></i></button>
          <button class="btn btn-square" disabled><i class="fas fa-plus"></i></button>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="input4">เบอร์โทรศัพท์</label>
            <input class="form-control" id="input4" value="{{ orderDetail?.customerPhoneNumber }}" readonly />
          </div>
        </div>
        <div class="col-6">
          <div for="select2" class="form-group mb-0">
            <label>สถานะ</label>
            <div for="ddlOrderStatus" class="form-group mb-0" readonly>
              <ng-select
                id="ddlOrderStatus"
                [disabled]="true"
                [items]="orderCommon.orderStatus"
                bindLabel="name"
                bindValue="id"
                placeholder="order status"
                (change)="onOrderStatusChange($event)"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="orderDetail.orderStatus"
              >
              </ng-select>
            </div>
          </div>
        </div>
      </div>
      <div class="address-info">
        <h4>รายละเอียดที่อยู่</h4>
        <div class="form-group-btn">
          <label for="input5">Location link</label>
          <div class="form-group">
            <input class="form-control" id="input5" value="{{ orderDetail?.locationLink }}" readonly />
            <button class="btn btn-square" disabled><i class="fal fa-map-marker-alt"></i></button>
          </div>
        </div>
        <div class="form-group text-area">
          <label>ที่อยู่</label><button class="btn btn-badge" disabled>เปลี่ยนที่อยู่</button>
          <textarea class="form-control" rows="3" placeholder="Enter ..." spellcheck="false" value="" readonly>{{
            orderDetail?.address
          }}</textarea>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="input6">แขวง / ตำบล</label>
              <input class="form-control" id="input6" value="{{ orderDetail?.subDistrict }}" readonly />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="input7">เขต / อำเภอ</label>
              <input class="form-control" id="input7" value="{{ orderDetail?.district }}" readonly />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="input8">จังหวัด</label>
              <div class="select-form form-group mb-0">
                <div for="ddlProvince" class="form-group mb-0" readonly>
                  <ng-select
                    id="ddlProvince"
                    [disabled]="true"
                    [items]="orderCommon.provinces"
                    bindLabel="name"
                    bindValue="provinceID"
                    placeholder="จังหวัด"
                    (change)="onProvinceChange($event)"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="orderDetail.provinceId"
                  >
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="input9">รหัสไปรษณีย์</label>
              <input class="form-control" id="input9" value="{{ orderDetail?.zipCode }}" readonly />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="input10">ชื่อผู้รับ</label>
              <input class="form-control" id="input10" value="{{ orderDetail?.receiverName }}" readonly />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="input11">เบอร์โทรศัพท์</label>
              <input class="form-control" id="input11" value="{{ orderDetail?.deliveryPhoneNumber }}" readonly />
            </div>
          </div>
        </div>
        <div class="form-group text-area">
          <label>หมายเหตุถึงคนขับ</label>
          <textarea class="form-control" rows="3" placeholder="..." spellcheck="false" value="" readonly>{{
            orderDetail?.deliverRemark
          }}</textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="col-6 pr-0 pl-0">
    <div class="order-info">
      <h4>ข้อมูลการสั่งซื้อ</h4>
      <div class="row-1">
        <div class="col-2 pl-0">
          <div class="form-group date-picker">
            <label for="datepicker1">วันที่จัดส่งสินค้า</label>
            <input
              type="text"
              placeholder="Start date"
              class="form-control"
              bsDatepicker
              [bsValue]="convertStringToDate(orderDetail?.deliveryDate)"
              [bsConfig]="{ adaptivePosition: true }"
              id="datepicker1"
              readonly
            />
          </div>
        </div>
        <div class="col-4 pl-0">
          <div class="form-group">
            <label for="input12">โปรโมชั่น (Optional)</label>
            <input class="form-control" id="input12" value="Happpy UOB set" readonly />
          </div>
        </div>
        <div class="col-3 pl-0">
          <div class="form-group mb-0">
            <label for="select4">จำนวนสินค้า</label>
            <select class="form-control" id="select4" readonly>
              <option>option 1</option>
              <option>option 2</option>
              <option>option 3</option>
              <option>option 4</option>
              <option>option 5</option>
            </select>
          </div>
        </div>
        <div class="col-3 pl-0 pr-0">
          <div class="form-group mb-0">
            <label for="select5">จำนวนกล่อง (Optional)</label>
            <select class="form-control" id="select5" readonly>
              <option>option 1</option>
              <option>option 2</option>
              <option>option 3</option>
              <option>option 4</option>
              <option>option 5</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row-2">
        <div class="col-3 pl-0">
          <div class="form-group">
            <label for="input13">ค่าไอศครีม</label>
            <input class="form-control" id="input13" value="{{ orderDetail?.orderPrice }}" readonly />
          </div>
        </div>
        <div class="col-2 pl-0">
          <div class="form-group">
            <label for="input14">ค่าจัดส่ง</label>
            <input class="form-control" id="input14" value="{{ orderDetail?.deliveryPrice }}" readonly />
          </div>
        </div>
        <div class="col-2 pl-0">
          <div class="form-group">
            <label for="input15">ส่วนลด</label>
            <input class="form-control" id="input15" value="{{ orderDetail?.discountPrice }}" readonly />
          </div>
        </div>
        <div class="col-2 pl-0">
          <button class="btn btn-primary" disabled>คำนวน</button>
        </div>
        <div class="col-3 pl-0 pr-0">
          <div class="form-group">
            <label for="input15">ราคาสุทธิ</label>
            <input class="form-control" id="input15" value="{{ orderDetail?.totalPrice }}" readonly />
          </div>
        </div>
      </div>
      <div class="order-info-add">
        <div class="header">
          <button
            type="button"
            class="btn btn-link"
            (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseBasic"
          >
            <i class="fas fa-chevron-right"></i>
            <h5>รายการสั่งซื้อสินค้า</h5>
          </button>
          <!-- If empty order number, It will add class="empty-order" -->
          <div class="order-add">{{ orderDetail.products.length }}</div>
        </div>
        <div id="collapseBasic" [collapse]="isCollapsed" [isAnimated]="true">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">รหัสสินค้า</th>
                  <th scope="col">สินค้า (EN)</th>
                  <th scope="col">สินค้า (EN)</th>
                  <th class="text-center" scope="col">จำนวน</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of orderDetail?.products">
                  <td scope="row">{{ item.code }}</td>
                  <td>{{ item.nameTH }}</td>
                  <td>{{ item.nameEN }}</td>
                  <td class="add-order-col text-center">
                    <div class="add-order">
                      <div class="number-display">{{ item.quantity }}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3 pr-0 pl-0">
    <div class="paid-info">
      <h4>ข้อมูลการชำระเงิน</h4>
      <div class="form-group-switch">
        <label>การชำระเงิน</label>
        <div for="ddlPaymentStatus" class="form-group mb-0" readonly>
          <ng-select
            id="ddlPaymentStatus"
            [disabled]="true"
            [items]="orderCommon.paymentStatuses"
            bindLabel="name"
            bindValue="id"
            placeholder="การชำระเงิน"
            (change)="onProvinceChange($event)"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="orderDetail.paymentStatus"
          >
          </ng-select>
        </div>

        <div class="paid-status">
          <div class="success">{{ orderDetail.paymentStatusText }}</div>
          <div>Ref code: {{ orderDetail.paymentReference }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
