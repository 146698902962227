import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiService } from './base/api.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private apiService: ApiService) {}

  public userName = new BehaviorSubject('');

  getUserDetail(id: string): Observable<any> {
    return this.apiService.get(`user/${id}`);
  }
}
