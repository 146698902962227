import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionModule as MkAccordionModule, BoxModule } from 'angular-admin-lte';
import { LoadingPageModule, MaterialBarModule } from 'angular-loading-page';
import { NumberPickerModule } from 'ng-number-picker';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';
import { NgxPaginationModule } from 'ngx-pagination';
import { CoreModule } from 'src/app/core/core.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { InvoiceListComponent } from './components/dashboard/invoice-list/invoice-list.component';
import { PaymentDeliveryComponent } from './components/dashboard/payment-delivery/payment-delivery.component';
import { TotalOrdersComponent } from './components/dashboard/total-orders/total-orders.component';
import { OrderPlaceComponent } from './components/order/order-place/order-place.component';
import { OrderProductComponent } from './components/order/order-products/order-product.component';
import { OrdersComponent } from './components/order/orders.component';
import { OrderEditComponent } from './order-edit/order-edit.component';
import { OrdersListComponent } from './orders-list/orders-list.component';
import { OrdersNewComponent } from './orders-new/orders-new.component';
import { OrdersPosComponent } from './orders-pos/orders-pos.component';
import { OrderRoutingModule } from './orders-routing.module';
import { OrdersViewComponent } from './orders-view/orders-view.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

@NgModule({
  declarations: [
    OrdersComponent,
    OrderProductComponent,
    OrderPlaceComponent,
    DashboardComponent,
    TotalOrdersComponent,
    PaymentDeliveryComponent,
    InvoiceListComponent,
    OrdersListComponent,
    OrdersNewComponent,
    OrdersPosComponent,
    OrderEditComponent,
    OrdersViewComponent,
  ],
  imports: [
    CommonModule,
    OrderRoutingModule,
    CollapseModule,
    CoreModule,
    LoadingPageModule,
    MaterialBarModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    NumberPickerModule,
    MkAccordionModule,
    BoxModule,
    NgSelectModule,
    NgxDropzoneModule,
    NgxFileDragDropModule,
    SharedModule,
    NgxPaginationModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OrdersModule {}
