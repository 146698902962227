import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-drag-upload',
  templateUrl: './drag-upload.component.html',
  styleUrls: ['./drag-upload.component.scss'],
})
export class DragUploadComponent implements OnInit, OnDestroy {
  @Input() imageUrl: string;
  @Output() onChanged = new EventEmitter<File>();
  @Output() getBaseText = new EventEmitter<string>();
  @Output() onClear = new EventEmitter();

  public readonly uploadedFile: BehaviorSubject<string> = new BehaviorSubject(null);

  private subscription: Subscription;

  public readonly control = new FileUploadControl(
    { listVisible: true, accept: ['image/*'], discardInvalid: true, multiple: false },
    [FileUploadValidators.accept(['image/*']), FileUploadValidators.filesLimit(1)]
  );

  public ngOnInit(): void {
    this.subscription = this.control.valueChanges.subscribe((values: Array<File>) => this.getImage(values[0]));
  }

  ngOnChanges() {
    // Check if the data exists before using it
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onRemoveFile(control: any, file: any): void {
    control.removeFile(file);
    this.onClear.emit();
  }

  private getImage(file: File): void {
    if (FileReader && file) {
      const fr = new FileReader();
      fr.onload = (e: any) => this.uploadedFile.next(e.target.result);
      fr.readAsDataURL(file);
      const fr2 = new FileReader();
      fr2.onload = (e: any) => {
        var binaryString = e.target.result;
        const base64textString = btoa(binaryString);
        this.getBaseText.emit(base64textString);
      };
      fr2.readAsBinaryString(file);
      this.onChanged.emit(file);
    } else {
      this.onChanged.emit(null);
      this.uploadedFile.next(null);
    }
  }

  clearImageUrl() {
    this.onClear.emit();
  }
}
