import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { MODAL_CONFIG } from 'src/app/configs/global';
import { InformationMessageService } from 'src/app/core/services/information-message.service';
import { PromotionSetService } from 'src/app/core/services/promotion.service';
import { PromotionSetModel } from 'src/app/models/promotion.model';

@Component({
  selector: 'app-product-promotion',
  templateUrl: './product-promotion.component.html',
  styleUrls: ['./product-promotion.component.scss'],
})
export class ProductPromotionComponent implements OnInit {
  promotionList = [];
  pageOfItems: Array<any>;
  promotionId: number;
  promotionDetail: PromotionSetModel;
  modalRef: BsModalRef;
  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private promotionService: PromotionSetService,
    private messageService: InformationMessageService
  ) {}

  promotionForm: FormGroup = null;

  get f() {
    return this.promotionForm.controls;
  }

  ngOnInit() {
    this.getPromotionList();
  }

  getPromotionList() {
    this.promotionService
      .getPromotionList()
      .pipe(take(1))
      .subscribe((res) => {
        this.promotionList = res;
      });
  }

  getPromotionById(promotionId: number) {
    this.promotionService
      .getPromotionById(promotionId)
      .pipe(take(1))
      .subscribe((res) => {
        this.promotionDetail = res;
        this.buildForm();
      });
  }

  createPromotion() {
    console.log('this.promotionForm.value', this.promotionForm.value);
    this.promotionService
      .createPromotion(this.promotionForm.value)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.messageService.showSuccess('Update successful');
          this.getPromotionList();
        },
        (err) => {
          this.messageService.showError('Update failed');
          this.getPromotionList();
        }
      );
    this.modalRef.hide();
  }

  updatePromotion(promotionId: number) {
    console.log('this.promotionForm.value', this.promotionForm.value);
    this.promotionService
      .updatePromotionById(promotionId, this.promotionForm.value)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.messageService.showSuccess('Update successful');
          this.getPromotionList();
        },
        (err) => {
          this.messageService.showError('Update failed');
          this.getPromotionList();
        }
      );
    this.modalRef.hide();
  }

  onSubmit() {
    this.promotionForm.markAllAsTouched();
    if (this.promotionForm.valid) {
      if (!this.promotionId) {
        this.createPromotion();
      } else {
        this.updatePromotion(this.promotionId);
      }
    }
  }

  buildForm() {
    this.promotionForm = this.formBuilder.group({
      name: [this.promotionDetail?.name, [Validators.required]],
      quantity: [this.promotionDetail?.quantity, [Validators.required]],
      price: [this.promotionDetail?.price, [Validators.required]],
      calculationMethod: [this.promotionDetail?.calculationMethod],
      shippingCostOfBKK: [this.promotionDetail?.shippingCostOfBKK],
      shippingCostOfNonBKK: [this.promotionDetail?.shippingCostOfNonBKK],
      promotionPeriodFrom: [this.promotionDetail?.promotionPeriodFrom],
      promotionPeriodTo: [this.promotionDetail?.promotionPeriodTo],
      isAvailable: [this.promotionDetail?.isAvailable ?? true],

      calculateItemPrice: [this.promotionDetail?.calculateItemPrice],
      canBuySameItem: [this.promotionDetail?.canBuySameItem],
      canSelectProduct: [this.promotionDetail?.canSelectProduct],
      isAutoCalculatedNormalOrder: [this.promotionDetail?.isAutoCalculatedNormalOrder],
      maxQuantity: [this.promotionDetail?.maxQuantity],
      maxSameItem: [this.promotionDetail?.maxSameItem],
      maxSellPerDay: [this.promotionDetail?.maxSellPerDay],
      minQuantity: [this.promotionDetail?.minQuantity],
      ninoxPromotion: [this.promotionDetail?.ninoxPromotion],
      pricePerUnit: [this.promotionDetail?.pricePerUnit],
      priority: [this.promotionDetail?.priority],
      productCategoryId: [this.promotionDetail?.productCategoryId],
      skipCalPrice: [this.promotionDetail?.upcountryPrice],
      upcountryPrice: [this.promotionDetail?.upcountryPrice],
      usePricePerUnit: [this.promotionDetail?.usePricePerUnit],
    });
    console.log(this.promotionForm);
  }

  openModal(template: TemplateRef<any>, id?: number) {
    if (id) {
      this.promotionId = id;
      this.getPromotionById(id);
    } else {
      this.promotionId = null;
      this.promotionDetail = null;
      this.buildForm();
    }
    this.modalRef = this.modalService.show(template, MODAL_CONFIG);
  }

  openDeleteModal(template: TemplateRef<any>, id?: number) {
    this.getPromotionById(id);
    this.promotionId = id;
    this.modalRef = this.modalService.show(template, MODAL_CONFIG);
    this.modalRef.content = this.promotionDetail?.name;
  }

  onDeletePromotion() {
    this.promotionService
      .deletePromotionById(this.promotionId)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.modalRef.hide();
          this.messageService.showSuccess('Update successful');
          this.getPromotionList();
        },
        (error) => {
          this.messageService.showError('Update failed');
        }
      );
  }

  onChangePage(pageOfItems: any) {
    this.pageOfItems = this.promotionList.slice(pageOfItems.startIndex, pageOfItems.endIndex + 1);
  }
}
