// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://api.moltothailand.com/',
  IMAGE_URL: 'https://api.moltothailand.com/',
  GOOGLE_API_URL: 'https://maps.googleapis.com/',
  GOOGLE_API_KEY: 'AIzaSyD4tKxZktTmEUp31v6C28GNGtWrmIb3nVc',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impacgit puyllt when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
