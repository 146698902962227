import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { LoginRoutingModule } from 'src/app/pages/+login/login-routing.module';
import { AuthService } from '../../../core/services/base/auth.service';
@Component({
  selector: 'app-header-inner',
  templateUrl: './header-inner.component.html',
})
export class HeaderInnerComponent implements OnInit {
  constructor(private authService: AuthService, public router: Router) {}

  async ngOnInit() {}

  doLogOut() {
    this.authService.SignOut();
  }
}
