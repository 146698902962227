<mk-box header="About Angular Admin LTE" [isCollapsable]="false" [isRemovable]="false">
  <p>
    Angular Admin LTE is an implementation of the Admin LTE theme. It comes with native angular components and directive
    and do not need JS dependencies. It provides a layout module witch comes with components for header, sidebar right &
    left, footer...
  </p>
</mk-box>
asadasd
<mk-box header="Download" [isCollapsable]="false" [isRemovable]="false">
  <p>
    Angular Admin LTE is available at npm, if you have an existing application run the following command to download it
    to your project.
  </p>
  <p>Angular Admin LTE is distributed in commonjs format, a module manager of your choice is required.</p>
  <pre><code class="language-bash">npm install angular-admin-lte --save</code></pre>
</mk-box>

<mk-box header="Import" [isCollapsable]="false" [isRemovable]="false">
  <p>
    UI components are configured as modules, once Angular Admin LTE is downloaded and configured, modules and apis can
    be imported from 'angular-loading-page' shorthand in your application code.
  </p>
  <pre><code class="language-typescript">import &#123; LayoutModule &#125; from 'angular-admin-lte';    //Loading layout module
import &#123; BoxModule &#125; from 'angular-admin-lte';       //Box component</code></pre>
</mk-box>

<mk-box header="Dependencies" [isCollapsable]="false" [isRemovable]="false">
  <p>
    Angular Admin LTE have no JS dependencies but still need Admin LTE and Bootstrap CSS. You can also use FontAwesome
    and Ionicons as it is used by default in Admin LTE.
  </p>
  <pre><code class="language-typescript">"styles": [
  "node_modules/bootstrap-css-only/css/bootstrap.min.css",
  "node_modules/font-awesome/css/font-awesome.css", // optional
  "node_modules/ionicons/css/ionicons.css", // optional
  "node_modules/admin-lte-css/dist/css/AdminLTE.css",
  "node_modules/admin-lte-css/dist/css/skins/_all-skins.css"
]</code></pre>
</mk-box>
