import { Directive, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';
import { CommonHelper } from 'src/app/utils/commonHelper';

@Directive({
  selector: '[phoneMask]',
  providers: [NgModel],
})
export class PhoneMaskDirective {
  constructor(private model: NgModel) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: any) {
    const result = CommonHelper.separatePhoneNumber(event);
    this.model.valueAccessor.writeValue(result);
  }

  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    let trimmed = input.value.replace(/\s+/g, '');

    if (trimmed.length > 12) {
      trimmed = trimmed.substring(0, 12);
    }
    trimmed = trimmed.replace(/-/g, '');

    let numbers = [];

    numbers.push(trimmed.substring(0, 3));

    if (trimmed.substring(3, 5) !== '') numbers.push(trimmed.substring(3, 6));

    if (trimmed.substring(6, 9) != '') numbers.push(trimmed.substring(6, 10));

    input.value = numbers.join('-');
  }
}
