import { DropdownItemModel } from './base/util.model';

export class OrderTotalItemModel {
  id: string | '';
  name: string | undefined;
  value: number | undefined;
  totalvalue: boolean | undefined;
}

export class OrderTotalStickmodel {
  total_order: number | undefined;
  bankok_metro: number | undefined;
  upcountry: number | undefined;
  airplan: number | undefined;
  pickup: number | undefined;
}

export class OrderTotalListModel {
  items: OrderTotalItemModel[] | undefined;

  public bigSizeItems() {
    if (this.items != undefined) {
      return this.items.filter((x: OrderTotalItemModel) => x != undefined && x.totalvalue == true);
    }
    return [];
  }

  public smallSizeItems() {
    if (this.items != undefined) {
      return this.items.filter((x: OrderTotalItemModel) => x != undefined && x.totalvalue == false);
    }
    return [];
  }

  public getStickModel() {
    const model = new OrderTotalStickmodel();
    if (this.items != null) {
      model.total_order = this.items!.filter((x: any) => x != undefined && x.id == '0')[0]?.value;
      model.bankok_metro = this.items!.filter((x: any) => x != undefined && x.id == '1')[0]?.value;
      model.upcountry = this.items!.filter((x: any) => x != undefined && x.id == '2')[0]?.value;
      model.airplan = this.items!.filter((x: any) => x != undefined && x.id == '3')[0]?.value;
      model.pickup = this.items!.filter((x: any) => x != undefined && x.id == '4')[0]?.value;
    }

    return model;
  }
}

export class OrderPartnerItemsModel {
  id: string | '';
  name: string | undefined;
  totalorder: number | undefined;
  payment: number | undefined;
  delivery: number | undefined;
}

export class OrderDetail {
  id: string | number | 0;
  orderDate: Date | undefined;
  customerId: string | number | '';
  invoiceNumber: string | '';
  ref_id: number | string | 0;
  tel: string | '';
  places: OrderPlaceModel | null;
  paymentType: string | number | '';
  isCheck: boolean | false;
  numberOfSet: any = 0;
  selectedPlaceIndex: any = 0;
  referenceText: string | '';
  refImageFile: OrderDetailImageFileModel | undefined;
  profImageFile: OrderDetailImageFileModel | undefined;
  moreSlipImageFile: OrderDetailImageFileModel | undefined;
  paymentStatus: number | string | '';
  remark: string | '';
  isSaveable: boolean | true;
  communicationMethodId: string | undefined;
  orderAddressDetailId: string | number | null;
}

export class OrderListItem extends OrderDetail {
  orderPrice: string | number | '';
  deliveryPrice: string | number | '';
  totalPrice: string | number | '';
  deliveryDate: string | Date | '';
  orderStatus: string | '';
  orderStatusId: string | number | '';
  paymentStatusId: string | number | '';
  customerName: string | Date | '';
  isPaid: boolean | false;
  promotionName: string | '';
  ref_id_name: string | '';
  referenceText: string;
}

export class OrderDetailImageFileModel {
  id: string | number | '';
  filePath: string | '';
  fileBase64String: string | '';
}
export class OrderPlaceModel {
  placeId: string | undefined;
  deliveryNumber: string | '';
  deliveryDate: Date | undefined;
  setId: string | number | undefined;
  setQuantity: number | undefined;
  boxNumber: string | number | '';
  receiverName: string | '';
  customerAddressId: string | number | undefined;
  address: string | '';
  tel: string | '';
  province: string | number | 0;
  district: string | '';
  zipcode: string | '';
  transport: string | number | 0;
  orderPrice: string | undefined;
  deliveryPrice: string | undefined;
  totalPrice: string | undefined;
  locationLink: string | number | undefined;
  deliveryRemark: string | '';
  status: string | number | undefined;
  timeRemark: string | number | 0;
  isUpcountry: boolean | false;
  products: OrderProductItemModel[] | any[];
  remark: string | '';
}

export class OrderProductItemModel {
  id: string | undefined;
  productCode: string | '';
  orderId: number | undefined | undefined;
  placeId: string | number | undefined;
  productId: string | number | undefined;
  nameTh: string | undefined;
  nameEn: string | undefined;
  quantity: number | undefined;
}

export class OrderPromotionItemModel {
  id: string | number | undefined;
  name: string | undefined;
  quantity: string | number | undefined;
  priority: string | number | undefined;
}

export class OrderCommonModel {
  partners: DropdownItemModel[] | any[];
  provinces: any[];
  communicationMethod: DropdownItemModel[] | any[];
  paymentStatuses: DropdownItemModel[] | any[];
  transportationTypes: DropdownItemModel[] | any[];
  productCategory: DropdownItemModel[] | any[];
  promotion: OrderPromotionItemModel[] | any[];
  orderStatus: DropdownItemModel[] | any[];
  paymentGateway: DropdownItemModel[] | any[];
  giftItem: DropdownItemModel[] | any[];
  deliveryStatuses: DropdownItemModel[] | any[];
  branches: DropdownItemModel[] | any[];
}
