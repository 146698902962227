import { ApiService } from './base/api.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(private apiService: ApiService) {}

  sendConfirmDeliveryDate(id: string): Observable<any> {
    return this.apiService.post(`utility/send-confirm-delivery/${id}`);
  }

  sendCard(id: string): Observable<any> {
    return this.apiService.post(`utility/send-card/${id}`);
  }

  callDriver(id: string): Observable<any> {
    return this.apiService.post(`utility/call-driver/${id}`);
  }
}
