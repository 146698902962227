import { ReferenceInformationModel } from './order-detail.model';

export class OrderRecheckModel {
  orderId: number;
  invoiceNumber: string | '';
  orderPrice: number;
  discountPrice: number;
  deliveryPrice: number;
  totalPrice: number;
  quantity: number;
  boxQuantity: Array<object>;
  place: OrderRecheckPlaceModel;
  hasImage: boolean | false;
  isCheck: boolean;
  isWaitingData: boolean;
  waitingData: string;
  referenceText: string;
  deliveryRemark: string;
  smsRemark: string;
  factoryRemark: string;
  referenceInformation: ReferenceInformationModel[];
  nextOrderId: number;
  previousOrderId: number;
  referenceNumber: string;
}

export class OrderRecheckPlaceModel {
  name: string | '';
  setName: string | '';
  setQuantity: string | number | '';
  boxNumber: string | number | '1';
  remark: string | '';
  tel: string | '';
  secondPhone?: string | '';
  province: string | '';
  transport: string | '';
  address: string | '';
  deliveryRemark: string | '';
  products: OrderRecheckProductModel[] | [];
  gifts: OrderRecheckGiftModel[] | [];
  deliveryDate: string;
  receiverName: string;
  status: string | '';
}

export class OrderRecheckProductModel {
  id: string | number | '';
  name: string | '';
  quantity: string | number | 0;
}

export class OrderRecheckGiftModel {
  id: number;
  name: string;
  amount: number;
}
