<div *ngIf="pager.pages && pager.pages.length" class="row pagination">
  <div class="col-12 col-xl-6 pl-0">
    <div class="dataTables_info" role="status" aria-live="polite">
      Showing {{ pager.startIndex + 1 }} to {{ pager.endIndex + 1 }} of {{ pager.totalItems }} entries
    </div>
  </div>
  <div class="col-12 col-xl-6 pr-0">
    <div class="dataTables_paginate paging_simple_numbers">
      <ul class="pagination">
        <li [ngClass]="{ disabled: pager.currentPage === 1 }" class="paginate_button previous disabled">
          <a (click)="pager.currentPage !== pager.totalPages && setPage(pager.currentPage - 1)">Previous</a>
        </li>
        <li *ngFor="let page of pager.pages" [ngClass]="{ active: pager.currentPage === page }">
          <a (click)="setPage(page)" class="page-link">{{ page }}</a>
        </li>
        <li [ngClass]="{ disabled: pager.currentPage === pager.totalPages }" class="paginate_button next">
          <a (click)="pager.currentPage !== pager.totalPages && setPage(pager.currentPage + 1)">Next</a>
        </li>
      </ul>
    </div>
  </div>
</div>
