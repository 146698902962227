import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './base/api.service';

@Injectable({
  providedIn: 'root',
})
export class DeliveryService {
  constructor(private apiService: ApiService) {}

  getDeliveryDateRange(
    from: string,
    to: string,
    paging: string = '',
    filter: string = '',
    sortBy: string = ''
  ): Observable<any> {
    const params = `?fromdate=${from}&todate=${to}&paging=${paging}&filter=${filter}&sortby=${sortBy}`;
    return this.apiService.get(`delivery/${params}`);
  }

  getDeliveryRouteDateRange(from: string, to: string, paging: string = '', filter: string = ''): Observable<any> {
    const params = `?fromdate=${from}&todate=${to}&paging=${paging}&filter=${filter}`;
    return this.apiService.get(`delivery/route/${params}`);
  }
}
