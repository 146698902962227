export enum OrderFilterEnum {
  Transaction = 'Transaction',
  Payment = 'Payment',
  Delivery = 'Delivery',
  Partner = 'Partner',
  WaitingData = 'WaitingData',
  ProductName = 'ProductName',
  DeliveryDate = 'DeliveryDate',
  Total = 'Total',
  PaymentGateway = 'PaymentGateway',
}
