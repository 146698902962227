import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HTML_OPTION } from 'src/app/configs/global';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  constructor(private http: HttpClient) {}

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.GOOGLE_API_URL}${path}`).pipe(catchError(this.formatErrors));
  }

  getAddressInfo(gps: string): Observable<any> {
    return this.http
      .get(
        `${environment.GOOGLE_API_URL}maps/api/geocode/json?latlng=${gps}&key=${environment.GOOGLE_API_KEY}&language=th`
      )
      .pipe(catchError(this.formatErrors));
  }
}
