import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AccordionModule as MkAccordionModule, BoxModule, DropdownModule, TabsModule } from 'angular-admin-lte';
import { HeaderInnerComponent } from './share-modules/header-inner/header-inner.component';
import { SidebarLeftInnerComponent } from './share-modules/sidebar-left-inner/sidebar-left-inner.component';
import { SidebarRightInnerComponent } from './share-modules/sidebar-right-inner/sidebar-right-inner.component';

@NgModule({
  imports: [CommonModule, FormsModule, HttpClientModule, DropdownModule, TabsModule, BoxModule, MkAccordionModule],
  declarations: [HeaderInnerComponent, SidebarLeftInnerComponent, SidebarRightInnerComponent],
  exports: [BoxModule, TabsModule, HeaderInnerComponent, SidebarLeftInnerComponent, SidebarRightInnerComponent],
})
export class CoreModule {}
