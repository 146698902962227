import { UploadImageViewModel } from './product.model';

export class OrderDetailModel {
  id: string | number | 0;
  orderDate: Date | string;
  deliveryDate: Date | string;
  receivedDate: Date | undefined;
  eta: Date | undefined;
  branchId: number | 0;

  customerId: string | number | '';
  invoiceNumber: string | '';
  customerName: string | '';
  customerPhoneNumber: string | '';
  referenceId: string | number | '';
  promotionId: string | number | '';
  promotionName: string | '';
  remark: string | '';
  addressId: number | 0;
  referenceText: string | '';
  referenceNumber: number | string;
  isWaitingData: boolean | false;
  waitingData: string;

  orderStatus: string | number | 1;
  paymentReference: string | '';
  paymentStatus: string | number | 1;
  paymentStatusText: string | '';

  quantity: number | 0;
  boxQuantity: number | 0;
  boxCode: string | number;

  vatPrice: number | 0;
  orderPrice: number | 0;
  totalPrice: number | 0;
  deliveryPrice: number | 0;
  discountPrice: number | 0;

  isDataComplete: boolean | false;
  isDelivered: boolean | false;
  isNinoxOrder: boolean | false;

  isUpcountry: boolean | false;
  isPaid: boolean | false;

  receiverName: string | '';
  deliveryPhoneNumber: string | '';
  address: string | '';
  subDistrict: string | '';
  district: string | '';
  provinceId: string | number | -1;
  locationLink: string | '';
  zipCode: string | '';
  deliverRemark: string | '';

  transportationType: number;
  routeName: string;
  routeQueue: number;
  secondPhone: string;
  smsRemark: string;
  factoryRemark: string;
  deliveryRemark: string;
  timeRemark: number;
  orderWording: string;
  nextOrderId?: number;
  previousOrderId?: number;
  products: OrderProductDetailModel[];
  referenceInformation: ReferenceInformationModel[];
  customerOrderGiftItem: CustomerOrderGiftItemModel[];
}

export class OrderProductDetailModel {
  id: string | number | 0;
  code: string | '';
  nameEN: string | '';
  nameTH: string | '';
  productId: number | 0;
  quantity: number | 0;
  price?: number | 0;
}

export class ReferenceInformationModel extends UploadImageViewModel {
  referenceText: string;
  fileReferenceTypeId: number;
  filePath: string;
  paymentGatewayId?: number;
  paymentDate?: Date;
  billValue?: number
}

export class CustomerOrderGiftItemModel {
  id: string;
  orderId: number;
  giftItemId: number;
  amount: number;
}
