import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './base/api.service';
@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private apiService: ApiService) {}

  // Get
  getAllProducts(): Observable<any> {
    return this.apiService.get(`products`);
  }

  getProductsFilter(paging: string = '', filter: string = '', sortBy: string = ''): Observable<any> {
    const params = `?paging=${paging}&filter=${filter}&sortby=${sortBy}`;
    return this.apiService.get(`products/filter${params}`);
  }

  getProductById(productId: number): Observable<any> {
    return this.apiService.get(`products/${productId}`);
  }

  getAllCategories(): Observable<any> {
    return this.apiService.get(`products/category`);
  }

  getAllBranches(): Observable<any> {
    return this.apiService.get(`products/branch`);
  }

  getProductByCategory(categoryId: number): Observable<any> {
    return this.apiService.get(`products/category/${categoryId}/products`);
  }

  getProductByBranch(branchId: number): Observable<any> {
    return this.apiService.get(`products/branch/${branchId}/products`);
  }

  // Post
  createProductDetail(productDetail: object): Observable<any> {
    return this.apiService.post(`products`, productDetail);
  }

  // Put
  updateProductDetailById(productId: number, productDetail: object): Observable<any> {
    return this.apiService.put(`products/${productId}`, productDetail);
  }

  updateProductStock(productId: number, updateStock: object): Observable<any> {
    return this.apiService.put(`products/${productId}/updatestock`, updateStock);
  }

  deleteProductById(productId: number): Observable<any> {
    return this.apiService.delete(`products/${productId}`);
  }
}
