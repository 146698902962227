<div class="order-list-container">
  <div class="row">
    <div class="col-12">
      <div class="order-from-partner">
        <div class="order-header"><i class="fal fa-users"></i> Delivery Overview</div>
        <div class="order-content">
          <div
            class="order"
            [ngStyle]="{ 'background-color': isSelectedRouteName ? '#fff' : '#d2d7df' }"
            (click)="onChange('total', deliveryFilterEnum.Total)"
          >
            <div class="total-order">
              <div class="type text-uppercase">
                <span class="title">Total Parcel</span>
                <div class="value">{{ totalParcel.current }}/{{ totalParcel.total }}</div>
              </div>
            </div>
            <div class="type-order">
              <div class="type">
                Total Time<span>{{ totalParcelTime.current }}/{{ totalParcelTime.total }}</span>
              </div>
              <div class="type">
                Morning<span>{{ totalParcelTimeMorning.current }}/{{ totalParcelTimeMorning.total }}</span>
              </div>
              <div class="type">
                Afternoon<span>{{ totalParcelTimeAfternoon.current }}/{{ totalParcelTimeAfternoon.total }}</span>
              </div>
            </div>
          </div>
          <div class="slick-container d-flex">
            <div class="slick-order">
              <div class="slick-row-top">
                <div
                  class="col-3 order-partner-item mb-2"
                  *ngFor="let delivery of deliveryItemTop; let rowIndex = index"
                  (click)="onChange(delivery, deliveryFilterEnum.RouteName, 'top', rowIndex)"
                  tabindex="{{ rowIndex }}"
                  [ngStyle]="{
                    'background-color': routeSection === 'top' && routeIndex === rowIndex ? '#d2d7df' : '#fff'
                  }"
                >
                  <h5
                    class="car_{{ delivery.routeName != null ? delivery.routeName[0] : '-' }}"
                    style="text-align: center"
                  >
                    {{ delivery.routeName }}
                  </h5>

                  <div class="detail">
                    <div class="d-block">
                      <div class="" style="width: 100%">{{ delivery.driver }}</div>
                    </div>
                    <div class="d-block">
                      <div class="detail-items">
                        <h6 class="title">Current</h6>
                        <div class="value">{{ delivery.remaining.current }}</div>
                      </div>
                      <div class="detail-items">
                        <h6 class="title">Total</h6>
                        <div class="value">{{ delivery.remaining.total }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="slick-row-bottom">
                <div
                  class="col-3 order-partner-item mb-2"
                  *ngFor="let delivery of deliveryItemBottom; let rowIndex = index"
                  (click)="onChange(delivery, deliveryFilterEnum.RouteName, 'bottom', rowIndex)"
                  tabindex="{{ rowIndex }}"
                  [ngStyle]="{
                    'background-color': routeSection === 'bottom' && routeIndex === rowIndex ? '#d2d7df' : '#fff'
                  }"
                >
                  <h5
                    class="car_{{ delivery.routeName != null ? delivery.routeName[0] : '-' }}"
                    style="text-align: center"
                  >
                    {{ delivery.routeName }}
                  </h5>
                  <div class="detail">
                    <div class="d-block">
                      <div class="" style="width: 100%">{{ delivery.driver }}</div>
                    </div>
                    <div class="d-block">
                      <div class="detail-items">
                        <h6 class="title">Current</h6>
                        <div class="value">{{ delivery.remaining.current }}</div>
                      </div>
                      <div class="detail-items">
                        <h6 class="title">Total</h6>
                        <div class="value">{{ delivery.remaining.total }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="header-title">
  <div class="filter-component">
    <h5>Filters</h5>
    <div class="form-group select-form">
      <ng-select
        id="dateDropdown"
        class="custom"
        [items]="dateFilterList"
        [searchable]="false"
        [clearable]="false"
        [(ngModel)]="defaultDateFilter"
        [ngModelOptions]="{ standalone: true }"
        (change)="onDatePresentChanged($event)"
      >
      </ng-select>
    </div>
    <div class="form-group date-picker">
      <input
        type="text"
        placeholder="Start date"
        class="form-control"
        [bsValue]="startDate"
        bsDatepicker
        (bsValueChange)="dateStartChange($event)"
        [disabled]="isDateStartDisabled"
        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY ', adaptivePosition: true, customTodayClass: 'today' }"
      />
    </div>
    <ng-container *ngIf="!isDateEndHidden">
      <span>to</span>
      <div class="form-group date-picker">
        <input
          type="text"
          placeholder="End date"
          class="form-control"
          [bsValue]="endDate"
          bsDatepicker
          (bsValueChange)="dateEndChange($event)"
          [disabled]="isDateEndDisabled"
          [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY ', adaptivePosition: true, customTodayClass: 'today' }"
        />
      </div>
    </ng-container>
  </div>
  <form action="#" method="get" class="search-component">
    <div class="input-group">
      <input type="text" name="q" class="form-control" placeholder="Search..." (keyup)="filterByText($event)" />
      <span class="input-group-btn">
        <button type="submit" name="search" id="search-btn" class="btn btn-flat">
          <i class="fa fa-search"></i>
        </button>
      </span>
    </div>
  </form>
</div>
<div class="row table-config">
  <div class="col-5"></div>
  <div class="col-1 pl-0">
    <div class="custom-control custom-checkbox checkbox-ascending">
      <input
        type="checkbox"
        class="custom-control-input"
        id="checkAutoRefresh"
        [(ngModel)]="isAutoRefresh"
        (change)="onChangeAutoRefresh($event)"
      />
      <label class="custom-control-label" for="checkAutoRefresh">Auto Refresh</label>
    </div>
  </div>
  <div class="col-3 pr-0">
    <div class="item-per-page">
      <span>Sort by </span>
      <ng-select
        class="sort"
        [(ngModel)]="sortingValue"
        [clearable]="false"
        [searchable]="false"
        (change)="onSelectSortingField($event)"
        dropdownPosition="top"
      >
        <ng-option *ngFor="let item of tableHeader" [value]="item.value">{{ item.value }} </ng-option>
      </ng-select>
    </div>
  </div>
  <div class="col-1 pl-0">
    <div class="custom-control custom-checkbox checkbox-ascending">
      <input type="checkbox" class="custom-control-input" id="checkDescending" (change)="onSelectDescending($event)" />
      <label class="custom-control-label" for="checkDescending">Descending</label>
    </div>
  </div>
  <div class="col-2">
    <div class="item-per-page">
      <span>Item per page</span>
      <ng-select
        class="custom"
        [(ngModel)]="itemPerPage"
        [clearable]="false"
        [searchable]="false"
        (change)="onSelectItemPerPage()"
        dropdownPosition="top"
      >
        <ng-option *ngFor="let item of itemPerPageValue" [value]="item">{{ item }} </ng-option>
      </ng-select>
    </div>
  </div>
</div>
<pagination-controls (pageChange)="onChangePage($event)" class="text-right"></pagination-controls>

<div class="table table-custom">
  <table class="table table-hover table-border">
    <thead>
      <tr>
        <th *ngFor="let header of tableHeader">{{ header.value }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="odd"
        *ngFor="
          let order of listPageModel?.results
            | paginate
              : {
                  itemsPerPage: itemPerPage,
                  currentPage: listPageModel?.pagination?.currentPage,
                  totalItems: listPageModel?.pagination?.totalItems
                }
        "
      >
        <td>{{ order.orderId }}</td>
        <td>
          <div class="btn btn-default btn-sm ref_{{ order.referenceId }}">{{ order.reference }}</div>
        </td>
        <td>
          <div class="btn btn-default btn-sm car_{{ order.carNumber != null ? [0] : '-' }}">{{ order.carNumber }}</div>
        </td>
        <td>{{ order.customer }}</td>
        <td>{{ order.phone }}</td>
        <td>{{ order.address }}</td>
        <td>{{ order.provinceName }}</td>
        <td>{{ order.boxCount }}</td>
        <td>
          <div class="btn btn-default btn-sm d_status_{{ order.deliveryStatus }}">
            {{ getDeliveryStatus(order.deliveryStatus) }}
          </div>
        </td>
        <td>{{ order.remark }}</td>
        <td>{{ order.waittingForDataText }}</td>
        <td>
          <img
            class="imgZoom"
            height="80px"
            src="{{ getImageUrl(order.fileReferenceUrl) }}"
            (click)="zoomImage(getImageUrl(order.fileReferenceUrl))"
          />
        </td>
        <td>{{ order.remark }}</td>
        <td>{{ order.waittingForDataText }}</td>
        <td>
          <img
            class="imgZoom"
            height="80px"
            src="{{ getImageUrl(order.fileReferenceUrl) }}"
            (click)="zoomImage(getImageUrl(order.fileReferenceUrl))"
          />
        </td>
        <td class="padding">
          <div class="btn-group d-flex" role="group">
            <a
              [routerLink]="['/order/edit', order.orderId]"
              target="_blank"
              [ngClass]="{ 'btn-success-outline': order.isCheck, 'btn-danger-outline': !order.isCheck }"
              class="btn btn-sm"
              ><i class="fal fa-eye"></i>View</a
            >
            <button
              type="button"
              class="btn btn-default btn-sm"
              (click)="openOrderRecheckModal(orderRecheckModal, order.orderId)"
            >
              <i class="fal fa-file"></i> Recheck
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <pagination-controls (pageChange)="onChangePage($event)" class="text-right"></pagination-controls>
</div>

<!-- Order Recheck Modal-->
<ng-template #orderRecheckModal>
  <div class="modal-stock">
    <div class="modal-header">
      <h4 class="modal-title pull-left">ข้อมูล Recheck ออเดอร์</h4>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true"><i class="fal fa-times"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-6">
          <div *ngFor="let reference of orderRecheck.referenceInformation || []">
            <img src="{{ reference.filePath }}" alt="" width="100%" />
          </div>
        </div>
        <div class="col-6">
          <div class="recheck-wrapper">
            <div class="bill-header">
              <div class="d-flex invoice-no">
                <div class="field-name">ORDER NO. :</div>
                <div class="field-content">
                  {{ orderRecheck?.invoiceNumber }}
                </div>
                <span>&nbsp;</span>
                <div class="field-name">REF NO. :</div>
                <div class="field-content">
                  {{ orderRecheck?.referenceNumber }}
                </div>
              </div>
              <ul class="item-list primary-info">
                <li>
                  <div class="field-name">จำนวน</div>
                  <div class="field-content"><span></span>{{ orderRecheck?.place?.setName }}</div>
                </li>
                <li>
                  <div class="field-name">ค่าไอศครีม</div>
                  <div class="field-content"><span>฿</span>{{ orderRecheck?.orderPrice }}</div>
                </li>
                <li>
                  <div class="field-name">ค่าจัดส่ง</div>
                  <div class="field-content"><span>฿</span>{{ orderRecheck?.deliveryPrice }}</div>
                </li>
                <li>
                  <div class="field-name">ส่วนลด</div>
                  <div class="field-content"><span>฿</span>{{ orderRecheck?.discountPrice }}</div>
                </li>
                <li>
                  <div class="field-name">ราคาสุทธิ</div>
                  <div class="field-content"><span>฿</span>{{ orderRecheck?.totalPrice }}</div>
                </li>
              </ul>
            </div>
            <mk-accordion-group class="accordion-group bg-transparent">
              <mk-accordion header="">
                <mk-accordion-header> </mk-accordion-header>
                <div class="label-note">
                  <h3>{{ orderRecheck?.place?.setName }} | {{ orderRecheck?.place?.boxNumber }} Box</h3>
                </div>
                <div class="d-flex bill-content" *ngFor="let prod of orderRecheck?.place?.products">
                  <div class="field-name">
                    {{ prod.name }}
                  </div>
                  <div class="field-content"><span>X</span> {{ prod.quantity }}</div>
                </div>
                <div class="d-flex bill-footer">
                  <div class="field-name">Total</div>
                  <div class="field-content">
                    {{ orderRecheck?.quantity }}
                  </div>
                </div>
                <ng-container *ngIf="orderRecheck?.place?.gifts?.length > 0">
                  <hr />
                  <div class="d-flex bill-content" *ngFor="let prod of orderRecheck?.place?.gifts">
                    <div class="field-name">
                      {{ prod.name }}
                    </div>
                    <div class="field-content"><span>X</span> {{ prod.amount }}</div>
                  </div>
                  <!-- <div class="d-flex bill-footer">
                                      <div class="field-name">
                                          Total
                                      </div>
                                      <div class="field-content">
                                          {{getTotalGift()}}
                                      </div>
                                  </div> -->
                </ng-container>
                <ng-container *ngIf="orderRecheck?.boxQuantity?.length > 0">
                  <hr />
                </ng-container>
                <div class="d-flex bill-content" *ngFor="let box of orderRecheck?.boxQuantity">
                  <i class="fal fa-box" style="font-size: 11px"> {{ box.key }} = {{ box.value }}&nbsp;</i>
                </div>
                <div class="note-content" *ngIf="orderRecheck?.place?.remark">
                  <div class="note-label">Remark!!!</div>
                  <div class="field-content">
                    <div class="special-remark">*{{ orderRecheck?.place?.remark }}</div>
                  </div>
                </div>
                <hr />
                <div class="shipping-info-wrapper">
                  <div class="shipping-info">
                    <div class="field-content delivery">
                      {{ orderRecheck?.referenceText }}
                    </div>
                    <div class="field-content delivery">วันที่จัดส่ง {{ orderRecheck?.place?.deliveryDate }}</div>
                    <div class="field-content customer">K.{{ orderRecheck?.place?.receiverName }}</div>
                    <div class="field-content customer">เบอร์ผู้รับ: {{ orderRecheck?.place?.tel }}</div>
                    <div class="field-content customer">เบอร์สำรอง: {{ orderRecheck?.place?.secondPhone }}</div>
                    <div class="field-content">
                      {{ orderRecheck?.place?.address }}
                    </div>
                  </div>
                  <div class="shipping-info">
                    <div class="field-content">
                      <div class="status-remark">
                        {{ orderRecheck?.place?.status }}
                      </div>
                    </div>
                    <div class="field-content">
                      <div class="status-remark">
                        {{ orderRecheck?.place?.transport }}
                      </div>
                    </div>
                    <div class="field-content" *ngIf="orderRecheck?.place?.deliveryRemark">
                      <div class="note-label">หมายเหตุการจัดส่ง</div>
                      <div class="special-remark">*{{ orderRecheck?.place?.deliveryRemark }}</div>
                    </div>
                    <div class="field-content" *ngIf="orderRecheck?.smsRemark">
                      <div class="note-label">SMS</div>
                      <div class="special-remark">*{{ orderRecheck?.smsRemark }}</div>
                    </div>
                    <div class="field-content" *ngIf="orderRecheck?.factoryRemark">
                      <div class="note-label">หมายเหตุถึงคนรถ ฉีกลาเบล</div>
                      <div class="special-remark">*{{ orderRecheck?.factoryRemark }}</div>
                    </div>
                  </div>
                </div>
              </mk-accordion>
            </mk-accordion-group>
            <div class="form-group switch-form">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customSwitch1"
                  (change)="onChangeIsCheck($event, orderRecheck.orderId.toString())"
                  [checked]="orderRecheck.isCheck"
                  [disabled]="orderRecheck.isWaitingData"
                />
                <label class="custom-control-label" for="customSwitch1">ตรวจทาน</label>
              </div>
              <label>{{ orderRecheck?.waitingData }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<div id="myModal" class="imgZoom-modal">
  <img class="imgZoom-modal-content" id="img01" (click)="zoomOutImage()" />
</div>
