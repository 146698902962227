import { isEmpty, isNil } from 'lodash-es';
import { IMAGE_URL } from '../configs/global';

export class CommonHelper {
  static separatePhoneNumber(phoneNumber: string): string {
    const onlyNumber = phoneNumber.replace(/-/g, '').trim();
    const firstPart = onlyNumber.substring(0, 3);
    const secondPart = onlyNumber.substring(3, 6);
    const thirdPart = onlyNumber.substring(6, 10);

    const result = `${firstPart}-${secondPart}-${thirdPart}`;
    return !isNil(onlyNumber) && !isEmpty(onlyNumber) ? result : '';
  }

  static boxCountGroup(value: any) {
    if (!isNil(value)) {
      const arr = Array.from(value);
      const counts = {};
      arr.forEach((v: any) => {
        counts[v] = (counts[v] || 0) + 1;
      });
      return Object.entries(counts).map(([key, value]) => ({ key, value }));
    } else {
      return [];
    }
  }

  static mapImageUrl(url: string) {
    return url ? IMAGE_URL + url : '';
  }

  static replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
  }
}
