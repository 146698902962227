import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { ListPageModel, PagingModel } from 'src/app/models/base/paging.model';
import { OrderFilterModel } from 'src/app/models/order-filter.model';
import { OrderListItem } from 'src/app/models/order.model';
import { OrderService } from './order.service';
@Injectable({
  providedIn: 'root',
})
export class ListOrderService {
  filterModel: OrderFilterModel = new OrderFilterModel();
  orderList: OrderListItem[] = Array();
  orderListPaging: ListPageModel = new ListPageModel();
  pagingModel: PagingModel = new PagingModel();

  private filterSource = new BehaviorSubject(this.filterModel);
  private listSource = new BehaviorSubject(this.orderList);
  private pagingSource = new BehaviorSubject(this.orderListPaging);

  filter = this.filterSource.asObservable();
  list = this.listSource.asObservable();
  paging = this.pagingSource.asObservable();

  constructor(private orderService: OrderService) {
    this.filterModel = new OrderFilterModel();
    this.orderListPaging = new ListPageModel();
  }

  private convertPagingModel() {
    let modelStr = `{`;
    modelStr = modelStr + `"TotalItems":"${this.pagingModel?.totalItems ?? 10}"`;
    modelStr = modelStr + `,"PerPage":"${this.pagingModel?.perPage ?? 10}"`;
    modelStr = modelStr + `,"CurrentPage":"${this.pagingModel?.currentPage ?? 1}"`;
    modelStr = modelStr + `,"MaxPage":"${this.pagingModel?.maxPage ?? 1}"`;
    modelStr = modelStr + `}`;
    return modelStr;
  }

  private convertFilterModel() {
    let modelStr = `{`;
    modelStr = modelStr + `"FilterText":"${this.filterModel.searchText}"`;

    // TransactionType
    if (!isNil(this.filterModel.transactionTypeId)) {
      modelStr = modelStr + `,"transportationTypeId":"${this.filterModel.transactionTypeId}"`;
    }

    // PaymentStatus
    if (!isNil(this.filterModel.paymentStatusId)) {
      modelStr = modelStr + `,"paymentStatusId":"${this.filterModel.paymentStatusId}"`;
    }

    // DeliveryStatus
    if (!isNil(this.filterModel.deliveryStatusId)) {
      modelStr = modelStr + `,"deliveryStatusId":"${this.filterModel.deliveryStatusId}"`;
    }

    // Partner
    if (!isNil(this.filterModel.partnerId)) {
      modelStr = modelStr + `,"partnerId":"${this.filterModel.partnerId}"`;
    }

    // IsWaitingData
    if (!isNil(this.filterModel.isWaitingData)) {
      modelStr = modelStr + `,"isWaitingData":"${this.filterModel.isWaitingData}"`;
    }

    modelStr = modelStr + `}`;

    return modelStr;
  }

  initialValue() {
    this.filterModel = new OrderFilterModel();
    this.filterModel.searchText = '';
    this.filterModel.start = moment().format('YYYY/MM/DD');
    this.filterModel.stop = moment().add(1, 'days').format('YYYY/MM/DD');
    this.filterSource.next(this.filterModel);
  }

  getOrderList() {
    const dateStop = moment(this.filterModel.stop, 'YYYY-MM-DD').add(1, 'days').format('YYYY/MM/DD');
    this.orderService
      .getPosOrdersDateRange(this.filterModel.start, dateStop, this.convertPagingModel(), this.convertFilterModel(), '')
      .subscribe((res) => {
        this.orderListPaging = Object.assign(new ListPageModel(), res);
        this.pagingSource.next(this.orderListPaging);
        if (this.orderListPaging.results != null) {
          this.orderList = res.results.map((x: any) => Object.assign(new OrderListItem(), x));
          this.listSource.next(this.orderList);
        }
      });
  }

  searchListByText(text: string) {
    this.filterModel.searchText = text;
    this.getOrderList();
  }

  searchByTransaction(id?: number) {
    this.filterModel.transactionTypeId = id;
    this.getOrderList();
  }

  searchByDelivery(id?: number) {
    this.filterModel.deliveryStatusId = id;
    this.getOrderList();
  }

  searchByPayment(id?: number) {
    this.filterModel.paymentStatusId = id;
    this.getOrderList();
  }

  searchByWaitingData(isWaiting?: boolean) {
    this.filterModel.isWaitingData = isWaiting;
    this.getOrderList();
  }

  searchByPartner(id?: number) {
    this.filterModel.partnerId = id;
    this.getOrderList();
  }

  changePaging(paging: PagingModel) {
    this.pagingModel = Object.assign(new PagingModel(), paging);
    this.getOrderList();
  }

  clearFilter() {
    this.initialValue();
    this.getOrderList();
  }

  searchByRange(from: string, to: string) {
    if (this.filterModel.start != '' && this.filterModel.stop != '') {
      const formCheck = moment(from).format('YYYY-MM-DD');
      const toCheck = moment(to).format('YYYY-MM-DD');

      if (formCheck != this.filterModel.start || this.filterModel.stop != toCheck) {
        this.filterModel.start = formCheck;
        this.filterModel.stop = toCheck;
        this.filterSource.next(this.filterModel);
      }
    } else {
      this.filterModel.start = moment(from).format('YYYY-MM-DD');
      this.filterModel.stop = moment(to).format('YYYY-MM-DD');
      this.filterSource.next(this.filterModel);
    }
    this.getOrderList();
  }
}
