import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { isNil } from 'lodash/fp';
import moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DEFAULT_PER_PAGE, MODAL_CONFIG_LG } from 'src/app/configs/global';
import { DateFilterEnum } from 'src/app/core/enum/dateFilter.enum';
import { OrderFilterEnum } from 'src/app/core/enum/orderFilter.enum';
import { CommonService } from 'src/app/core/services/common.service';
import { InformationMessageService } from 'src/app/core/services/information-message.service';
import { OrderRecheckService } from 'src/app/core/services/order-recheck.service';
import { OrderService } from 'src/app/core/services/order.service';
import { OrderListPage, PagingModel, SortByModel } from 'src/app/models/base/paging.model';
import { ITEM_PER_PAGE } from 'src/app/models/base/util.model';
import { OrderFilterModel } from 'src/app/models/order-filter.model';
import { OrderRecheckModel } from 'src/app/models/order-recheck.model';
import { OrderCommonModel, OrderPartnerItemsModel } from 'src/app/models/order.model';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss'],
})
export class OrdersListComponent implements OnInit, OnDestroy {
  intervalId: ReturnType<typeof setInterval>;
  orderListPage: OrderListPage;
  pagingModel: PagingModel;
  sorting: SortByModel;
  partnerTotal: OrderPartnerItemsModel = new OrderPartnerItemsModel();
  partnerListTop: OrderPartnerItemsModel[] = [];
  partnerListBottom: OrderPartnerItemsModel[] = [];
  orderCommon: OrderCommonModel = new OrderCommonModel();
  orderRecheck: OrderRecheckModel = new OrderRecheckModel();
  startDate: Date;
  endDate: Date;
  isDateStartDisabled = true;
  isDateEndDisabled = true;
  isDateEndHidden = false;
  isSelectedPartner = false;
  isAutoRefresh = true;
  selectedDateFilter = DateFilterEnum.Today;
  filterModel: OrderFilterModel;
  momentFormat = 'YYYY-MM-DDTHH:mm:ss';
  dateFilterList = Object.values(DateFilterEnum);
  defaultDateFilter = DateFilterEnum.Today;
  orderFilterEnum = OrderFilterEnum;
  itemPerPage: number = DEFAULT_PER_PAGE;
  partnerSection: string;
  partnerIndex: number;
  itemPerPageValue = ITEM_PER_PAGE;
  sortingValue;
  counterDateStart = 0;
  counterDateEnd = 0;
  tableHeader = [
    {
      key: 'invoiceNumber',
      value: 'ออเดอร์',
    },
    {
      key: 'ref_id_name',
      value: 'Ref',
    },
    {
      key: 'referenceNumber',
      value: 'Ref no',
    },
    {
      key: 'orderDate',
      value: 'วันที่สั่งซื้อ',
    },
    {
      key: 'deliveryDate',
      value: 'วันที่ต้องจัดส่ง',
    },
    {
      key: 'customerName',
      value: 'ชื่อ',
    },
    {
      key: 'provinceName',
      value: 'จังหวัด',
    },
    {
      key: 'promotionName',
      value: 'รายการ(Set)',
    },
    {
      key: 'itemQuantity',
      value: 'จำนวน',
    },
    {
      key: 'orderPrice',
      value: 'ค่าสินค้า',
    },
    {
      key: 'deliveryPrice',
      value: 'ค่าส่ง',
    },
    {
      key: 'discountPrice',
      value: 'ส่วนลด',
    },
    {
      key: 'totalPrice',
      value: 'ราคารวม',
    },
    {
      key: 'orderStatusId',
      value: 'Status',
    },
    {
      key: 'paymentStatusId',
      value: 'Payment',
    },
    {
      key: 'deliveryQueue',
      value: 'คิวรถ',
    },
    {
      key: 'updateBy',
      value: 'คนกรอก',
    },
    {
      key: 'verifyBy',
      value: 'คนตรวจ',
    },
    {
      key: '',
      value: '',
    },
  ];
  modalRef: BsModalRef;

  constructor(
    private orderService: OrderService,
    private commonService: CommonService,
    private modalService: BsModalService,
    private messageService: InformationMessageService,
    private orderRecheckService: OrderRecheckService
  ) {
    this.intervalId = setInterval(() => {
      if (!document.hidden && this.isAutoRefresh) {
        this.getOrdersDateRange();
      }
    }, 10000);
  }

  private getPartnerList(from: string, to: string, isDeliveryDate: boolean = false) {
    this.orderService.getPartnerList(from, to, isDeliveryDate).subscribe((res) => {
      let list = res.map((x: any) => Object.assign(new OrderPartnerItemsModel(), x));
      this.partnerTotal = list.find((x: any) => x.id === 0);
      list = list.filter((x: any) => x.id !== 0);
      const halfPartnerList = Math.ceil(list.length / 2);

      if (halfPartnerList > 0) {
        this.partnerListTop = list.slice(0, halfPartnerList);
        this.partnerListBottom = list.slice(halfPartnerList);
      } else {
        this.partnerListTop = [];
        this.partnerListBottom = [];
      }
    });
  }

  private convertPagingModel() {
    let modelStr = `{`;
    modelStr = modelStr + `"TotalItems":"${this.pagingModel.totalItems}"`;
    modelStr = modelStr + `,"PerPage":"${this.itemPerPage}"`;
    modelStr = modelStr + `,"CurrentPage":"${this.pagingModel.currentPage}"`;
    modelStr = modelStr + `,"MaxPage":"${this.pagingModel.maxPage}"`;
    modelStr = modelStr + `}`;
    return modelStr;
  }

  private convertFilterModel() {
    let modelStr = `{`;
    modelStr = modelStr + `"FilterText":"${this.filterModel.searchText}"`;

    // TransactionType
    if (!isNil(this.filterModel.transactionTypeId)) {
      modelStr = modelStr + `,"transportationTypeId":"${this.filterModel.transactionTypeId}"`;
    }

    // PaymentStatus
    if (!isNil(this.filterModel.paymentStatusId)) {
      modelStr = modelStr + `,"paymentStatusId":"${this.filterModel.paymentStatusId}"`;
    }

    // DeliveryStatus
    if (!isNil(this.filterModel.deliveryStatusId)) {
      modelStr = modelStr + `,"deliveryStatusId":"${this.filterModel.deliveryStatusId}"`;
    }

    // Partner
    if (!isNil(this.filterModel.partnerId)) {
      modelStr = modelStr + `,"partnerId":"${this.filterModel.partnerId}"`;
    }

    // IsWaitingData
    if (!isNil(this.filterModel.isWaitingData)) {
      modelStr = modelStr + `,"isWaitingData":"${this.filterModel.isWaitingData}"`;
    }

    // IsFilterProductName
    if (!isNil(this.filterModel.isFilterProductName)) {
      modelStr = modelStr + `,"isFilterProductName":"${this.filterModel.isFilterProductName}"`;
    }

    // IsSearchByDeliveryDate
    if (!isNil(this.filterModel.isSearchByDeliveryDate)) {
      modelStr = modelStr + `,"searchByDeliveryDate":"${this.filterModel.isSearchByDeliveryDate}"`;
    }

    modelStr = modelStr + `}`;
    return modelStr;
  }

  private convertSortModel() {
    return `{"SortBy":"${this.sorting.sortBy}","Descending":${this.sorting.descending}}`;
  }

  getOrdersDateRange() {
    this.orderService
      .getOrdersDateRange(
        this.filterModel.start,
        this.filterModel.stop,
        this.convertPagingModel(),
        this.convertFilterModel(),
        this.convertSortModel()
      )
      .subscribe((res) => {
        this.orderListPage = res;
      });
    this.getPartnerList(this.filterModel.start, this.filterModel.stop, this.filterModel.isSearchByDeliveryDate);
  }

  initialFilterValue() {
    this.filterModel = new OrderFilterModel();
    this.filterModel.searchText = '';
    this.filterModel.start = moment().startOf('day').format(this.momentFormat);
    this.filterModel.stop = moment().endOf('day').format(this.momentFormat);
    this.startDate = moment(this.filterModel.start).toDate();
    this.endDate = moment(this.filterModel.stop).toDate();
  }

  initialPagingValue() {
    this.pagingModel = new PagingModel();
    this.pagingModel.currentPage = 1;
    this.pagingModel.perPage = 10;
    this.pagingModel.maxPage = 1;
    this.pagingModel.totalItems = 10;
  }
  initialSortByValue() {
    this.sorting = new SortByModel();
    this.sorting.sortBy = this.tableHeader[1].key; // Default is ref_id_name
    this.sortingValue = this.tableHeader[1].value; // Default for dropdown
  }

  async ngOnInit() {
    this.orderCommon = await this.commonService.getOrderCommon();
    this.initialFilterValue();
    this.initialPagingValue();
    this.initialSortByValue();
    this.getOrdersDateRange();
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  getPaymentStatus(id: number) {
    const paymentStatus = this.orderCommon.paymentStatuses.find((x) => x.id == id);
    if (paymentStatus) {
      return paymentStatus.name;
    }
  }

  getOrderStatus(id: number) {
    const orderStatus = this.orderCommon.orderStatus.find((x) => x.id == id);
    if (orderStatus) {
      return orderStatus.name;
    }
  }

  onChange($event: any, typeOfFilter: OrderFilterEnum, section?: string, index?: number) {
    this.pagingModel.currentPage = 1;
    switch (typeOfFilter) {
      case OrderFilterEnum.Delivery:
        this.filterModel.deliveryStatusId = $event?.id ?? null;
        this.getOrdersDateRange();
        break;
      case OrderFilterEnum.Payment:
        this.filterModel.paymentStatusId = $event?.id ?? null;
        this.getOrdersDateRange();
        break;
      case OrderFilterEnum.Transaction:
        this.filterModel.transactionTypeId = $event?.id ?? null;
        this.getOrdersDateRange();
        break;
      case OrderFilterEnum.WaitingData:
        this.filterModel.isWaitingData = $event.target.checked;
        this.getOrdersDateRange();
        break;
      case OrderFilterEnum.ProductName:
        this.filterModel.isFilterProductName = $event.target.checked;
        this.getOrdersDateRange();
        break;
      case OrderFilterEnum.DeliveryDate:
        this.filterModel.isSearchByDeliveryDate = $event.target.checked;
        this.getOrdersDateRange();
        break;
      case OrderFilterEnum.Partner:
        this.partnerSection = section;
        this.partnerIndex = index;
        this.isSelectedPartner = true;
        this.filterModel.partnerId = $event.id;
        this.getOrdersDateRange();
        break;
      case OrderFilterEnum.Total:
        this.partnerSection = section;
        this.partnerIndex = index;
        this.isSelectedPartner = false;
        delete this.filterModel.partnerId;
        this.getOrdersDateRange();
        break;
      default:
        return '';
    }
  }

  filterByText($event: any) {
    this.filterModel.searchText = $event.target.value;
    this.getOrdersDateRange();
  }

  convertDateToString(date: any, ref_id_name: any = '') {
    const format = ref_id_name === 'Line ส่งด่วน' ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY';
    return date == null ? '-' : moment(date).format(format);
  }

  dateStartChange($event: any) {
    this.counterDateStart = this.counterDateStart + 1;
    if (this.counterDateStart > 2 && $event.length !== 0) {
      this.startDate = $event;
      if (moment().diff(moment(this.startDate), 'days') > 30) {
        this.isAutoRefresh = false;
      }
      const endDate = this.selectedDateFilter == DateFilterEnum.SelectDate ? this.startDate : this.endDate;
      this.filterModel.start = moment(this.startDate).startOf('day').format(this.momentFormat);
      this.filterModel.stop = moment(endDate).endOf('day').format(this.momentFormat);
      this.getOrdersDateRange();
    }
  }

  dateEndChange($event: any) {
    this.counterDateEnd = this.counterDateEnd + 1;
    if (this.counterDateEnd > 2 && $event.length !== 0) {
      this.endDate = $event;
      this.filterModel.stop = moment(this.endDate).endOf('day').format(this.momentFormat);
      this.getOrdersDateRange();
    }
  }

  onDatePresentChanged($event: DateFilterEnum) {
    if ($event == DateFilterEnum.Custom) {
      this.isDateStartDisabled = false;
      this.isDateEndDisabled = false;
    } else {
      this.isDateStartDisabled = true;
      this.isDateEndDisabled = true;
    }

    if ($event == DateFilterEnum.SelectDate) {
      this.isDateEndHidden = true;
      this.isDateStartDisabled = false;
    } else {
      this.isDateEndHidden = false;
    }

    this.selectedDateFilter = $event;
    this.pagingModel.currentPage = 1;

    switch ($event) {
      case DateFilterEnum.Yesterday:
        this.startDate = moment().subtract(1, 'day').startOf('day').toDate();
        this.endDate = moment().subtract(1, 'day').endOf('day').toDate();
        this.filterModel.start = moment(this.startDate).format(this.momentFormat);
        this.filterModel.stop = moment(this.endDate).format(this.momentFormat);
        break;
      case DateFilterEnum.Today:
        this.startDate = moment().startOf('day').toDate();
        this.endDate = moment().endOf('day').toDate();
        this.filterModel.start = moment(this.startDate).format(this.momentFormat);
        this.filterModel.stop = moment(this.endDate).format(this.momentFormat);
        break;
      case DateFilterEnum.Tomorrow:
        this.startDate = moment().startOf('day').add(1, 'day').toDate();
        this.endDate = moment().endOf('day').add(1, 'day').toDate();
        this.filterModel.start = moment(this.startDate).format(this.momentFormat);
        this.filterModel.stop = moment(this.endDate).format(this.momentFormat);
        break;
      case DateFilterEnum.ThisWeek:
        this.startDate = moment().startOf('week').toDate();
        this.endDate = moment().endOf('week').toDate();
        this.filterModel.start = moment(this.startDate).format(this.momentFormat);
        this.filterModel.stop = moment(this.endDate).format(this.momentFormat);
        break;
      case DateFilterEnum.ThisMonth:
        this.startDate = moment().startOf('month').toDate();
        this.endDate = moment().endOf('month').toDate();
        this.filterModel.start = moment(this.startDate).format(this.momentFormat);
        this.filterModel.stop = moment(this.endDate).format(this.momentFormat);
        break;
      case DateFilterEnum.LastMonth:
        this.startDate = moment().subtract(1, 'month').startOf('month').toDate();
        this.endDate = moment().subtract(1, 'month').endOf('month').toDate();
        this.filterModel.start = moment(this.startDate).format(this.momentFormat);
        this.filterModel.stop = moment(this.endDate).format(this.momentFormat);
        break;
      case DateFilterEnum.Custom:
        break;
      case DateFilterEnum.SelectDate:
        this.startDate = moment().startOf('day').toDate();
        this.endDate = moment().endOf('day').toDate();
        this.filterModel.start = moment(this.startDate).format(this.momentFormat);
        this.filterModel.stop = moment(this.endDate).format(this.momentFormat);
        break;
      default:
        return this.initialFilterValue();
    }
  }

  onSelectItemPerPage() {
    this.pagingModel.currentPage = 1;
    this.getOrdersDateRange();
  }

  onChangePage(event: number) {
    this.pagingModel.currentPage = event;
    this.getOrdersDateRange();
  }

  addComma(value: number) {
    return value?.toLocaleString();
  }

  onSelectSortingField(value: string) {
    const key = this.tableHeader?.find((x) => x.value === value).key;
    this.sorting.sortBy = key;
    this.initialPagingValue();
    this.getOrdersDateRange();
  }

  onSelectDescending($event) {
    this.sorting.descending = $event.target.checked;
    this.initialPagingValue();
    this.getOrdersDateRange();
  }

  openOrderRecheckModal(template: TemplateRef<any>, id: string) {
    this.getOrderById(id);
    this.modalRef = this.modalService.show(template, MODAL_CONFIG_LG);
  }

  getTotalGift() {
    // return this.orderRecheck.place.gifts.reduce((acc, cur) => acc + cur.amount, 0);
  }

  onChangeIsCheck($event: any, id: string) {
    const checkValue = $event.target.checked;
    this.orderService.orderReCheck(id, checkValue).subscribe(
      () => {
        this.messageService.showSuccess('Update successful');
      },
      (err) => {
        this.messageService.showError('Update failed');
      }
    );
  }

  sendLineBot(orderId: string) {
    return this.orderService.sendNotificationLineBot(orderId).subscribe(
      () => {
        this.messageService.showSuccess('Sent Line bot Notification');
      },
      () => {
        this.messageService.showError('Sent failed');
      }
    );
  }

  onChangeAutoRefresh($event: any) {
    this.isAutoRefresh = $event.target.checked;
  }

  private getOrderById(id: string) {
    this.orderService.getOrderById(id).subscribe(async (res) => {
      this.orderRecheck = await this.orderRecheckService.bindOrderRecheck(res);
    });
  }

  magnify(imgID, zoom) {
    var img, glass, w, h, bw;
    img = document.getElementById(imgID);
    /* Create magnifier glass: */
    glass = document.createElement('DIV');
    glass.setAttribute('class', 'img-magnifier-glass');

    /* Insert magnifier glass: */
    img.parentElement.insertBefore(glass, img);

    /* Set background properties for the magnifier glass: */
    glass.style.backgroundImage = "url('" + img.src + "')";
    glass.style.backgroundRepeat = 'no-repeat';
    glass.style.backgroundSize = img.width * zoom + 'px ' + img.height * zoom + 'px';
    bw = 3;
    w = glass.offsetWidth / 2;
    h = glass.offsetHeight / 2;

    /* Execute a function when someone moves the magnifier glass over the image: */
    glass.addEventListener('mousemove', moveMagnifier);
    img.addEventListener('mousemove', moveMagnifier);

    /*and also for touch screens:*/
    glass.addEventListener('touchmove', moveMagnifier);
    img.addEventListener('touchmove', moveMagnifier);
    function moveMagnifier(e) {
      var pos, x, y;
      /* Prevent any other actions that may occur when moving over the image */
      e.preventDefault();
      /* Get the cursor's x and y positions: */
      pos = getCursorPos(e);
      x = pos.x;
      y = pos.y;
      /* Prevent the magnifier glass from being positioned outside the image: */
      if (x > img.width - w / zoom) {
        x = img.width - w / zoom;
      }
      if (x < w / zoom) {
        x = w / zoom;
      }
      if (y > img.height - h / zoom) {
        y = img.height - h / zoom;
      }
      if (y < h / zoom) {
        y = h / zoom;
      }
      /* Set the position of the magnifier glass: */
      glass.style.left = x - w + 'px';
      glass.style.top = y - h + 'px';
      /* Display what the magnifier glass "sees": */
      glass.style.backgroundPosition = '-' + (x * zoom - w + bw) + 'px -' + (y * zoom - h + bw) + 'px';
    }

    function getCursorPos(e) {
      var a,
        x = 0,
        y = 0;
      e = e || window.event;
      /* Get the x and y positions of the image: */
      a = img.getBoundingClientRect();
      /* Calculate the cursor's x and y coordinates, relative to the image: */
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /* Consider any page scrolling: */
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }
  }
}
