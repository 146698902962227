import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FileValidators } from 'ngx-file-drag-drop';
import { BehaviorSubject, concat, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/common.service';
import { CustomerService } from 'src/app/core/services/customer.service';
import { InformationMessageService } from 'src/app/core/services/information-message.service';
import { OrderService } from 'src/app/core/services/order.service';
import { SaveOrderService } from 'src/app/core/services/save-order.service';
import { DropdownItemModel } from 'src/app/models/base/util.model';
import { CustomerViewModel } from 'src/app/models/customer.model';
import { OrderRecheckModel } from 'src/app/models/order-recheck.model';
import { OrderCommonModel, OrderDetail, OrderDetailImageFileModel, OrderPlaceModel } from 'src/app/models/order.model';
import { selectedOrderProduct } from 'src/app/models/product.model';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit, OnDestroy {
  value: any = 0;
  min = 1;
  max = 50;
  step = 1;
  selectedSetId: string = '';
  numberOfSet = 0;
  numberOfItem = 0;
  isSaveable = false;
  orderId: string;

  selectProducts: selectedOrderProduct[] = [];
  setList: DropdownItemModel[] = [];

  orderDetail: OrderDetail = new OrderDetail();
  selectedCustomer: any;
  selectedPartner: any;
  customersData: any[] = [];
  customerName: string;
  customerList$ = new Observable<CustomerViewModel[]>();
  searchText$ = new Subject<string>();
  isCustomerLoading = false;
  selectedPalce = OrderPlaceModel;
  orderCommon: OrderCommonModel = new OrderCommonModel();
  recheckModel: OrderRecheckModel = new OrderRecheckModel();

  placeList: OrderPlaceModel[] = [];
  paymentStatusId: Number = 1;

  fileControl = new FormControl(
    [],
    [FileValidators.required, FileValidators.maxFileCount(2), FileValidators.fileType(['txt'])]
  );

  refImageUrl = '';
  refImageUrl$ = new BehaviorSubject(this.refImageUrl);
  profImageUrl = '';
  profImageUrl$ = new BehaviorSubject(this.refImageUrl);
  moreSlipImageUrl = '';
  moreSlipImageUrl$ = new BehaviorSubject(this.refImageUrl);

  dropdownCustomerSetting = {
    singleSelection: true,
    text: 'select molta customer',
    enableSearchFilter: true,
    classes: 'form-control',
    searchAutofocus: false,
    lazyLoading: true,
    badgeShowLimit: 1,
    enableFilterSelectAll: false,
    searchBy: ['itemName'],
  };

  paymentType: any[] = [];

  orderSubscrip: Subscription;

  modalRef!: BsModalRef;
  modalConfig = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  constructor(
    private saveOrderService: SaveOrderService,
    private customerService: CustomerService,
    private orderService: OrderService,
    private commonService: CommonService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private messageService: InformationMessageService
  ) {}

  async ngOnInit(): Promise<void> {
    this.orderId = this.route.snapshot.paramMap.get('id');
    this.getAllSets();
    this.loadCustomer();
    this.orderDetail.orderDate = moment().toDate();
    this.addInitialPlace();
    this.orderCommon = await this.commonService.getOrderCommon();
    this.paymentType = this.orderCommon.paymentStatuses;
    this.paymentStatusId = this.paymentType[0].id;

    if (this.orderId == null) {
      this.saveOrderService.createNewOrderDetail();
    } else {
      this.orderService.getOrderById(this.orderId).subscribe(async (res) => {
        const apiModel = res;
        const model = await this.saveOrderService.bindOrderDetail(apiModel);
        this.customerName = model.places?.receiverName;
        this.recheckModel = await this.saveOrderService.getRecheckModel(
          this.orderCommon.provinces,
          this.orderCommon.orderStatus
        );
        console.log('this.recheckModel', this.recheckModel);
      });
    }

    this.orderSubscrip = this.saveOrderService.orderDetail.subscribe((o) => {
      this.orderDetail = o;
      if (this.orderDetail.refImageFile?.filePath) {
        this.refImageUrl$.next(this.orderDetail.refImageFile.filePath);
        //  this.refImageUrl = this.orderDetail.refImageFile.filePath;
        // console.log(this.refImageUrl)
      }

      if (this.orderDetail.profImageFile?.filePath) this.profImageUrl$.next(this.orderDetail.profImageFile.filePath);

      if (this.orderDetail.moreSlipImageFile?.filePath)
        this.moreSlipImageUrl$.next(this.orderDetail.moreSlipImageFile.filePath);

      console.log('this.orderDetail', this.orderDetail);
    });
  }

  ngOnDestroy() {
    this.orderSubscrip.unsubscribe();
  }

  async onSave() {
    // const apiModel = await this.saveOrderService.convertToAPIModel();
    // console.log(JSON.stringify(apiModel));
    console.log('this.orderDetail.id', this.orderDetail.id);
    if (!this.orderDetail.id) this.saveOrderService.saveOrder();
    else this.saveOrderService.updateOrder();
  }

  onSelectCustomer($event: any) {
    const customerId = $event.id;
    this.customerService.getCustomerById(customerId).subscribe((res) => {
      const address = res.customerAddress[0];

      this.orderDetail.customerId = customerId;
      this.orderDetail.places.customerAddressId = address.id;
      this.orderDetail.places.address = address.deliveryAddress;
      this.orderDetail.tel = address.phone;
      this.orderDetail.places.tel = address.phone;
      this.orderDetail.places.province = address.provinceId;
      this.orderDetail.places.locationLink = address.googleMapUrl;
      this.orderDetail.places.receiverName = res.name;
      this.saveOrderService.updateOrderDetail(this.orderDetail);
    });
  }

  onPartnersChange($event: any) {
    this.orderDetail.ref_id = $event.id;
    this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  saveRefImage(base64file: any) {
    this.orderDetail.refImageFile = new OrderDetailImageFileModel();
    this.orderDetail.refImageFile.fileBase64String = base64file;
    this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  saveProfImage(base64file: any) {
    this.orderDetail.profImageFile = new OrderDetailImageFileModel();
    this.orderDetail.profImageFile.fileBase64String = base64file;
    this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  saveMoreSlipImage(base64file: any) {
    this.orderDetail.moreSlipImageFile = new OrderDetailImageFileModel();
    this.orderDetail.moreSlipImageFile.fileBase64String = base64file;
    this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  clearRefImage() {
    this.orderDetail.refImageFile.fileBase64String = '';
    this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  clearProfImage() {
    this.orderDetail.profImageFile.fileBase64String = '';
    this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  clearMoreSlipImage() {
    this.orderDetail.moreSlipImageFile.fileBase64String = '';
    this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  private addInitialPlace() {
    this.placeList = [];
    const place1 = new OrderPlaceModel();
    place1.deliveryNumber = '1234';
    this.placeList.push(place1);
  }

  private loadCustomer() {
    this.customerList$ = concat(
      of([]), // default items
      this.searchText$.pipe(
        distinctUntilChanged(),
        tap(() => (this.isCustomerLoading = true)),
        switchMap((term) =>
          this.customerService.filter(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.isCustomerLoading = false;
            })
          )
        )
      )
    );
  }

  trackByFn(item: CustomerViewModel) {
    return item.id;
  }

  getAllSets(): void {
    this.setList.push({ id: '6', name: 'Set 6 cups' });
    this.setList.push({ id: '4', name: 'Set 4 cups' });
    this.setList.push({ id: '2', name: 'Set 2 cups' });
  }

  setSetChange(value: any): void {
    this.numberOfSet = value;
  }

  onValueChange(file: File[]) {
    console.log('file', file);
    const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file[0]));
    console.log('File changed!', url);
  }

  //dropdown customer
  onItemSelect() {}

  addNewPlace() {
    this.orderDetail.places = new OrderPlaceModel();
    this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  setPaymentChange(value: any) {
    this.orderDetail.paymentStatus = value.id;
    this.saveOrderService.updateOrderDetail(this.orderDetail);
  }
  onChecked($event: any) {
    this.orderDetail.isCheck = $event;
    this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  openCreateModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.modalConfig);
  }
  isSelectedAddress1: boolean = true;
  isSelectedAddress2: boolean = false;
  cars: any[] = [];
  selectedCar = ['test1', 'test2'];

  toggleSelectAddress1() {
    if (!this.isSelectedAddress1) {
      this.isSelectedAddress1 = !this.isSelectedAddress1;
    }
    this.isSelectedAddress2 = false;
  }

  toggleSelectAddress2() {
    if (!this.isSelectedAddress2) {
      this.isSelectedAddress2 = !this.isSelectedAddress2;
    }
    this.isSelectedAddress1 = false;
  }

  testMessage() {
    this.messageService.showSuccess();
  }
}
