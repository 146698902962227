export enum DateFilterEnum {
  Yesterday = 'Yesterday',
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  ThisWeek = 'This Week',
  ThisMonth = 'This Month',
  LastMonth = 'Last Month',
  Custom = 'Custom Range',
  SelectDate = 'Select Date',
}
