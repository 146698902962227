export class ProductViewModel {
  id?: number;
  nameTH: string;
  nameEN: string;
  description: string;
  productContent: ProductContentViewModel[];
  price: number;
  additionalPrice: number;
  thumbnailImage: UploadImageViewModel;
  images: UploadImageViewModel[];
  isActive: boolean;
  isOnlineShopping: boolean;
  isPosSale: boolean;
  ipCode: string;
  canReserve: boolean;
  categories: categoriesViewModel[];
  stockNumber: number;
  nutritions: ProductNutritionViewModel[];
  allergics: ProductAllergicViewModel[];
  productBranch: any[];
}

export class selectedOrderProduct {
  productId: number | undefined;
  orderId: number | undefined;
  productCode: string | undefined;
  nameTH: string | undefined;
  nameEN: string | undefined;
  quantity: number | undefined;
}

export class ProductContentViewModel {
  id: number;
  content: string;
  sortOrder: number;
}

export class UploadImageViewModel {
  id: number;
  url: string;
  filePathBase64: string;
}

export class ProductNutritionViewModel {
  id?: number;
  nutritionId: number;
  value: number;
  sortOrder: number;
}

export class ProductAllergicViewModel {
  id?: number;
  allergicId: number;
  value: boolean;
  sortOrder: number;
}

export class categoriesViewModel {
  id?: number;
  categoryId: number;
  categoryName: string;
}

export class branchViewModel {
  id?: number;
  branchId: number;
  branchName: string;
}
