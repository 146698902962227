import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ListOrderService } from 'src/app/core/services/list-order.service';
import { OrderService } from 'src/app/core/services/order.service';
import { OrderFilterModel } from 'src/app/models/order-filter.model';
import { OrderTotalItemModel, OrderTotalListModel, OrderTotalStickmodel } from 'src/app/models/order.model';

@Component({
  selector: 'app-total-orders',
  templateUrl: './total-orders.component.html',
  styleUrls: ['./total-orders.component.scss'],
})
export class TotalOrdersComponent implements OnInit, OnDestroy {
  @Input() selectTransport: string = '';
  @Output() filterChanged = new EventEmitter<string>();

  orderTotalList: OrderTotalListModel = new OrderTotalListModel();
  orderTotal: OrderTotalStickmodel = new OrderTotalStickmodel();
  listFilter$: Subscription;
  filter: OrderFilterModel;

  constructor(private orderService: OrderService, private listOrderService: ListOrderService) {}

  ngOnInit(): void {
    this.listFilter$ = this.listOrderService.filter.subscribe((o) => {
      this.filter = o;
      this.bindOrderTotal(this.filter.start, this.filter.stop);
    });
  }

  ngOnDestroy() {
    if (this.listFilter$) this.listFilter$.unsubscribe();
  }

  bindOrderTotal(fromDate: string, toDate: string) {
    const from = moment(fromDate).format('YYYY-MM-DDT00:00:00');
    const to = moment(toDate).format('YYYY-MM-DDT23:00:00');
    const total = this.orderService.getOrderTotalRange(from, to).subscribe((res) => {
      this.orderTotalList.items = res.map((x: any) => Object.assign(new OrderTotalItemModel(), x));
      this.orderTotal = this.orderTotalList.getStickModel();
    });
  }

  totalClick(id: number) {
    this.listOrderService.searchByTransaction(id);
  }
}
