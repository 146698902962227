import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { OrderPartnerItemsModel } from 'src/app/models/order.model';
import { OrderService } from 'src/app/core/services/order.service';
import { ListOrderService } from 'src/app/core/services/list-order.service';
import { Subscription } from 'rxjs';
import { OrderFilterModel } from 'src/app/models/order-filter.model';

@Component({
  selector: 'app-payment-delivery',
  templateUrl: './payment-delivery.component.html',
  styleUrls: ['./payment-delivery.component.scss'],
})
export class PaymentDeliveryComponent implements OnInit, OnDestroy {
  partnerList: OrderPartnerItemsModel[] = [];
  listFilter$: Subscription;
  filter: OrderFilterModel;

  constructor(private orderService: OrderService, private listOrderService: ListOrderService) {}

  ngOnInit(): void {
    this.listFilter$ = this.listOrderService.filter.subscribe((o) => {
      this.filter = o;
      this.getPartnerList(this.filter.start, this.filter.stop);
    });
  }

  ngOnDestroy() {
    if (this.listFilter$) this.listFilter$.unsubscribe();
  }

  filterClick(id: number) {
    this.listOrderService.searchByPartner(id);
  }

  private getPartnerList(fromDate: string, toDate: string) {
    const from = moment(fromDate).format('YYYY-MM-DDT00:00:00');
    const to = moment(toDate).format('YYYY-MM-DDT23:00:00');
    this.orderService.getPartnerList(from, to, false).subscribe((res) => {
      this.partnerList = res.map((x: any) => Object.assign(new OrderPartnerItemsModel(), x));
    });
  }
}
