<div class="box box-product-order">
  <div class="box-header with-border">
    <div class="box-title">
      <h2>รายการสั่งซื้อสินค้า</h2>
      <div
        class="amount-status"
        [class.complete]="orderDetail.numberOfSet != 0 && orderDetail.numberOfSet == numberOfItem"
      >
        <i
          class="fa fa-exclamation"
          *ngIf="orderDetail.numberOfSet == 0 || orderDetail.numberOfSet != numberOfItem"
        ></i>
        <i class="fas fa-check" *ngIf="orderDetail.numberOfSet != 0 && orderDetail.numberOfSet == numberOfItem"></i>
        <span></span> {{ numberOfItem }}/{{ orderDetail.numberOfSet }}
      </div>
    </div>
  </div>
  <div class="box-body">
    <div class="table-responsive">
      <table id="example2" class="table table-striped table-order-product">
        <thead>
          <tr>
            <th class="text-center">รหัสสินค้า</th>
            <th>รายการ (TH)</th>
            <th>รายการ (EN)</th>
            <th class="text-center">จำนวน</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of selectProducts; let i = index">
            <td class="text-center">
              {{ item.productCode }}
            </td>
            <td>{{ item.nameTh }}</td>
            <td>{{ item.nameEn }}</td>
            <td>
              <ng-number-picker
                [(value)]="item.quantity!"
                [buttonsOrientation]="'h'"
                [size]="'sm'"
                [showDownButton]="true"
                [showUpButton]="true"
                [inputReadOnly]="false"
                [mouseWheel]="false"
                [arrowKeys]="false"
                [showUpButton]="numberOfItem != orderDetail.numberOfSet"
                (valueChange)="itemQuantityChange($event, i)"
                [placeholder]="'How much'"
                [customClass]="{ container: 'number-picker-wrapper', up: '', down: '', prefix: '', postfix: '' }"
              >
              </ng-number-picker>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="category-list">
      <div class="category-label-wrapper">
        <span
          class="label label-default category-label"
          [class.active]="filterProductId == category.id"
          *ngFor="let category of productCategoryList"
          (click)="filterProduct(category.id)"
        >
          {{ category.name }}
        </span>
      </div>
      <div class="category-img-wrapper">
        <ng-container *ngFor="let product of productList; let i = index">
          <div class="row" *ngIf="i % 4 === 0">
            <div
              class="col product-container"
              *ngFor="let productCol of productList.slice(i, i + 4)"
              (click)="addProduct(productCol)"
            >
              <div class="img-item">
                <img class="img-responsive" src="{{ getProductImage(productCol) }}" alt="" />
                <span class="category-name">{{ productCol.nameEN }}</span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
