import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './base/api.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private apiService: ApiService) {}

  public userName = new BehaviorSubject('');

  getCustomerList(): Observable<any> {
    return this.apiService.get(`customers`);
  }

  getCustomerById(id: string): Observable<any> {
    return this.apiService.get(`customers/${id}`);
  }

  getOrderByCustomerId(customerId: number): Observable<any> {
    return this.apiService.get(`customers/${customerId}/orders`);
  }

  createCustomer(customer: any): Observable<any> {
    return this.apiService.post(`customers`, customer);
  }

  createCustomerFromOrder(customer: any): Observable<any> {
    return this.apiService.post(`customers/order`, customer);
  }

  updateCustomer(customerId: number, customer: any): Observable<any> {
    return this.apiService.put(`customers/${customerId}`, customer);
  }

  deleteCustomer(customerId: number): Observable<any> {
    return this.apiService.delete(`customers/${customerId}`);
  }

  getCustomerAddressById(customerId: string, addressId: number): Observable<any> {
    return this.apiService.get(`customers/${customerId}/address/${addressId}`);
  }

  getCustomerAddresses(customerId: string): Observable<any> {
    return this.apiService.get(`customers/${customerId}/addresses`);
  }
  createCustomerAddress(customerId: string, address: any): Observable<any> {
    return this.apiService.post(`customers/${customerId}/address`, address);
  }

  updateCustomerAddress(customerId: string, addressId: number, address: any): Observable<any> {
    return this.apiService.patch(`customers/${customerId}/address/${addressId}`, address);
  }

  filter(searchText: string) {
    if (searchText != '' && searchText != undefined) {
      if (searchText.length >= 2)
        return this.apiService.get(`customers/filter?q=${searchText}`).pipe(map((rsp) => rsp));
    }
    return of([]);
  }
}
