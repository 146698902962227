import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductPromotionComponent } from 'src/app/pages/product-promotion/product-promotion.component';
import { PageComponent } from './page.component';

const routes: Routes = [
  {
    path: '',
    component: PageComponent,
    data: {
      title: 'Dashboard',
    },
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/pages/orders/orders.module').then((m) => m.OrdersModule),
        data: {
          title: 'Orders',
        },
      },
      {
        path: 'delivery',
        loadChildren: () => import('src/app/pages/delivery/delivery.module').then((m) => m.DeliveryModule),
        data: {
          title: 'Delivery',
        },
      },
      {
        path: 'delivery',
        loadChildren: () => import('src/app/pages/delivery/delivery.module').then((m) => m.DeliveryModule),
        data: {
          title: 'Delivery',
        },
      },
      {
        path: 'setting',
        loadChildren: () => import('src/app/pages/setting/setting.module').then((m) => m.SettingModule),
        data: {
          title: 'Settings',
        },
      },
      {
        path: 'news',
        loadChildren: () => import('src/app/pages/News/news.module').then((m) => m.newsModule),
        data: {
          title: 'News',
        },
      },
      {
        path: 'branches',
        loadChildren: () => import('src/app/pages/branch/branch.module').then((m) => m.BranchModule),
        data: {
          title: 'Branches',
        },
      },
      {
        path: 'customer',
        loadChildren: () => import('src/app/pages/Customer/customer.module').then((m) => m.customerModule),
        data: {
          title: 'Customers',
        },
      },
      {
        path: 'product',
        loadChildren: () => import('src/app/pages/product/product.module').then((m) => m.ProductModule),
        data: {
          title: 'Products',
        },
      },
      {
        path: 'pos',
        loadChildren: () => import('src/app/pages/pos/pos.module').then((m) => m.PosModule),
        data: {
          title: 'POS',
        },
      },
      {
        path: 'product-promotion',
        component: ProductPromotionComponent,
        data: {
          title: 'Product set promotions',
        },
      },
      {
        path: 'accordion',
        loadChildren: () => import('../teamplate/+accordion/accordion.module').then((m) => m.AccordionModule),
        data: {
          title: 'Accordion',
        },
      },
      {
        path: 'alert',
        loadChildren: () => import('../teamplate/+alert/alert.module').then((m) => m.AlertModule),
        data: {
          title: 'Alert',
        },
      },
      {
        path: 'form',
        data: {
          title: 'Form',
        },
        children: [
          {
            path: 'input-text',
            loadChildren: () =>
              import('../teamplate/+form/input-text/input-text.module').then((m) => m.InputTextModule),
            data: {
              title: 'Input Text',
            },
          },
        ],
      },
      {
        path: 'layout',
        data: {
          title: 'Layout',
        },
        children: [
          {
            path: 'configuration',
            loadChildren: () =>
              import('../teamplate/+layout/configuration/configuration.module').then((m) => m.ConfigurationModule),
            data: {
              title: 'Configuration',
            },
          },
          {
            path: 'custom',
            loadChildren: () => import('../teamplate/+layout/custom/custom.module').then((m) => m.CustomModule),
            data: {
              title: 'Disable Layout',
              // disableLayout: true
            },
          },
          {
            path: 'content',
            loadChildren: () => import('../teamplate/+layout/content/content.module').then((m) => m.ContentModule),
            data: {
              title: 'Content',
            },
          },
          {
            path: 'header',
            loadChildren: () => import('../teamplate/+layout/header/header.module').then((m) => m.HeaderModule),
            data: {
              title: 'Header',
            },
          },
          {
            path: 'sidebar-left',
            loadChildren: () =>
              import('../teamplate/+layout/sidebar-left/sidebar-left.module').then((m) => m.SidebarLeftModule),
            data: {
              title: 'Sidebar Left',
            },
          },
          {
            path: 'sidebar-right',
            loadChildren: () =>
              import('../teamplate/+layout/sidebar-right/sidebar-right.module').then((m) => m.SidebarRightModule),
            data: {
              title: 'Sidebar Right',
            },
          },
        ],
      },
      {
        path: 'boxs',
        data: {
          title: 'Boxs',
        },
        children: [
          {
            path: 'box',
            loadChildren: () =>
              import('../teamplate/+boxs/box-default/box-default.module').then((m) => m.BoxDefaultModule),
            data: {
              title: 'Box',
            },
          },
          {
            path: 'info-box',
            loadChildren: () => import('../teamplate/+boxs/box-info/box-info.module').then((m) => m.BoxInfoModule),
            data: {
              title: 'Info Box',
            },
          },
          {
            path: 'small-box',
            loadChildren: () => import('../teamplate/+boxs/box-small/box-small.module').then((m) => m.BoxSmallModule),
            data: {
              title: 'Small Box',
            },
          },
        ],
      },
      {
        path: 'dropdown',
        loadChildren: () => import('../teamplate/+dropdown/dropdown.module').then((m) => m.DropdownModule),
        data: {
          title: 'Dropdown',
        },
      },
      {
        path: 'tabs',
        loadChildren: () => import('../teamplate/+tabs/tabs.module').then((m) => m.TabsModule),
        data: {
          title: 'Tabs',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
