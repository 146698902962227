import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, TemplateRef, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {} from 'googlemaps';
import moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FileValidators } from 'ngx-file-drag-drop';
import { BehaviorSubject, concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';
import { MODAL_CONFIG } from 'src/app/configs/global';
import { OrderStatusEnum, PaymentStatusEnum } from 'src/app/core/enum/orderstatus.enum';
import { CommonService } from 'src/app/core/services/common.service';
import { CustomerService } from 'src/app/core/services/customer.service';
import { InformationMessageService } from 'src/app/core/services/information-message.service';
import { OrderRecheckService } from 'src/app/core/services/order-recheck.service';
import { OrderService } from 'src/app/core/services/order.service';
import { ProductsService } from 'src/app/core/services/products.service';
import { DropdownItemModel } from 'src/app/models/base/util.model';
import { CalculatedOrderResult, ProductCalModel, RequestCalculateOrderModel } from 'src/app/models/calculate.model';
import { CustomerViewModel } from 'src/app/models/customer.model';
import { OrderDetailModel, OrderProductDetailModel } from 'src/app/models/order-detail.model';
import { OrderRecheckModel } from 'src/app/models/order-recheck.model';
import { OrderCommonModel } from 'src/app/models/order.model';
import { ProductViewModel, UploadImageViewModel } from 'src/app/models/product.model';

@Component({
  selector: 'app-orders-pos',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './orders-pos.component.html',
  styleUrls: ['./orders-pos.component.scss'],
})
export class OrdersPosComponent implements OnInit {
  isCollapsed = false;
  isCollapsedDelivery = true;
  isCollapsedOrderDetail = false;
  isActive1 = false;
  isActive2 = false;
  isCustomerLoading = false;
  isProvinceLoading = false;
  isPartnerLoading = false;
  isPromotionLoading = false;
  quantity: number = 1;
  numberOfBox: number = 1;
  filterProductId = 0;
  filterProductText: string = '';
  orderId: string = '';
  customerId: string = '';
  addressId: number;
  customerRefId: number;
  selectedCustomerName: string;
  selectedCustomerPhone: string;
  maxQuantity: number = 0;
  selectedPromotion: any = null;
  files: File[] = [];
  orderDetail: OrderDetailModel = new OrderDetailModel();
  orderCommon: OrderCommonModel = new OrderCommonModel();
  orderRecheck: OrderRecheckModel = new OrderRecheckModel();
  provinces = [];
  partners = [];
  promotions = [];
  transportationTypes = [];
  communicationMethod = [];
  quantityList = [];
  numberOfBoxList = [];
  customerAddresses = [];
  infoWindow: google.maps.InfoWindow;
  pinMarker: google.maps.Marker;
  searchBox: google.maps.places.SearchBox;
  productList: ProductViewModel[] = [];
  allProductList: ProductViewModel[] = [];
  categoryList: any[] = [];
  customerList$ = new Observable<CustomerViewModel[]>();
  searchText$ = new Subject<string>();
  selectedProducts: OrderProductDetailModel[] = Array();
  selectedCustomer = false;
  selectedAddressInModal = false;
  selectedAddressIndex = 0;
  addressEditMode = false;
  addressCreateMode = false;
  orderDate: any;
  deliveryDate: any;
  referenceText: string;
  fileControl = new FormControl(
    [],
    [FileValidators.required, FileValidators.maxFileCount(2), FileValidators.fileType(['txt'])]
  );
  referenceImage$ = new BehaviorSubject(<string[]>[]);
  maxQuantity$ = new BehaviorSubject<number>(0);
  orderForm: FormGroup = null;
  modalRef: BsModalRef;
  modalConfigLarge = { ...MODAL_CONFIG, class: 'modal-lg' };

  imageTypes = [
    { id: 1, name: 'รูปอ้างอิงข้อมูล' },
    { id: 2, name: 'หลักฐานการโอนเงิน' },
    { id: 3, name: 'อื่นๆ ' },
  ];

  customerForm = new FormGroup({
    name: new FormControl(),
    phone: new FormControl(),
    customerReferId: new FormControl(),
    address: new FormControl(),
    subDistrict: new FormControl(),
    district: new FormControl(),
    provinceId: new FormControl(),
    postcode: new FormControl(),
    description: new FormControl(),
  });

  addressForm = new FormGroup({
    deliveryName: new FormControl(),
    deliveryPhoneNumber: new FormControl(),
    locationLink: new FormControl(),
    address: new FormControl(),
    subDistrict: new FormControl(),
    district: new FormControl(),
    provinceId: new FormControl(),
    postcode: new FormControl(),
    description: new FormControl(),
  });

  constructor(
    private customerService: CustomerService,
    private orderService: OrderService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private productService: ProductsService,
    private messageService: InformationMessageService,
    private formBuilder: FormBuilder,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private orderRecheckService: OrderRecheckService
  ) {
    document.onfullscreenchange = function (event) {
      console.log(event);
      let target = event.target;
      let pacContainerElements = document.getElementsByClassName('pac-container');
      if (pacContainerElements.length > 0) {
        let pacContainer = document.getElementsByClassName('pac-container')[0];
        console.log(document.fullscreenElement);
        if (!document.fullscreenElement) {
          console.log('Exiting FULL SCREEN - moving pacContainer to body');
          document.getElementsByTagName('body')[0].appendChild(pacContainer);
        } else {
          console.log('Entering FULL SCREEN - moving pacContainer to target element');

          document.fullscreenElement.appendChild(pacContainer);
        }
      } else {
        console.log('FULL SCREEN change - no pacContainer found');
      }
    };
  }

  private removeText(str: string) {
    const removeSpace = str.replace(/\s/g, '');
    return removeSpace.replace(/เขต|แขวง|อ.|ต./, '');
  }

  private getQuantityList() {
    for (let i = 1; i <= 10; i++) {
      const item = new DropdownItemModel();
      item.id = i;
      item.name = i.toString();

      this.quantityList.push(item);
      this.numberOfBoxList.push(item);
    }
    this.quantity = 1;
  }

  private loadCustomer() {
    this.customerList$ = concat(
      of([]), // default items
      this.searchText$.pipe(
        distinctUntilChanged(),
        tap(() => (this.isCustomerLoading = true)),
        switchMap((term) =>
          this.customerService.filter(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.isCustomerLoading = false;
            })
          )
        )
      )
    );
  }
  private loadPromotion() {
    this.isPromotionLoading = true;
    this.commonService.filterPromotion().subscribe((x) => {
      this.promotions = x;
      this.isPromotionLoading = false;

      if (this.orderId) {
        this.selectedPromotion = x.find((promotion) => promotion.id === this.orderDetail.promotionId);
        const maxQty = x.find((promotion) => promotion.id === this.orderDetail.promotionId).quantity;
        this.maxQuantity$.next(maxQty);
      }
    });
  }

  private loadPartner() {
    this.isPartnerLoading = true;
    this.commonService.filterPartner().subscribe((x) => {
      this.partners = x;
      this.isPartnerLoading = false;
    });
  }

  private loadProvince() {
    this.isProvinceLoading = true;
    this.commonService.filterProvince().subscribe((x) => {
      this.provinces = x;
      this.isProvinceLoading = false;
    });
  }

  private getOrderFormControlName(name: string) {
    return this.orderForm.controls[name];
  }

  private getFormArray(controlName: string) {
    return this.orderForm.get(controlName) as FormArray;
  }

  private setFormArray(arrayModel: any, controlName: string) {
    if (arrayModel && arrayModel.length > 0) {
      const formGroup = arrayModel?.map((a) => this.formBuilder.group(a));
      const formArray = this.formBuilder.array(formGroup);
      return this.orderForm.setControl(controlName, formArray);
    } else {
      return this.orderForm.setControl(controlName, this.formBuilder.array([]));
    }
  }

  get f() {
    return this.orderForm.controls;
  }

  get f_customer() {
    return this.customerForm.controls;
  }

  get f_address() {
    return this.addressForm.controls;
  }

  private async getAndRemoveUnusedCategory() {
    const catList = await this.commonService.getProductCategory();
    const result = [];
    catList.forEach((cat) => {
      if (cat.id == 0) {
        result.push(cat);
      }
      const prod = this.allProductList.filter((x) => x.categories.some((c) => c.categoryId === cat.id));
      if (prod.length > 0) result.push(cat);
    });

    this.categoryList = result;
  }
  async ngOnInit() {
    this.getQuantityList();
    this.orderDetail.products = Array();
    this.orderId = this.route.snapshot.paramMap.get('id');
    this.orderCommon = await this.commonService.getOrderCommon();

    this.loadCustomer();
    this.loadProvince();
    this.loadPartner();
    this.getAllProducts();

    if (this.orderId) {
      this.orderService.getOrderById(this.orderId).subscribe(async (res) => {
        console.log('res', res);
        this.orderDetail = res;
        this.orderDetail.provinceId = res.proviceId;
        this.selectedProducts = res.products;
        this.deliveryDate = new Date(res.deliveryDate);
        this.orderDate = new Date(res.orderDate);
        this.selectedCustomerName = res.customerName;
        this.selectedCustomerPhone = res.customerPhoneNumber;
        this.customerRefId = res.referenceId;
        this.orderRecheck = await this.orderRecheckService.bindOrderRecheck(res);
        this.loadPromotion();
        this.buildForm();
      });
    } else {
      this.orderDetail.orderStatus = OrderStatusEnum.Prepare;
      this.orderDetail.paymentStatus = PaymentStatusEnum.Waiting;
      this.orderDetail.isUpcountry = false;
      this.orderDate = new Date();
      this.deliveryDate = new Date();
      this.loadPromotion();
      this.buildForm();
    }
  }

  buildForm() {
    this.orderForm = this.formBuilder.group({
      orderDate: [this.orderDetail?.orderDate, Validators.required],
      orderStatus: [this.orderDetail?.orderStatus],
      referenceId: [this.orderDetail?.referenceId, Validators.required],
      invoiceNumber: [this.orderDetail?.invoiceNumber],
      referenceText: [this.orderDetail?.referenceText],
      customerId: [this.orderDetail?.customerId, Validators.required],
      customerName: [this.orderDetail?.customerName, Validators.required],
      customerPhoneNumber: [this.orderDetail?.customerPhoneNumber],
      receiverName: [this.orderDetail?.receiverName],
      deliveryPhoneNumber: [this.orderDetail?.deliveryPhoneNumber],
      addressId: [this.orderDetail?.addressId],
      address: [this.orderDetail?.address],
      locationLink: [this.orderDetail?.locationLink],
      subDistrict: [this.orderDetail?.subDistrict],
      district: [this.orderDetail?.district],
      provinceId: [this.orderDetail?.provinceId ?? 10], // Default is Bangkok
      zipCode: [this.orderDetail?.zipCode],
      isUpcountry: [this.orderDetail?.isUpcountry],
      deliverRemark: [this.orderDetail?.deliverRemark],
      deliveryDate: [this.orderDetail?.deliveryDate, Validators.required],
      promotionId: [this.orderDetail?.promotionId, Validators.required],
      quantity: [this.orderDetail?.quantity, [Validators.required]],
      boxQuantity: [this.orderDetail?.boxQuantity, Validators.required],
      orderPrice: [this.orderDetail?.orderPrice],
      deliveryPrice: [this.orderDetail?.deliveryPrice ?? 0],
      discountPrice: [this.orderDetail?.discountPrice ?? 0],
      totalPrice: [this.orderDetail?.totalPrice ?? 0],
      products: [this.orderDetail?.products, Validators.required],
      secondPhone: [this.orderDetail?.secondPhone],
      transportationType: [this.orderDetail?.transportationType],
      smsRemark: [this.orderDetail?.smsRemark],
      factoryRemark: [this.orderDetail?.factoryRemark],
      deliveryRemark: [this.orderDetail?.deliveryRemark],
      paymentStatus: [this.orderDetail?.paymentStatus, Validators.required],
      paymentReference: [this.orderDetail?.paymentReference],
      timeRemark: [this.orderDetail?.timeRemark ?? 0],
      orderWording: [this.orderDetail?.orderWording],
    });

    if (this.orderId) {
      this.setFormArray(this.orderDetail?.referenceInformation, 'referenceInformation');
    } else {
      this.orderForm.setControl('referenceInformation', this.formBuilder.array([]));
    }
  }

  newReferenceInformation(): FormGroup {
    this.referenceImage$.next([...this.referenceImage$.value, '']);
    return this.formBuilder.group({
      id: '',
      referenceText: '',
      fileReferenceTypeId: 1,
      filePathBase64: '',
    });
  }

  getSelectedProductQuantity() {
    return this.selectedProducts.reduce((sum, current) => sum + current.quantity, 0);
  }

  getAllProducts() {
    this.productService.getAllProducts().subscribe((res) => {
      this.allProductList = res.map((x: any) => Object.assign(new ProductViewModel(), x));
      this.productList = this.allProductList;
      this.getAndRemoveUnusedCategory();
    });
  }

  getProductImage(product: ProductViewModel) {
    return product.thumbnailImage?.url;
  }

  getReferences(form: any) {
    return form.controls.referenceInformation.controls;
  }

  updatePrice() {
    // const promotionPrice = this.selectedPromotion.price;
    // //this.getFormControlName('orderPrice').setValue(price);
    // let deliveryPrice = 0;
    // const isUpcountry = this.getOrderFormControlName('isUpcountry').value;
    // if (isUpcountry === true) {
    // 	deliveryPrice = this.selectedPromotion.upcountryPrice - promotionPrice;
    // 	this.getOrderFormControlName('deliveryPrice').setValue(deliveryPrice);
    // }

    // if (this.selectedProducts.length > 0) {
    // 	const productPrice = this.selectedProducts.reduce((sum, current) => sum + current.price, 0);
    // 	const promotionPrice = this.selectedPromotion?.price;
    // 	this.getOrderFormControlName('orderPrice').patchValue(productPrice);
    // 	this.getOrderFormControlName('discountPrice').setValue(productPrice - promotionPrice);
    // 	this.getOrderFormControlName('totalPrice').patchValue(productPrice + deliveryPrice);
    // }

    const addressId = this.getOrderFormControlName('addressId').value;
    const isUpcountry: boolean = this.getOrderFormControlName('isUpcountry').value;
    const selectedPromotion = this.selectedPromotion;
    const selectedProduct = this.selectedProducts;
    if (selectedPromotion) {
      const productCal: ProductCalModel[] = selectedProduct.map((e) => {
        return {
          productId: e.productId,
          quantity: e.quantity,
        };
      });

      if (!this.getOrderFormControlName('discountPrice').value) {
        this.getOrderFormControlName('discountPrice').setValue(0);
      }

      const requestCalculate: RequestCalculateOrderModel = {
        discountPrice: this.getOrderFormControlName('discountPrice').value,
        vatType: 0,
        promotionId: selectedPromotion.id,
        calculateOrderItem: {
          addressId: addressId,
          upcountry: isUpcountry,
          products: productCal,
        },
      };

      console.log('requestCalculate', requestCalculate);
      //if (addressId) {
      this.orderService.calculatePrice(requestCalculate).subscribe((res) => {
        const calculatedResult: CalculatedOrderResult = res;
        this.getOrderFormControlName('orderPrice').patchValue(calculatedResult.orderPrice);
        //this.getOrderFormControlName('discountPrice').patchValue(calculatedResult.discountPrice);
        this.getOrderFormControlName('deliveryPrice').patchValue(calculatedResult.deliveryPrice);
        this.getOrderFormControlName('totalPrice').patchValue(calculatedResult.summaryPrice);
      });
      /*} else {
		
				let orderPrice = 0;
				let deliveryPrice = 0;
				let discountPrice = selectedPromotion.price;
				let totalPrice = 0;
				if (isUpcountry) {
					deliveryPrice = selectedPromotion.upcountryPrice - selectedPromotion.price;
				}
				if (selectedProduct.length > 0) {
					const product = this.allProductList.filter(x => selectedProduct.map(y => y.productId).includes(x.id));
					const productPrice = product.map(e => e.price);
					const priceQty = selectedProduct.map((e, i) => productPrice[i] * e.quantity);
					orderPrice = priceQty.reduce((sum, current) => sum + current, 0);
					totalPrice = orderPrice + deliveryPrice - selectedPromotion.price;
				}
				this.getOrderFormControlName('orderPrice').patchValue(orderPrice);
				this.getOrderFormControlName('deliveryPrice').patchValue(deliveryPrice);
				this.getOrderFormControlName('discountPrice').patchValue(discountPrice);
				this.getOrderFormControlName('totalPrice').patchValue(totalPrice);
			}*/
    }
  }

  addProduct(product: any) {
    if (this.maxQuantity$.value > 0 && this.getSelectedProductQuantity() < this.maxQuantity$.value) {
      if (this.selectedProducts.some((x) => x.productId == product.id)) {
        ++this.selectedProducts.find((e) => e.productId == product.id).quantity;
        // this.selectedProducts.find(e => e.productId == product.id).price = this.selectedProducts.find(e => e.productId == product.id).price * this.selectedProducts.find(e => e.productId == product.id).quantity;
      } else {
        this.selectedProducts.push({
          id: undefined,
          productId: product.id,
          code: product.ipCode,
          nameEN: product.nameEN,
          nameTH: product.nameTH,
          price: product.price,
          quantity: 1,
        });
        this.getOrderFormControlName('products').setValue(this.selectedProducts);
      }
      this.updatePrice();
      // const orderPriceValue = this.getFormControlName('orderPrice').value;
      // if (orderPriceValue) {
      // 	const newOrderPrice = orderPriceValue + product.price;
      // 	this.getFormControlName('orderPrice').patchValue(newOrderPrice);
      // } else {
      // 	this.getFormControlName('orderPrice').setValue(product.price);
      // }
    }
  }

  trackByFn(item: CustomerViewModel) {
    return item.id;
  }

  onSelectCustomer($event: any) {
    console.log('onSelectCustomer', $event);
    if ($event) {
      this.customerId = $event.id;
      this.addressId = $event.addressId;
      this.selectedCustomer = true;
      this.selectedCustomerPhone = $event.phone;
      this.getOrderFormControlName('customerId').setValue(this.customerId);
      this.getOrderFormControlName('customerPhoneNumber').setValue($event.phone);
      this.getOrderFormControlName('addressId').setValue(this.addressId);
      this.getOrderFormControlName('isUpcountry').setValue($event.isUpcountry);
      this.getOrderFormControlName('referenceId').setValue($event.customerReferId);
      this.customerService.getCustomerAddressById(this.customerId, this.addressId).subscribe((res) => {
        console.log('getCustomerAddressById', res);
        this.customerRefId = res.customerReferId ?? undefined;
        this.getOrderFormControlName('locationLink').setValue(res.locationLink);
        this.getOrderFormControlName('address').setValue(res.address);
        this.getOrderFormControlName('subDistrict').setValue(res.subDistrict);
        this.getOrderFormControlName('district').setValue(res.district);
        this.getOrderFormControlName('provinceId').setValue(res.provinceId);
        this.getOrderFormControlName('zipCode').setValue(res.postcode);
        this.getOrderFormControlName('receiverName').setValue(res.deliveryName);
        this.getOrderFormControlName('deliveryPhoneNumber').setValue(res.deliveryPhoneNumber);
        this.getOrderFormControlName('deliverRemark').setValue(res.description);
      });
      this.customerService.getCustomerAddresses(this.customerId).subscribe((res) => {
        console.log('getCustomerAddresses', res);
        this.customerAddresses = res;
      });
    } else {
      this.selectedCustomer = false;
      this.customerRefId = undefined;
      this.getOrderFormControlName('customerId').patchValue('');
      this.getOrderFormControlName('customerPhoneNumber').patchValue('');
      this.getOrderFormControlName('addressId').patchValue('');
      this.getOrderFormControlName('locationLink').patchValue('');
      this.getOrderFormControlName('address').patchValue('');
      this.getOrderFormControlName('subDistrict').patchValue('');
      this.getOrderFormControlName('district').patchValue('');
      this.getOrderFormControlName('provinceId').patchValue('');
      this.getOrderFormControlName('zipCode').patchValue('');
      this.getOrderFormControlName('receiverName').patchValue('');
      this.getOrderFormControlName('deliveryPhoneNumber').patchValue('');
      this.getOrderFormControlName('deliverRemark').patchValue('');
    }
  }
  onQuantityChange($event) {
    if (this.selectedPromotion == null) {
      this.selectedPromotion = this.promotions.filter((x) => x.id == 19)[0];
      this.getOrderFormControlName('promotionId').setValue(this.selectedPromotion.id);
    }

    this.quantity =
      this.selectedPromotion.quantity == null
        ? this.getOrderFormControlName('quantity').value
        : this.selectedPromotion.quantity;
    this.getOrderFormControlName('quantity').setValue(this.quantity);

    this.maxQuantity$.next(this.quantity);
    if (this.maxQuantity$.value > 0 && this.getSelectedProductQuantity() > this.maxQuantity$.value) {
      this.selectedProducts = [];
      this.getOrderFormControlName('orderPrice').setValue(0);
    }
  }

  onPromotionChange($event) {
    this.selectedPromotion = $event;
    this.quantity =
      this.selectedPromotion.quantity == null
        ? this.getOrderFormControlName('quantity').value
        : this.selectedPromotion.quantity;
    this.getOrderFormControlName('quantity').setValue(this.quantity);

    this.onQuantityChange($event);
  }

  convertDateToString(date: any) {
    return date == null ? '-' : moment(date).format('DD/MM/YYYY');
  }

  convertStringToDate(date: string) {
    return moment(date).toDate();
  }

  onFilterProductChange(text: string) {
    if (text) this.filterProductText = text.toUpperCase();
    else this.filterProductText = '';

    console.log(this.filterProductText);

    this.onFilterProduct(null);
  }

  onFilterProduct(category: any) {
    if (category) this.filterProductId = category.id;

    if (this.filterProductId == 0) {
      this.productList = this.allProductList;
    } else {
      this.productList = this.allProductList.filter((x) =>
        x.categories.some((c) => c.categoryId === this.filterProductId)
      );
    }
    if (this.filterProductText.length > 0) {
      this.productList = this.productList.filter(
        (x) =>
          x.nameEN.toUpperCase().includes(this.filterProductText) ||
          x.nameTH.toUpperCase().includes(this.filterProductText)
      );
    }
  }

  onDecreaseProduct(item) {
    --item.quantity;
    if (item.quantity === 0) {
      this.selectedProducts = this.selectedProducts.filter((x) => x.productId != item.productId);
      this.getOrderFormControlName('products').patchValue(null);
    }
    this.updatePrice();
  }
  onIncrementProduct(item) {
    if (this.maxQuantity$.value > 0 && this.getSelectedProductQuantity() < this.maxQuantity$.value) {
      ++item.quantity;
      this.updatePrice();
    }
  }

  onProductItemValueChange(item, $event) {
    let newVal = parseInt($event.target.value);
    let expectedVal = this.maxQuantity$.value - (this.getSelectedProductQuantity() - item.quantity);

    if (
      this.maxQuantity$.value >= 0 &&
      this.getSelectedProductQuantity() - item.quantity + newVal > this.maxQuantity$.value
    ) {
      item.quantity = expectedVal;
      $event.target.value = expectedVal;
    } else if (this.maxQuantity$.value > 0) {
      item.quantity = newVal;
    }
    if (item.quantity <= 0) {
      this.selectedProducts = this.selectedProducts.filter((x) => x.productId != item.productId);
      this.getOrderFormControlName('products').patchValue(null);
    }

    this.updatePrice();
  }

  onOrderStatusChange($event: any): void {
    //this.orderStatusId = $event.id;
    // this.orderDetail.status = $event.id;
    // this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  onProvinceChange(value: any) {
    // this.orderDetail.province = value.id;
    // this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  onTransportChange(value: any) {
    // this.orderDetail.transport = value.id;
    // this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  onPartnersChange($event: any) {
    // this.orderDetail.ref_id = $event.id;
    // this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  onCreateCustomerPartnersChange($event: any) {
    // this.orderDetail.ref_id = $event.id;
    // this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  setPaymentChange(value: any) {
    // this.orderDetail.paymentStatus = value.id;
    // this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  setActive1() {
    this.isActive1 = !this.isActive1;
    this.isActive2 = false;
    this.getOrderFormControlName('timeRemark').setValue(1);
  }

  setActive2() {
    this.isActive2 = !this.isActive2;
    this.isActive1 = false;
    this.getOrderFormControlName('timeRemark').setValue(2);
  }

  onSelect(event: any) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  addReference() {
    this.getFormArray('referenceInformation').push(this.newReferenceInformation());
  }
  setOrderDetail() {
    const obj: OrderDetailModel = {
      id: +this.orderId,
      orderDate: this.getOrderFormControlName('orderDate').value,
      orderStatus: this.getOrderFormControlName('orderStatus').value,
      referenceId: this.getOrderFormControlName('referenceId').value,
      branchId: 0,
      invoiceNumber: this.getOrderFormControlName('invoiceNumber').value,
      referenceNumber: this.getOrderFormControlName('referenceNumber').value,
      referenceText: this.getOrderFormControlName('referenceText').value,
      customerId: this.getOrderFormControlName('customerId').value,
      customerName: this.getOrderFormControlName('customerName').value,
      customerPhoneNumber: this.getOrderFormControlName('customerPhoneNumber').value,
      receiverName: this.getOrderFormControlName('receiverName').value,
      deliveryPhoneNumber: this.getOrderFormControlName('deliveryPhoneNumber').value,
      addressId: this.getOrderFormControlName('addressId').value,
      address: this.getOrderFormControlName('address').value,
      locationLink: this.getOrderFormControlName('locationLink').value,
      subDistrict: this.getOrderFormControlName('subDistrict').value,
      district: this.getOrderFormControlName('district').value,
      provinceId: this.getOrderFormControlName('provinceId').value,
      zipCode: this.getOrderFormControlName('zipCode').value,
      isUpcountry: this.getOrderFormControlName('isUpcountry').value,
      deliverRemark: this.getOrderFormControlName('deliverRemark').value,
      deliveryDate: this.getOrderFormControlName('deliveryDate').value,
      promotionId: this.getOrderFormControlName('promotionId').value,
      quantity: this.getOrderFormControlName('quantity').value,
      boxCode: this.getOrderFormControlName('boxCode').value,
      boxQuantity: this.getOrderFormControlName('boxQuantity').value,
      orderPrice: this.getOrderFormControlName('orderPrice').value,
      deliveryPrice: this.getOrderFormControlName('deliveryPrice').value,
      discountPrice: this.getOrderFormControlName('discountPrice').value,
      totalPrice: this.getOrderFormControlName('totalPrice').value,
      products: this.selectedProducts,
      secondPhone: this.getOrderFormControlName('secondPhone').value,
      transportationType: this.getOrderFormControlName('transportationType').value,
      routeName: this.getOrderFormControlName('routeName').value,
      routeQueue: this.getOrderFormControlName('routeQueue').value,
      smsRemark: this.getOrderFormControlName('smsRemark').value,
      factoryRemark: this.getOrderFormControlName('factoryRemark').value,
      deliveryRemark: this.getOrderFormControlName('deliveryRemark').value,
      paymentStatus: this.getOrderFormControlName('paymentStatus').value,
      paymentReference: this.getOrderFormControlName('paymentReference').value,
      // referenceImage: this.getOrderFormControlName('referenceImage').value,
      referenceInformation: this.getOrderFormControlName('referenceInformation').value,
      customerOrderGiftItem: this.getOrderFormControlName('customerOrderGiftItem').value,
      timeRemark: this.getOrderFormControlName('timeRemark').value,
      orderWording: this.getOrderFormControlName('orderWording').value,
      isWaitingData: this.getOrderFormControlName('isWaitingData').value,
      waitingData: this.getOrderFormControlName('waitingData').value,
      receivedDate: undefined,
      eta: undefined,
      promotionName: '',
      remark: '',
      paymentStatusText: '',
      vatPrice: 0,
      isDataComplete: false,
      isDelivered: false,
      isNinoxOrder: false,
      isPaid: false,
    };
    return obj;
  }

  onSubmit() {
    this.orderForm.markAllAsTouched();
    console.log('this.orderForm', this.orderForm);
    if (this.orderForm.valid) {
      const obj = this.setOrderDetail();
      console.log('obj', obj);
      if (this.orderId) {
        this.orderService
          .updateOrder(+this.orderId, obj)
          .pipe(take(1))
          .subscribe(
            () => {
              this.messageService.showSuccess('Update successful');
            },
            (err) => {
              this.messageService.showError('Update failed');
            }
          );
      } else {
        this.orderService
          .createOrder(obj)
          .pipe(take(1))
          .subscribe(
            () => {
              this.messageService.showSuccess('Create successful');
            },
            (err) => {
              this.messageService.showError('Create failed');
            }
          );
      }
    }
  }

  saveReferenceImage(base64file: string, index: number) {
    const referenceInformation = this.getReferences(this.orderForm);
    // const image: UploadImageViewModel = {
    // 	id: 0,
    // 	url: '',
    // 	filePathBase64: base64file,
    // }
    referenceInformation.at(index).setValue({
      id: referenceInformation.at(index).value.id,
      referenceText: referenceInformation.at(index).value.referenceText,
      fileReferenceTypeId: referenceInformation.at(index).value.fileReferenceTypeId,
      filePathBase64: base64file,
    });
  }

  clearReferenceImage(index: number) {
    const image: UploadImageViewModel = {
      id: 0,
      url: '',
      filePathBase64: '',
    };
    this.orderForm.get('referenceInformation')['controls'][index].patchValue({
      filePathBase64: '',
    });
  }

  savePaymentImage(base64file: string) {
    this.getOrderFormControlName('transferImage').setValue({
      filePathBase64: base64file,
    });
  }

  clearPaymentImage() {
    this.getOrderFormControlName('transferImage').setValue({
      filePathBase64: '',
    });
  }

  saveOtherSlipImage(base64file: string) {
    this.getOrderFormControlName('moreSlipImage').setValue({
      filePathBase64: base64file,
    });
  }

  clearOtherSlipImage() {
    this.getOrderFormControlName('moreSlipImage').setValue({
      filePathBase64: '',
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, MODAL_CONFIG);
  }

  openAddressesModal(template: TemplateRef<any>) {
    this.buildAddressForm();
    this.customerService.getCustomerAddresses(this.customerId).subscribe((res) => {
      this.customerAddresses = res;
    });
    this.modalRef = this.modalService.show(template, this.modalConfigLarge);
  }

  displayAddressInfo(address: any) {
    const provinceName = this.provinces.find((x) => x.provinceID === address.provinceId)?.name;
    const combinedAddress = `${this.selectedCustomerName}, ${this.selectedCustomerPhone}, ${address.address}, ${address.subDistrict}, ${address.district}, ${provinceName}, ${address.postcode}`;
    return combinedAddress;
  }

  buildAddressForm(address?: any) {
    if (address) {
      this.addressForm = this.formBuilder.group({
        deliveryName: [address.name, Validators.required],
        deliveryPhoneNumber: [address.deliveryPhoneNumber, Validators.required],
        locationLink: [address.locationLink, Validators.required],
        address: [address.address, Validators.required],
        subDistrict: [address.subDistrict, Validators.required],
        district: [address.district, Validators.required],
        provinceId: [address.provinceId, Validators.required],
        postcode: [address.postcode, Validators.required],
        description: [address.description],
      });
    } else {
      this.addressForm = this.formBuilder.group({
        deliveryName: ['', Validators.required],
        deliveryPhoneNumber: ['', Validators.required],
        locationLink: ['', Validators.required],
        address: ['', Validators.required],
        subDistrict: ['', Validators.required],
        district: ['', Validators.required],
        provinceId: ['', Validators.required],
        postcode: ['', Validators.required],
        description: [''],
      });
    }
  }

  onCustomerSubmit() {
    this.customerForm.markAllAsTouched();
    if (this.customerForm.valid) {
      console.log('this.customerForm.value', this.customerForm.value);
      this.customerService
        .createCustomerFromOrder(this.customerForm.value)
        .pipe(take(1))
        .subscribe(
          (res) => {
            console.log('res createCustomerFromOrder', res);
            this.selectedCustomerName = res.name;
            this.selectedCustomerPhone = res.phone;
            this.customerRefId =
              res.customerReferId === 0 ? this.customerForm.value.customerReferId : res.customerReferId;
            this.getOrderFormControlName('customerId').setValue(res.id);
            this.getOrderFormControlName('customerName').setValue(res.name);
            this.getOrderFormControlName('customerPhoneNumber').setValue(res.phone);

            this.customerService.getCustomerAddresses(res.id).subscribe((res) => {
              console.log('res getCustomerAddresses', res);
              this.customerAddresses = res;
              this.getOrderFormControlName('addressId').setValue(res.id);
              this.bindAddressInOrder(this.customerAddresses[0]);
            });
            this.messageService.showSuccess('Create successful');
            this.modalRef.hide();
          },
          (err) => {
            this.messageService.showError('Create failed');
          }
        );
    }
  }

  onAddressModalClose() {
    this.modalRef.hide();
    this.selectedAddressInModal = false;
    this.addressEditMode = false;
    this.addressCreateMode = false;
    this.selectedAddressIndex = 0;
  }

  onChangeAddress(address: any) {
    console.log('onChangeAddress', address);
    this.getOrderFormControlName('isUpcountry').setValue(address.isUpCountry);
    this.getOrderFormControlName('locationLink').setValue(address.locationLink);
    this.getOrderFormControlName('address').setValue(address.address);
    this.getOrderFormControlName('subDistrict').setValue(address.subDistrict);
    this.getOrderFormControlName('district').setValue(address.district);
    this.getOrderFormControlName('provinceId').setValue(address.provinceId);
    this.getOrderFormControlName('zipCode').setValue(address.postcode);
    this.getOrderFormControlName('receiverName').setValue(address.name);
    this.getOrderFormControlName('deliveryPhoneNumber').setValue(address.deliveryPhoneNumber);
    this.getOrderFormControlName('deliverRemark').setValue(address.description);
    this.buildAddressForm(address);
    this.updatePrice();
  }

  onEditOrCreateAddress(isEdit: boolean, address?: any) {
    this.selectedAddressInModal = true;
    this.buildAddressForm(address);
    if (isEdit) {
      this.addressEditMode = true;
      this.addressCreateMode = false;
    } else {
      this.addressCreateMode = true;
      this.addressEditMode = false;
    }
  }

  isHidden(index: any) {
    if (this.selectedAddressIndex) {
      return this.selectedAddressIndex === index;
    }
    return true;
  }

  bindAddressInOrder(obj: any) {
    this.getOrderFormControlName('locationLink').setValue(obj.locationLink);
    this.getOrderFormControlName('address').setValue(obj.address);
    this.getOrderFormControlName('subDistrict').setValue(obj.subDistrict);
    this.getOrderFormControlName('district').setValue(obj.district);
    this.getOrderFormControlName('provinceId').setValue(obj.provinceId);
    this.getOrderFormControlName('zipCode').setValue(obj.postcode);
    this.getOrderFormControlName('receiverName').setValue(obj.deliveryName);
    this.getOrderFormControlName('deliveryPhoneNumber').setValue(obj.deliveryPhoneNumber);
  }

  onAddressSubmit() {
    this.addressForm.markAllAsTouched();
    if (this.addressForm.valid) {
      console.log('addressObj', this.addressForm.value);
      // Create new Address
      if (this.addressCreateMode) {
        this.customerService
          .createCustomerAddress(this.customerId, this.addressForm.value)
          .pipe(take(1))
          .subscribe(
            (res) => {
              this.messageService.showSuccess('Create successful');
              this.bindAddressInOrder(res);
              this.modalRef.hide();
            },
            (err) => {
              this.messageService.showError('Create failed');
            }
          );
      } else {
        this.customerService
          .updateCustomerAddress(this.customerId, this.addressId, this.addressForm.value)
          .pipe(take(1))
          .subscribe(
            (res) => {
              this.messageService.showSuccess('Update successful');
              this.bindAddressInOrder(res);
              this.modalRef.hide();
            },
            (err) => {
              this.messageService.showError('Update failed');
            }
          );
      }
      this.selectedAddressInModal = false;
    }
  }
}
