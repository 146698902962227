export class OrderItemModel {
  id: string | number | 0;
  customerId: string | number | 0;
  invoiceId: string | number | 0;
  invoice: InvoiceOrderModel | null;
  communicationMethodId: string | number | 0;
  orderPrice: string | 0;
  deliveryPrice: string | 0;
  totalPrice: string | 0;
  orderDate: string | null;
  deliveryDate: string | null;
  remark: string | null;
  orderStatus: string | '';
  orderStatusId: string | number | 0;
  referenceText: string | '';
  paymentStatusId: string | number | null;
  distributorId: string | number | 0;
  isDataComplete: boolean | false;
  paymentType: string | number | null;
  referenceId: string | number | 0;
  deliveryDetail: OrderDeliveryDetail[] | [];
  fileReference: OrderFileReference[] | [];
}

export class InvoiceOrderModel {
  invoiceId: string | number | '';
  invoiceNumber: string | '';
}

export class OrderFileReference {
  id: string | number | 0;
  orderId: string | number | 0;
  filePath: string | '';
  fileReferenceTypeId: string | number | 0;
  thumbnail: string | '';
  partnerId: string | number | 0;
  fileBase64String: string | number | '';
}
export class OrderDeliveryDetail {
  id: string | number | null;
  routeId: string | number | 0;
  customerAddressId: string | number | 0;
  deliveryStatusId: string | number | 0;
  distributorId: string | number | 0;
  recieverName: string | '';
  CustomerAddressId: string | number | 0;
  DeliveryRemark: string | '';
  imagePath: string | '';
  recievedBy: string | '';
  recievedTime: string | Date | '';
  boxCount: string | number | 0;
  isUpcountry: boolean | false;
  orderPrice: string | number | 0;
  deliveryPrice: string | number | 0;
  totalPrice: string | number | 0;
  transportationTypeId: string | number | 0;
  timeRemark: string | number | 0;
  customerOrderLineItem: OrderCustomerLineItem[] | [];
  customerOrderPromotion: OrderPromotion[] | [];
}

export class OrderCustomerLineItem {
  id: string | number | 0;
  orderId: string | number | 0;
  productId: string | number | 0;
  amount: string | number | 0;
  customerAddressId: string | number | 0;
  product: OrderApiProductItemModel | any;
}

export class OrderApiProductItemModel {
  id: string | number | 0;
  ipCode: string | '';
  nameEN: string | '';
  nameTH: string | '';
}

export class OrderPromotion {
  orderId: string | number | 0;
  promotionId: string | number | 0;
  quantity: string | number | 0;
}

export class OrderCalculatePrice {
  deliveryPrice: string | number | 0;
  ordrPrice: string | number | 0;
  summaryPrice: string | number | 0;
}
