import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { OrderCommonModel } from 'src/app/models/order.model';
import { ApiService } from './base/api.service';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  orderCommon: OrderCommonModel = null;

  constructor(private apiService: ApiService) {}

  private getOrderCommonApi(): Observable<any> {
    return this.apiService.get(`orders/common`);
  }

  async getOrderCommon(): Promise<OrderCommonModel> {
    if (this.orderCommon == null) {
      this.orderCommon = await this.getOrderCommonApi().toPromise();
      return this.orderCommon;
    } else {
      return this.orderCommon;
    }
  }

  async getProductCategory() {
    if (this.orderCommon != null) {
      const category: any[] = [];
      Object.assign(category, this.orderCommon.productCategory);
      category.unshift({
        id: 0,
        name: 'all',
      });
      return category;
    }
  }

  filterPromotion(name: string = null): Observable<any[]> {
    let items = this.orderCommon.promotion;
    if (name) {
      items = items.filter((x) => x.name.toLocaleLowerCase().indexOf(name.toLocaleLowerCase()) > -1);
    }
    return of(items).pipe(delay(500));
  }

  filterPartner(name: string = null): Observable<any[]> {
    let items = this.orderCommon.partners;
    if (name) {
      items = items.filter((x) => x.name.toLocaleLowerCase().indexOf(name.toLocaleLowerCase()) > -1);
    }
    return of(items).pipe(delay(500));
  }

  filterProvince(name: string = null): Observable<any[]> {
    let items = this.orderCommon.provinces;
    items = items.sort((a, b) => a.name.localeCompare(b.name));
    if (name) {
      items = items.filter((x) => x.name.toLocaleLowerCase().indexOf(name.toLocaleLowerCase()) > -1);
    }
    return of(items).pipe(delay(500));
  }

  filterPaymentGateway(name: string = null): Observable<any[]> {
    let items = this.orderCommon.paymentGateway;
    if (name) {
      items = items.filter((x) => x.name.toLocaleLowerCase().indexOf(name.toLocaleLowerCase()) > -1);
    }
    return of(items).pipe(delay(500));
  }

  filterGiftItem(name: string = null): Observable<any[]> {
    let items = this.orderCommon.giftItem;
    if (name) {
      items = items.filter((x) => x.name.toLocaleLowerCase().indexOf(name.toLocaleLowerCase()) > -1);
    }
    return of(items).pipe(delay(500));
  }

  filterBranchItem(name: string = null): Observable<any[]> {
    let items = this.orderCommon.branches;
    if (name) {
      items = items.filter((x) => x.name.toLocaleLowerCase().indexOf(name.toLocaleLowerCase()) > -1);
    }
    return of(items).pipe(delay(500));
  }
}
