import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { ProductsService } from 'src/app/core/services/products.service';
import { SaveOrderService } from 'src/app/core/services/save-order.service';
import { DropdownItemModel } from 'src/app/models/base/util.model';
import { OrderCommonModel, OrderDetail, OrderPlaceModel, OrderProductItemModel } from 'src/app/models/order.model';
import { ProductViewModel, selectedOrderProduct } from 'src/app/models/product.model';

@Component({
  selector: 'app-order-product',
  templateUrl: './order-product.component.html',
  styleUrls: ['./order-product.component.scss'],
})
export class OrderProductComponent implements OnInit, OnDestroy {
  @Input() place: OrderPlaceModel = new OrderPlaceModel();
  @Output() valueChange = new EventEmitter<selectedOrderProduct[]>();

  productList: ProductViewModel[] = [];
  allProductList: ProductViewModel[] = [];
  selectProducts: OrderProductItemModel[] = [];
  numberOfItem: number | string = 0;
  productCategoryList: DropdownItemModel[] = [];
  filterProductId = 0;
  numberOfSet: number | string = 0;
  orderSubscrip: Subscription;
  orderDetail: OrderDetail = new OrderDetail();
  orderCommon: OrderCommonModel = new OrderCommonModel();

  constructor(
    private saveOrderService: SaveOrderService,
    private productService: ProductsService,
    private commonService: CommonService
  ) {}

  async ngOnInit(): Promise<void> {
    this.getAllProducts();
    this.orderCommon = await this.commonService.getOrderCommon();
    this.productCategoryList = await this.commonService.getProductCategory();
    this.numberOfSet = this.place.setId;
    this.orderSubscrip = this.saveOrderService.orderDetail.subscribe((x) => {
      this.orderDetail = x;
      this.selectProducts = this.orderDetail.places ? this.orderDetail.places?.products : [];
      this.numberOfItem = this.selectProducts.length;
      console.log('this.selectProducts', this.orderDetail);
    });
  }

  ngOnDestroy() {
    this.orderSubscrip.unsubscribe();
  }

  getAllProducts() {
    this.productService.getAllProducts().subscribe((res) => {
      this.allProductList = res.map((x: any) => Object.assign(new ProductViewModel(), x));
      this.productList = this.allProductList;
    });
  }

  addProduct(product: ProductViewModel) {
    if (this.orderDetail.numberOfSet == 0 || this.numberOfItem == this.orderDetail.numberOfSet) return;

    if (this.selectProducts.some((x) => x.productId == product.id)) {
      ++this.selectProducts.find((e) => e.productId == product.id).quantity;
    } else {
      this.selectProducts.push({
        id: undefined,
        placeId: undefined,
        orderId: undefined,
        productId: product.id,
        productCode: '',
        nameEn: product.nameEN,
        nameTh: product.nameTH,
        quantity: 1,
      });
    }

    this.updateNumberOfItem();
  }

  updateNumberOfItem() {
    let tempNumber = 0;
    for (let i = 0; i < this.selectProducts.length; i++) {
      tempNumber += this.selectProducts[i].quantity!;
    }
    this.orderDetail.places.products = this.selectProducts;
    this.saveOrderService.updateOrderDetail(this.orderDetail);
    this.numberOfItem = tempNumber;
  }

  getProductImage(product: ProductViewModel) {
    // if (product == undefined || product!.productPicture!.length <= 0 || product!.productPicture![0] == undefined)
    //     return '';
    // return product!.productPicture![0].imageThumbnailUrl;
    return product.thumbnailImage?.url;
  }

  itemQuantityChange(event: any, i: number) {
    const isIncreseQuantity = this.selectProducts[i].quantity! < event;
    if (isIncreseQuantity && this.orderDetail.numberOfSet == this.numberOfItem) return;

    if (event <= 0) {
      this.selectProducts.splice(i, 1);
    } else {
      this.selectProducts[i].quantity = event;
    }

    this.updateNumberOfItem();
  }

  filterProduct(filterId: any) {
    this.filterProductId = filterId;
    if (filterId == 0) {
      this.productList = this.allProductList;
    } else {
      this.productList = this.allProductList.filter((x) =>
        x.categories.some((t: any) => t.productCategoryId == filterId)
      );
    }
  }
}
