<div class="orders-view-container">
  <div class="col-12 col-xl-4 left-section">
    <div class="section-container member-data">
      <div class="header">
        <h5>ข้อมูลสมาชิก</h5>
        <!-- <button class="btn btn-primary-outline"><i class="fal fa-file-alt"></i>Customer page</button> -->
      </div>
      <div class="data-list">
        <div class="data-title">ชื่อสมาชิก</div>
        <div class="data-content">{{ orderDetail.customerName }}</div>
      </div>
      <div class="data-list">
        <div class="data-title">เบอร์โทรศัพท์</div>
        <div class="data-content">{{ orderDetail.customerPhoneNumber }}</div>
      </div>
      <!-- <div class="data-list">
                <div class="data-title">อีเมลล์</div>
                <div class="data-content">jenniemail@gmail.com</div>
            </div>
            <div class="data-list">
                <div class="data-title">แต้มสะสม</div>
                <div class="data-content">120 (ได้รับจากออเดอร์นี้ 40 แต้ม)</div>
            </div> -->
    </div>
    <div class="section-container pay-data">
      <h5>ข้อมูลการชำระเงิน</h5>
      <div class="data-list">
        <div class="data-title">เลขที่ Invoice</div>
        <div class="data-content">{{ orderDetail.invoiceNumber }}</div>
      </div>
      <div class="data-list">
        <div class="data-title">สถานะ</div>
        <div class="data-content paid">{{ orderDetail.paymentStatusText }}</div>
      </div>
      <!-- <div class="data-list">
                <div class="data-title">ช่องทาง</div>
                <div class="data-content">บัตรเครดิต *1256</div>
            </div> -->
      <div class="data-list">
        <div class="data-title">วันที่ชำระเงิน</div>
        <div class="data-content">{{ getThaiDateTime(orderDetail.orderDate) }}</div>
      </div>
    </div>
  </div>
  <div class="col-12 col-xl-8 right-section">
    <div class="section-container order-data">
      <h5>ข้อมูลการสั่งซื้อ</h5>
      <div class="data-list">
        <div class="data-title">วันที่สั่งซื้อ</div>
        <div class="data-content">{{ getThaiDateTime(orderDetail.orderDate) }}</div>
      </div>
      <div class="data-list">
        <div class="data-title">วันที่ต้องจัดส่ง</div>
        <div class="data-content">{{ getThaiDate(orderDetail.deliveryDate) }}</div>
      </div>
      <div class="data-list">
        <div class="data-title">ชื่อผู้รับ</div>
        <div class="data-content">
          {{ orderDetail.receiverName }} &nbsp;&nbsp;&nbsp;&nbsp; <span>{{ orderDetail.deliveryPhoneNumber }}</span>
        </div>
      </div>
      <div class="data-list">
        <div class="data-title">ที่อยู่</div>
        <div class="data-content">{{ getFullAddress() }}</div>
      </div>
      <div class="data-list">
        <div class="data-title">หมายเหตุถึงคนขับ:</div>
        <div class="data-content">{{ orderDetail.deliverRemark }}</div>
      </div>

      <table class="table">
        <thead>
          <tr>
            <th scope="col">สินค้า</th>
            <th scope="col">Set promotion</th>
            <th scope="col">จำนวน</th>
            <th class="text-right" scope="col">ราคา</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of orderDetail?.products">
            <td>{{ item.nameTH }}</td>
            <td>{{ promotionName }}</td>
            <td>{{ item.quantity }}</td>
            <td class="text-right">{{ item.quantity }}</td>
          </tr>
        </tbody>
      </table>

      <div class="total-data">
        <div class="data-list">
          <div class="data-title">รวมราคาไอศกรีม</div>
          <div class="data-content">{{ orderDetail.orderPrice | number : '1.0' }}</div>
        </div>
        <div class="data-list">
          <div class="data-title">ค่าจัดส่ง</div>
          <div class="data-content">{{ orderDetail.deliveryPrice | number : '1.0' }}</div>
        </div>
        <div class="data-list">
          <div class="data-title">ส่วนลดพิเศษตามโปรโมชั่น:</div>
          <div class="data-content">{{ orderDetail.discountPrice | number : '1.0' }}</div>
        </div>
      </div>
      <div class="data-list total-order-data">
        <div class="data-title">ยอดรวมคำสั่งซื้อ:</div>
        <div class="data-content">{{ orderDetail.totalPrice | number : '1.0' }}</div>
      </div>
    </div>
  </div>
</div>
