import { Component, OnInit } from '@angular/core';
import { ListOrderService } from 'src/app/core/services/list-order.service';
import { OrderService } from 'src/app/core/services/order.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  orderTotal: any;
  constructor(private listOrderService: ListOrderService) {}

  ngOnInit(): void {
    this.listOrderService.initialValue();
  }
}
