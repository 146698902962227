import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HTML_OPTION } from 'src/app/configs/global';
import { environment } from 'src/environments/environment';
import { JwtService } from './jwt.service';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private jwtService: JwtService) {}

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  private getHttpHeader(isLogin = false) {
    let headersConfig = HTML_OPTION;
    const token = this.jwtService.getToken();

    if (token) {
      headersConfig = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          // .set('Access-Control-Allow-Origin','*')
          // .set('Access-Control-Allow-Credentials','true')
          .set('Accept', 'application/json')
          // .set('Origin', 'http://localhost:4200')
          // 'Accep-Encoding': 'gzip, deflate',
          // 'Accept-Language': 'en-GB,en;q=0.9,da-DK;q=0.8,da;q=0.7,th-TH;q=0.6,th;q=0.5,en-US;q=0.4',
          // 'Referer' : 'http://localhost:4200/',
          .set('Authorization', 'bearer ' + token),
      };
    }
    return headersConfig;
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.API_URL}${path}`, this.getHttpHeader()).pipe(catchError(this.formatErrors));
  }

  patch(path: string, body: object = {}, options?: object): Observable<any> {
    return this.http
      .patch(`${environment.API_URL}${path}`, JSON.stringify(body), this.getHttpHeader())
      .pipe(catchError(this.formatErrors));
  }

  postLogin(path: string, body: object = {}): Observable<any> {
    return this.http
      .post(`${environment.API_URL}${path}`, body.toString(), HTML_OPTION)
      .pipe(catchError(this.formatErrors));
  }

  post(path: string, body: object = {}): Observable<any> {
    return this.http
      .post(`${environment.API_URL}${path}`, JSON.stringify(body), this.getHttpHeader())
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http
      .put(`${environment.API_URL}${path}`, JSON.stringify(body), this.getHttpHeader())
      .pipe(catchError(this.formatErrors));
  }

  delete(path: string): Observable<any> {
    return this.http.delete(`${environment.API_URL}${path}`, this.getHttpHeader()).pipe(catchError(this.formatErrors));
  }
}
