export enum OrderStatusEnum {
  Prepare = 1,
  Booked = 2,
  ReadyToDeliver = 3,
  Delivered = 4,
}

export enum PaymentStatusEnum {
  Waiting = 1,
  Paid = 2,
  Claim = 3,
  Promote = 4,
  Cancel = 5,
}

export enum DeliveryStatusEnum {
  Waiting = 1,
  Sent = 2,
}
