<file-upload [control]="control" class="drop-upload-style">
  <div file-drop-zone [control]="control"></div>
  <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" let-file="file" #placeholder>
    <div class="overlay showimage" *ngIf="control.size === 0 && imageUrl != ''">
      <div class="remove-icon" (click)="clearImageUrl()">
        <i class="fa fa-times" aria-hidden="true "></i>
      </div>
      <img [src]="imageUrl" style="width: 100%" />
    </div>
    <div *ngIf="control.size === 0 && imageUrl == ''">
      <div class="upload-text">
        <ng-container *ngIf="isFileDragDropAvailable; else isNotDragDropAvailable">
          <b>Drag and drop</b> files<br />
          or click here
        </ng-container>
        <ng-template #isNotDragDropAvailable>
          <b>Click here</b> to<br />
          choose a files
        </ng-template>
      </div>
    </div>
  </ng-template>

  <ng-template let-file="file" let-control="control" #item>
    <div class="overlay">
      <div class="remove-icon" (click)="onRemoveFile(control, file)">
        <i class="fa fa-times" aria-hidden="true "></i>
      </div>
      <img *ngIf="control.valid" [src]="uploadedFile | async" style="width: 100%" />
    </div>
  </ng-template>
</file-upload>
