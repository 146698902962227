<form [formGroup]="orderForm" *ngIf="orderForm" (ngSubmit)="onSubmit()">
  <div class="container-fluid">
    <div class="col-8 pr-0 pl-0">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="ddlReference">สาขา</label>
            <div for="ddlReference" class="form-group mb-0">
              <ng-select
                id="ddlReference"
                [items]="partners"
                [loading]="isPartnerLoading"
                bindLabel="name"
                bindValue="id"
                placeholder="สาขา"
                [(ngModel)]="customerRefId"
                (change)="onPartnersChange($event)"
                [ngModelOptions]="{ standalone: true }"
                formControlName="referenceId"
              >
              </ng-select>
            </div>
          </div>
        </div>
      </div>
      <div class="order-info">
        <!-- ข้อมูลการสั่งซื้อ -->

        <!-- รายการสั่งซื้อสินค้า -->

        <div class="row">
          <div class="col-6">
            <h4>เลือกไอศกรีม</h4>
          </div>
          <div class="col-6 filter-component">
            <div class="search-component">
              <div class="input-group">
                <input
                  type="text"
                  name="q"
                  class="form-control"
                  (focus)="$event.target.value = ''; onFilterProductChange('')"
                  (keyup)="onFilterProductChange($event.target.value)"
                  placeholder="ค้นหาตามชื่อไอศกรีม..."
                />
                <span class="input-group-btn">
                  <button type="button" name="search" id="search-btn" class="btn btn-flat">
                    <i class="fa fa-search"></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="order-filter-add">
          <div class="form-group">
            <div
              class="btn btn-filter filter-item"
              *ngFor="let category of categoryList"
              [class.active]="filterProductId == category.id"
              (click)="onFilterProduct(category)"
            >
              {{ category.name }}
            </div>
          </div>
        </div>
        <div class="col-3 pl-0"></div>
        <div class="row order-image-row">
          <div class="col-3 pr-0 pl-0" *ngFor="let product of productList; let i = index">
            <div class="order-image-item" (click)="addProduct(product)">
              <img [src]="getProductImage(product)" alt="" />
              <span class="product-name">{{ product.nameEN }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4 pr-0 pl-0">
      <div class="order-info-add">
        <div class="header">
          <button
            type="button"
            class="btn btn-link"
            (click)="isCollapsedOrderDetail = !isCollapsedOrderDetail"
            [attr.aria-expanded]="!isCollapsedOrderDetail"
            aria-controls="collapseDelivery"
          >
            <i class="fas fa-chevron-right"></i>
            <h4>ข้อมูลการสั่งซื้อ</h4>
          </button>
        </div>
        <div id="collapseDelivery" [collapse]="isCollapsedOrderDetail" [isAnimated]="true">
          <div class="row-1">
            <div class="col-4 pl-0">
              <div class="form-group">
                <label for="input12">โปรโมชั่น</label>
                <ng-select
                  [items]="promotions"
                  name="promotionId"
                  [loading]="isPromotionLoading"
                  bindValue="id"
                  bindLabel="name"
                  placeholder="โปรโมชั่น"
                  required
                  (change)="onPromotionChange($event)"
                  [searchable]="false"
                  [clearable]="false"
                  formControlName="promotionId"
                  [ngClass]="{
                    'is-invalid': f.promotionId?.touched && (f.promotionId.errors || f.promotionId.errors?.required)
                  }"
                >
                </ng-select>
                <div *ngIf="f.promotionId?.touched" class="invalid-feedback">กรุณากรอกฟิลด์นี้</div>
              </div>
            </div>
            <div class="col-2 pl-0">
              <div class="form-group mb-0">
                <label for="txtQuantity">จำนวนสินค้า</label>

                <input
                  id="txtQuantity"
                  type="number"
                  class="form-control"
                  formControlName="quantity"
                  (change)="onQuantityChange($event)"
                />
              </div>
            </div>
            <div class="col-3 pl-0 pr-0">
              <div class="form-group mb-0">
                <label for="select5">จำนวนกล่อง</label>
                <ng-select
                  id="ddlNumberOfBox"
                  [items]="numberOfBoxList"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="จำนวนกล่อง"
                  formControlName="boxQuantity"
                  [(ngModel)]="quantityList[0].id"
                  [ngModelOptions]="{ standalone: true }"
                  [clearable]="false"
                >
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row-2">
            <div class="col-3 pl-0">
              <div class="form-group">
                <label for="input13">ค่าไอศครีม</label>
                <input class="form-control" id="orderPrice" readonly formControlName="orderPrice" />
              </div>
            </div>
            <div class="col-2 pl-0">
              <div class="form-group">
                <label for="input14">ค่าจัดส่ง</label>
                <input class="form-control" id="deliveryPrice" readonly formControlName="deliveryPrice" />
              </div>
            </div>
            <div class="col-2 pl-0">
              <div class="form-group">
                <label for="input15">ส่วนลด</label>
                <input
                  type="number"
                  class="form-control"
                  id="discountPrice"
                  min="0"
                  formControlName="discountPrice"
                  (change)="updatePrice()"
                />
              </div>
            </div>
            <!-- <div class="col-2 pl-0">
                            <button type='button' class="btn btn-primary" [disabled]="!f.orderPrice"
                                (click)="calculatePrice()">คำนวน</button>
                        </div> -->
            <div class="col-3 pl-0 pr-0">
              <div class="form-group">
                <label for="input15">ราคาสุทธิ</label>
                <input class="form-control" id="input15" readonly formControlName="totalPrice" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="order-info-add">
        <div class="header">
          <button
            type="button"
            class="btn btn-link"
            (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseBasic"
          >
            <i class="fas fa-chevron-right"></i>
            <h4>รายการสั่งซื้อสินค้า</h4>
          </button>
          <!-- If empty order number, It will add class="empty-order" -->
          <div class="order-add">{{ getSelectedProductQuantity() }}/{{ maxQuantity$.value }}</div>
        </div>
        <div id="collapseBasic" [collapse]="isCollapsed" [isAnimated]="true">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">รหัสสินค้า</th>
                  <th scope="col">สินค้า (EN)</th>
                  <th scope="col">สินค้า (EN)</th>
                  <th class="text-center" scope="col">จำนวน</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of selectedProducts">
                  <td scope="row">{{ item.productId }}</td>
                  <td>{{ item.nameTH }}</td>
                  <td>{{ item.nameEN }}</td>
                  <td class="add-order-col">
                    <div class="add-order">
                      <button type="button" class="btn btn-cal" (click)="onDecreaseProduct(item)">
                        <i class="fal fa-minus"></i>
                      </button>
                      <div class="number-display">
                        <input
                          type="number"
                          style="width: 3em"
                          [value]="item.quantity"
                          (change)="onProductItemValueChange(item, $event)"
                        />
                      </div>
                      <button type="button" class="btn btn-cal" (click)="onIncrementProduct(item)">
                        <i class="fal fa-plus"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <div
                  [ngClass]="{ 'is-invalid': f.products.touched && (f.products.errors || f.products.errors?.required) }"
                ></div>
                <div *ngIf="f.products.touched" class="invalid-feedback">กรุณาเลือกสินค้า</div>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div class="paid-info">
        <h4>ข้อมูลการชำระเงิน</h4>
        <div class="form-group-switch">
          <label>การชำระเงิน</label>
          <div for="ddlPaymentStatus" class="form-group mb-0">
            <div for="ddlPaymentStatus" class="form-group mb-0">
              <ng-select
                class="ddlPaymentStatus"
                id="ddlPaymentStatus"
                [items]="orderCommon.paymentStatuses"
                bindLabel="name"
                bindValue="id"
                placeholder="การชำระเงิน"
                [clearable]="false"
                (change)="onProvinceChange($event)"
                formControlName="paymentStatus"
              >
              </ng-select>
            </div>
          </div>
        </div>
        <div class="button-row">
          <button type="submit" class="btn btn-primary">Save</button>
          <button class="btn btn-primary-outline" [routerLink]="['/order']">Cancel</button>
        </div>
      </div>
    </div>
  </div>

  <!-- ReferenceModal -->
  <ng-template #referenceModal>
    <div class="modal-stock">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Reference</h4>
        <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true"><i class="fal fa-times"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <input class="form-control" id="input" formControlName="referenceText" />
        </div>
      </div>
    </div>
  </ng-template>
</form>
