<div class="navbar-custom-menu">
  <ul class="nav navbar-nav">
    <li mk-dropdown type="list" [isWrapper]="false" class="user user-menu">
      <mk-dropdown-toggle>
        <a #toggleElement>
          <img src="assets/img/user2-160x160.jpg" class="user-image" alt="User Image" />
        </a>
      </mk-dropdown-toggle>
      <mk-dropdown-menu>
        <li>
          <a href="" (click)="doLogOut()">Sign out</a>
        </li>
      </mk-dropdown-menu>
    </li>
  </ul>
</div>
