import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/core/services/common.service';
import { CustomerService } from 'src/app/core/services/customer.service';
import { InformationMessageService } from 'src/app/core/services/information-message.service';
import { OrderService } from 'src/app/core/services/order.service';
import { SaveOrderService } from 'src/app/core/services/save-order.service';
import { OrderDetailModel } from 'src/app/models/order-detail.model';
import { OrderCommonModel, OrderDetail } from 'src/app/models/order.model';

@Component({
  selector: 'app-order-edit',
  templateUrl: './order-edit.component.html',
  styleUrls: ['./order-edit.component.scss'],
})
export class OrderEditComponent implements OnInit {
  isCollapsed = false;
  isActive1 = false;
  isActive2 = false;
  orderId: string;
  files: File[] = [];
  orderDetail: OrderDetailModel = new OrderDetailModel();
  orderCommon: OrderCommonModel = new OrderCommonModel();

  constructor(
    private saveOrderService: SaveOrderService,
    private customerService: CustomerService,
    private orderService: OrderService,
    private commonService: CommonService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private messageService: InformationMessageService
  ) {}

  async ngOnInit(): Promise<void> {
    this.orderDetail.products = Array();
    this.orderId = this.route.snapshot.paramMap.get('id');
    this.orderCommon = await this.commonService.getOrderCommon();

    this.orderService.getOrderById(this.orderId).subscribe(async (res) => {
      this.orderDetail = res;
      // const apiModel = res;
      // this.orderDetail = await this.saveOrderService.bindOrderDetail(apiModel);
    });
  }

  convertDateToString(date: any) {
    return date == null ? '-' : moment(date).format('DD/MM/YYYY');
  }

  convertStringToDate(date: string) {
    return moment(date).toDate();
  }

  setActive1() {
    this.isActive1 = !this.isActive1;
  }

  setActive2() {
    this.isActive2 = !this.isActive2;
  }
  onSelect(event: any) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  onOrderStatusChange($event: any): void {
    //this.orderStatusId = $event.id;
    // this.orderDetail.status = $event.id;
    // this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  onProvinceChange(value: any) {
    // this.orderDetail.province = value.id;
    // this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  onTransportChange(value: any) {
    // this.orderDetail.transport = value.id;
    // this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  onPartnersChange($event: any) {
    // this.orderDetail.ref_id = $event.id;
    // this.saveOrderService.updateOrderDetail(this.orderDetail);
  }

  setPaymentChange(value: any) {
    // this.orderDetail.paymentStatus = value.id;
    // this.saveOrderService.updateOrderDetail(this.orderDetail);
  }
}
