<div class="order-list-container">
  <div class="row">
    <div class="col-12">
      <div class="order-from-partner">
        <div class="order-header"><i class="fal fa-users"></i> Order from partners</div>
        <div class="order-content">
          <div
            class="order"
            [ngStyle]="{ 'background-color': isSelectedPartner ? '#fff' : '#d2d7df' }"
            (click)="onChange('total', orderFilterEnum.Total)"
          >
            <div class="total-order">
              <div class="type text-uppercase">
                <span class="title">{{ partnerTotal.name }}</span>
                <div class="value">{{ partnerTotal.totalorder }}</div>
              </div>
            </div>
            <div class="type-order">
              <div class="type">
                Payment<span>{{ partnerTotal.payment }}</span>
              </div>
              <div class="type">
                Delivery<span>{{ partnerTotal.delivery }}</span>
              </div>
            </div>
          </div>
          <div class="slick-container d-flex">
            <div class="slick-order">
              <div class="slick-row-top">
                <div
                  class="col-3 order-partner-item mb-2"
                  *ngFor="let partner of partnerListTop; let rowIndex = index"
                  (click)="onChange(partner, orderFilterEnum.Partner, 'top', rowIndex)"
                  tabindex="{{ rowIndex }}"
                  [ngStyle]="{
                    'background-color': partnerSection === 'top' && partnerIndex === rowIndex ? '#d2d7df' : '#fff'
                  }"
                >
                  <h5 class="ref_{{ partner.id }}" style="text-align: center">{{ partner.name }}</h5>
                  <div class="detail">
                    <div class="order">{{ partner.totalorder }}</div>
                    <div class="d-block">
                      <div class="detail-items">
                        <h6 class="title">Payment</h6>
                        <div class="value">{{ partner.payment }}</div>
                      </div>
                      <div class="detail-items">
                        <h6 class="title">Delivery</h6>
                        <div class="value">{{ partner.delivery }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="slick-row-bottom">
                <div
                  class="col-3 order-partner-item"
                  *ngFor="let partner of partnerListBottom; let rowIndex = index"
                  (click)="onChange(partner, orderFilterEnum.Partner, 'bottom', rowIndex)"
                  [ngStyle]="{
                    'background-color': partnerSection === 'bottom' && partnerIndex === rowIndex ? '#d2d7df' : '#fff'
                  }"
                >
                  <h5 class="ref_{{ partner.id }}" style="text-align: center">{{ partner.name }}</h5>
                  <div class="detail">
                    <div class="order">{{ partner.totalorder }}</div>
                    <div class="d-block">
                      <div class="detail-items">
                        <h6 class="title">Payment</h6>
                        <div class="value">{{ partner.payment }}</div>
                      </div>
                      <div class="detail-items">
                        <h6 class="title">Delivery</h6>
                        <div class="value">{{ partner.delivery }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="header-title">
  <div class="filter-component">
    <h5>Filters</h5>
    <div class="form-group select-form">
      <ng-select
        id="dateDropdown"
        class="custom"
        [items]="dateFilterList"
        [searchable]="false"
        [clearable]="false"
        [(ngModel)]="defaultDateFilter"
        [ngModelOptions]="{ standalone: true }"
        (change)="onDatePresentChanged($event)"
      >
      </ng-select>
    </div>
    <div class="form-group date-picker">
      <input
        type="text"
        placeholder="Start date"
        class="form-control"
        [bsValue]="startDate"
        bsDatepicker
        (bsValueChange)="dateStartChange($event)"
        [disabled]="isDateStartDisabled"
        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY ', adaptivePosition: true, customTodayClass: 'today' }"
      />
    </div>
    <ng-container *ngIf="!isDateEndHidden">
      <span>to</span>
      <div class="form-group date-picker">
        <input
          type="text"
          placeholder="End date"
          class="form-control"
          [bsValue]="endDate"
          bsDatepicker
          (bsValueChange)="dateEndChange($event)"
          [disabled]="isDateEndDisabled"
          [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY ', adaptivePosition: true, customTodayClass: 'today' }"
        />
      </div>
    </ng-container>
    <div class="form-group select-form">
      <ng-select
        class="custom"
        name="transportationTypes"
        [items]="orderCommon.transportationTypes"
        bindValue="id"
        bindLabel="name"
        placeholder="ประเภทขนส่ง"
        [searchable]="false"
        (change)="onChange($event, orderFilterEnum.Transaction)"
      >
      </ng-select>
    </div>
    <div class="form-group select-form">
      <ng-select
        class="custom"
        [items]="orderCommon.orderStatus"
        bindLabel="name"
        bindValue="id"
        placeholder="สถานะจัดส่ง"
        (change)="onChange($event, orderFilterEnum.Delivery)"
      >
      </ng-select>
    </div>
    <div class="form-group select-form">
      <ng-select
        class="custom"
        [items]="orderCommon.paymentStatuses"
        bindLabel="name"
        bindValue="id"
        placeholder="สถานะการชำระเงิน"
        (change)="onChange($event, orderFilterEnum.Payment)"
      >
      </ng-select>
    </div>
    <div class="custom-control custom-checkbox select-form">
      <input
        type="checkbox"
        class="custom-control-input"
        id="checkData"
        (change)="onChange($event, orderFilterEnum.WaitingData)"
      />
      <label class="custom-control-label" for="checkData">รอข้อมูล</label>
    </div>
  </div>
  <form action="#" method="get" class="search-component">
    <div class="input-group">
      <input type="text" name="q" class="form-control" placeholder="Search..." (keyup)="filterByText($event)" />
      <span class="input-group-btn">
        <button type="submit" name="search" id="search-btn" class="btn btn-flat">
          <i class="fa fa-search"></i>
        </button>
      </span>
    </div>
  </form>
</div>
<div class="row">
  <div class="col-2"></div>
  <div class="col-8">
    <div class="custom-control custom-checkbox select-form">
      <input
        type="checkbox"
        class="custom-control-input"
        id="checkDeliveryDate"
        (change)="onChange($event, orderFilterEnum.DeliveryDate)"
      />
      <label class="custom-control-label" for="checkDeliveryDate">ค้นหาตามวันที่ต้องจัดส่ง</label>
    </div>
  </div>
  <div class="col-2">
    <div class="custom-control custom-checkbox select-form">
      <input
        type="checkbox"
        class="custom-control-input"
        id="checkProductName"
        (change)="onChange($event, orderFilterEnum.ProductName)"
      />
      <label class="custom-control-label" for="checkProductName">ค้นหาตามชื่อไอศกรีม</label>
    </div>
  </div>
</div>
<div class="row table-config">
  <div class="col-5"></div>
  <div class="col-1 pl-0">
    <div class="custom-control custom-checkbox checkbox-ascending">
      <input
        type="checkbox"
        class="custom-control-input"
        id="checkAutoRefresh"
        [(ngModel)]="isAutoRefresh"
        (change)="onChangeAutoRefresh($event)"
      />
      <label class="custom-control-label" for="checkAutoRefresh">Auto Refresh</label>
    </div>
  </div>
  <div class="col-3 pr-0">
    <div class="item-per-page">
      <span>Sort by </span>
      <ng-select
        class="sort"
        [(ngModel)]="sortingValue"
        [clearable]="false"
        [searchable]="false"
        (change)="onSelectSortingField($event)"
        dropdownPosition="top"
      >
        <ng-option *ngFor="let item of tableHeader" [value]="item.value">{{ item.value }} </ng-option>
      </ng-select>
    </div>
  </div>
  <div class="col-1 pl-0">
    <div class="custom-control custom-checkbox checkbox-ascending">
      <input type="checkbox" class="custom-control-input" id="checkDescending" (change)="onSelectDescending($event)" />
      <label class="custom-control-label" for="checkDescending">Descending</label>
    </div>
  </div>
  <div class="col-2">
    <div class="item-per-page">
      <span>Item per page</span>
      <ng-select
        class="custom"
        [(ngModel)]="itemPerPage"
        [clearable]="false"
        [searchable]="false"
        (change)="onSelectItemPerPage()"
        dropdownPosition="top"
      >
        <ng-option *ngFor="let item of itemPerPageValue" [value]="item">{{ item }} </ng-option>
      </ng-select>
    </div>
  </div>
</div>
<pagination-controls (pageChange)="onChangePage($event)" class="text-right"></pagination-controls>
<div class="table table-custom">
  <table class="table table-hover table-bordered">
    <thead>
      <tr>
        <th *ngFor="let header of tableHeader">{{ header.value }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="odd"
        *ngFor="
          let order of orderListPage?.results
            | paginate
              : {
                  itemsPerPage: itemPerPage,
                  currentPage: orderListPage?.pagination?.currentPage,
                  totalItems: orderListPage?.pagination?.totalItems
                }
        "
      >
        <td>{{ order.invoiceNumber }}</td>
        <td>
          <div class="btn btn-default btn-sm ref_{{ order.ref_id }}">{{ order.ref_id_name }}</div>
        </td>
        <td>{{ order.referenceNumber }}</td>
        <td>{{ convertDateToString(order.orderDate, order.ref_id_name) }}</td>
        <td>{{ convertDateToString(order.deliveryDate, order.ref_id_name) }}</td>
        <td>
          <a [routerLink]="['/order/view/', order.id]">{{ order.customerName }}</a>
        </td>
        <td>{{ order.provinceName }}</td>
        <td>{{ order.promotionName }}</td>
        <td>{{ order.itemQuantity }} items</td>
        <td>{{ order.orderPrice }}</td>
        <td>{{ order.deliveryPrice }}</td>
        <td>{{ order.discountPrice }}</td>
        <td>{{ order.totalPrice }}</td>
        <td>
          <div class="btn btn-default btn-sm status_{{ order.orderStatusId }}">
            {{ getOrderStatus(order.orderStatusId) }}
          </div>
        </td>
        <td>
          <div class="btn btn-default btn-sm pay_{{ order.paymentStatusId }}">
            {{ getPaymentStatus(order.paymentStatusId) }}
          </div>
          <!-- <button
            style="margin-left: 5px"
            class="btn btn-info btn-sm"
            *ngIf="order.paymentStatusId === 2 && order.ref_id_name === 'Line ส่งด่วน'"
            type="button"
            (click)="sendLineBot(order.id)"
          >
            แจ้งเตือน
          </button> -->
        </td>
        <td>
          <div class="btn btn-default btn-sm car_{{ order.deliveryQueue[0] }}">{{ order.deliveryQueue }}</div>
        </td>
        <td>{{ order.createBy }}</td>
        <td>{{ order.verifyBy }}</td>
        <td>
          <div class="btn-group d-flex" role="group">
            <a
              [routerLink]="['/order/edit', order.id]"
              target="_blank"
              [ngClass]="{ 'btn-success-outline': order.isCheck, 'btn-danger-outline': !order.isCheck }"
              class="btn btn-sm"
              ><i class="fal fa-eye"></i>View</a
            >
            <button
              type="button"
              class="btn btn-default btn-sm"
              (click)="openOrderRecheckModal(orderRecheckModal, order.id)"
            >
              <i class="fal fa-file"></i> Recheck
            </button>
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="4"></td>
        <td><b>รายการทั้งหมด</b></td>
        <td>{{ orderListPage?.pagination?.totalItems }}</td>
        <td><b>สินค้าทั้งหมด</b></td>
        <td>{{ addComma(orderListPage?.sumItem) }}</td>
        <td><b>ยอดสินค้ารวม</b></td>
        <td>{{ addComma(orderListPage?.sumOrderPrice) }}</td>
        <td><b>ยอดส่งรวม</b></td>
        <td>{{ addComma(orderListPage?.sumDeliveryPrice) }}</td>
        <td><b>ส่วนลดรวม</b></td>
        <td>{{ addComma(orderListPage?.sumDiscountPrice) }}</td>
        <td><b>รวมยอดทั้งหมด</b></td>
        <td>{{ addComma(orderListPage?.sumTotalPrice) }}</td>
      </tr>
    </tfoot>
  </table>
  <pagination-controls (pageChange)="onChangePage($event)" class="text-right"></pagination-controls>
</div>

<!-- Order Recheck Modal-->
<ng-template #orderRecheckModal>
  <div class="modal-stock">
    <div class="modal-header">
      <h4 class="modal-title pull-left">ข้อมูล Recheck ออเดอร์</h4>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true"><i class="fal fa-times"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-6">
          <div class="img-magnifier-container" *ngFor="let reference of orderRecheck.referenceInformation || []">
            <img
              id="{{ reference.id }}"
              src="{{ reference.filePath }}"
              alt=""
              width="100%"
              (click)="magnify(reference.id, 3)"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="recheck-wrapper">
            <div class="bill-header">
              <div class="d-flex invoice-no">
                <div class="field-name">ORDER NO. :</div>
                <div class="field-content">
                  {{ orderRecheck?.invoiceNumber }}
                </div>
                <span>&nbsp;</span>
                <div class="field-name">REF NO. :</div>
                <div class="field-content">
                  {{ orderRecheck?.referenceNumber }}
                </div>
              </div>
              <ul class="item-list primary-info">
                <li>
                  <div class="field-name">จำนวน</div>
                  <div class="field-content"><span></span>{{ orderRecheck?.place?.setName }}</div>
                </li>
                <li>
                  <div class="field-name">ค่าไอศครีม</div>
                  <div class="field-content"><span>฿</span>{{ orderRecheck?.orderPrice }}</div>
                </li>
                <li>
                  <div class="field-name">ค่าจัดส่ง</div>
                  <div class="field-content"><span>฿</span>{{ orderRecheck?.deliveryPrice }}</div>
                </li>
                <li>
                  <div class="field-name">ส่วนลด</div>
                  <div class="field-content"><span>฿</span>{{ orderRecheck?.discountPrice }}</div>
                </li>
                <li>
                  <div class="field-name">ราคาสุทธิ</div>
                  <div class="field-content"><span>฿</span>{{ orderRecheck?.totalPrice }}</div>
                </li>
              </ul>
            </div>
            <mk-accordion-group class="accordion-group bg-transparent">
              <mk-accordion header="">
                <mk-accordion-header> </mk-accordion-header>
                <div class="label-note">
                  <h3>{{ orderRecheck?.place?.setName }} | {{ orderRecheck?.place?.boxNumber }} Box</h3>
                </div>
                <div class="d-flex bill-content" *ngFor="let prod of orderRecheck?.place?.products">
                  <div class="field-name">
                    {{ prod.name }}
                  </div>
                  <div class="field-content"><span>X</span> {{ prod.quantity }}</div>
                </div>
                <div class="d-flex bill-footer">
                  <div class="field-name">Total</div>
                  <div class="field-content">
                    {{ orderRecheck?.quantity }}
                  </div>
                </div>
                <ng-container *ngIf="orderRecheck?.place?.gifts?.length > 0">
                  <hr />
                  <div class="d-flex bill-content" *ngFor="let prod of orderRecheck?.place?.gifts">
                    <div class="field-name">
                      {{ prod.name }}
                    </div>
                    <div class="field-content"><span>X</span> {{ prod.amount }}</div>
                  </div>
                  <!-- <div class="d-flex bill-footer">
                                        <div class="field-name">
                                            Total
                                        </div>
                                        <div class="field-content">
                                            {{getTotalGift()}}
                                        </div>
                                    </div> -->
                </ng-container>
                <ng-container *ngIf="orderRecheck?.boxQuantity?.length > 0">
                  <hr />
                </ng-container>
                <div class="d-flex bill-content" *ngFor="let box of orderRecheck?.boxQuantity">
                  <i class="fal fa-box" style="font-size: 11px"> {{ box.key }} = {{ box.value }}&nbsp;</i>
                </div>
                <div class="note-content" *ngIf="orderRecheck?.place?.remark">
                  <div class="note-label">Remark!!!</div>
                  <div class="field-content">
                    <div class="special-remark">*{{ orderRecheck?.place?.remark }}</div>
                  </div>
                </div>
                <hr />
                <div class="shipping-info-wrapper">
                  <div class="shipping-info">
                    <div class="field-content delivery">
                      {{ orderRecheck?.referenceText }}
                    </div>
                    <div class="field-content delivery">วันที่จัดส่ง {{ orderRecheck?.place?.deliveryDate }}</div>
                    <div class="field-content customer">K.{{ orderRecheck?.place?.receiverName }}</div>
                    <div class="field-content customer">เบอร์ผู้รับ: {{ orderRecheck?.place?.tel }}</div>
                    <div class="field-content customer">เบอร์สำรอง: {{ orderRecheck?.place?.secondPhone }}</div>
                    <div class="field-content">
                      {{ orderRecheck?.place?.address }}
                    </div>
                  </div>
                  <div class="shipping-info">
                    <div class="field-content">
                      <div class="status-remark">
                        {{ orderRecheck?.place?.status }}
                      </div>
                    </div>
                    <div class="field-content">
                      <div class="status-remark">
                        {{ orderRecheck?.place?.transport }}
                      </div>
                    </div>
                    <div class="field-content" *ngIf="orderRecheck?.place?.deliveryRemark">
                      <div class="note-label">หมายเหตุลาเบล</div>
                      <div class="special-remark">*{{ orderRecheck?.place?.deliveryRemark }}</div>
                    </div>
                    <div class="field-content" *ngIf="orderRecheck?.smsRemark">
                      <div class="note-label">SMS</div>
                      <div class="special-remark">*{{ orderRecheck?.smsRemark }}</div>
                    </div>
                    <div class="field-content" *ngIf="orderRecheck?.factoryRemark">
                      <div class="note-label">หมายเหตุถึงคนรถ ฉีกลาเบล</div>
                      <div class="special-remark">*{{ orderRecheck?.factoryRemark }}</div>
                    </div>
                  </div>
                </div>
              </mk-accordion>
            </mk-accordion-group>
            <div class="form-group switch-form">
              <div class="custom-control custom-switch" style="width: 95%">
                <div class="left">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customSwitch1"
                    (change)="onChangeIsCheck($event, orderRecheck.orderId.toString())"
                    [checked]="orderRecheck.isCheck"
                    [disabled]="orderRecheck.isWaitingData"
                  />
                  <label class="custom-control-label" for="customSwitch1">ตรวจทาน</label>
                </div>
                <div class="right">
                  <a
                    [routerLink]="['/order-summary']"
                    [queryParams]="{ id: orderRecheck.orderId }"
                    target="_blank"
                    class="btn btn-default"
                    ><i class="fal fa-search"></i>Open</a
                  >
                </div>
              </div>
              <label>{{ orderRecheck?.waitingData }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
