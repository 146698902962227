import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'paid',
    loadChildren: () => import('./pages/paid/paid.module').then((m) => m.paidModule),
    data: {
      customLayout: true,
    },
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/+login/login.module').then((m) => m.LoginModule),
    data: {
      customLayout: true,
    },
  },
  {
    path: 'register',
    loadChildren: () => import('./teamplate/+register/register.module').then((m) => m.RegisterModule),
    data: {
      customLayout: true,
    },
  },
  {
    path: 'order-summary',
    loadChildren: () => import('./pages/order-summary/order-summary.module').then((m) => m.OrderSummaryModule),
    data: {
      customLayout: true,
    },
  },
  {
    path: '',
    loadChildren: () => import('./pages/page.module').then((m) => m.PagesModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
