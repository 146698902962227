<mk-layout-wrapper>
  <mk-layout-header logoLink="/" [isSidebarRightToggle]="false">
    <mk-layout-header-logo>
      <img src="assets/img/logo-header.png" alt="Molto Logo" class="brand-image elevation-3" style="max-width: 105px" />
    </mk-layout-header-logo>
    <mk-layout-header-logo-mini>
      <img src="assets/img/logo-header.png" alt="Molto Logo" class="brand-image elevation-3" style="max-width: 40px" />
    </mk-layout-header-logo-mini>
    <app-header-inner></app-header-inner>
  </mk-layout-header>
  <mk-layout-sidebar-left></mk-layout-sidebar-left>
  <mk-layout-content>
    <div mk-layout-content-before-header>
      <div *mkLoadingPage="{ checkPendingHttp: true, checkPendingRoute: true }"></div>
    </div>
    <router-outlet></router-outlet>
  </mk-layout-content>
</mk-layout-wrapper>
